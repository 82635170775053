import React, { Component } from 'react';
import { STOCK_TYPES } from '../constants/stocks.constants';
import SeedsList from './SeedsList';
import FertilisersList from './FertilizersList';
import AapList from './AapList';
import AdditionalList from './AdditionalList';
import CattleList from './CattleList';

const componentsMap = {
  seeds: SeedsList,
  fertilizer: FertilisersList,
  aap: AapList,
  additional: AdditionalList,
  cattle: CattleList,
};

class StockList extends Component {
  handleProductTypeChange = ({ target }) =>
    this.props.history.replace('/stocks/' + target.value);

  render () {
    console.log(this.props.match, this.props.history);
    const selectedProduct = this.props.match.params.type || STOCK_TYPES[0].type;

    const ListComponent = componentsMap[selectedProduct] ?
      componentsMap[selectedProduct] : null;
    return (
      <div className="px-3">
        <h5>PRODUKTŲ KATALOGAS</h5>
        <select
          className="select"
          onChange={this.handleProductTypeChange}
          value={selectedProduct}
        >
          {STOCK_TYPES.map(
            (option, index) => (
              <option key={`productSelectOption${index}`} value={option.type}>
                {option.label}
              </option>
            )
          )}
        </select>
        {ListComponent && (<ListComponent />)}
      </div>
    )
  }
}

export default StockList;
