import gql from 'graphql-tag';

export const FileTypesQuery = gql`
    query FileTypes {
        fileTypes {
            _id
            name
        }
    }
`;

export const FileTypeQuery = gql`
    query FileSubTypes($type: String!) {
        fileType(_id: $type) {
            _id
            name
            subtypes {
                _id
                name
            }
        }
    }
`;

export const FilesQuery = gql`
    query Files($type: String!, $subTypeId: String) {
        fileType(_id: $type) {
            _id
            name
            subtype(subTypeId: $subTypeId) {
                _id
                name
                files {
                    _id
                    name
                    type
                    path
                    fileType
                    videoUrl
                }
            }
        }
    }
`;

export const SendFile = gql`
    mutation SendFile($userId: String!, $fileId: String!, $name: String, $email: String!, $isVideo: Boolean!) {
        emailFile(userId: $userId, fileId: $fileId, name: $name, email: $email, isVideo: $isVideo) {
            status
        }
    }
`;


// const SendFile = gql`
//     mutation SendFile($userId: String!, $fileId: String!, $name: String, $email: String!, $isVideo: Boolean!) {
//         emailFile(userId: $userId, fileId: $fileId, name: $name, email: $email, isVideo: $isVideo) {
//             status
//         }
//     }
// `;

export const FarmersQuery = gql`
    {
        farmers(isCattle: true) {
            _id
            name
            email
        }
    }
`;

export const FarmersAllQuery = gql`
    {
        farmers {
            _id
            name
            email
        }
    }
`;
