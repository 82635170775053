import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import App from '../App/App';
import Login from '../../../authentication/containers/Login/Login';
import './Root.css';

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const Root = ({ isLoggedIn, isLimited }) => (
  <HashRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route
        path="/"
        render={() =>
          !isLoggedIn ? (
            <Redirect key="RootRedirect" to="/login" />
          ) : (
            <App key="RootApp" isLimited={isLimited} />
          )
        }
      />
    </Switch>
  </HashRouter>
);

Root.propTypes = propTypes;

const mapStateToProps = ({ auth: { isLoggedIn, user } }) => (
  {
    isLoggedIn,
    isLimited: user && user.email === 'biuras@balticagro.com'
  }
);

export default connect(mapStateToProps)(Root);
