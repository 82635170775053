import gql from 'graphql-tag';
export const FARMERS_CULTURE_PHASES = gql`
  query FarmesCulturePhases($_id: String!) {
    farmersCultureById(_id: $_id) {
      _id
      farmer {
        _id
        name
        email
        phone
        location
        region {
          _id
          name
        }
      }
      area
      aapAvailableArea
      culture {
        _id
        code
        name
        aapPhases {
          _id
          code
          shortCode
          name
          image {
            uri
          }
        }
      }
    }
  }
`;

export const AAP_MIXES_QUERY = gql`
  query AapMixes($culturePhaseCode: String, $categories: [String]) {
    aapMixes(culturePhaseCode: $culturePhaseCode, categories: $categories) {
      _id
      code
      name
      categoryCodes
    }
  }
`;

export const AAP_MIX_AND_PHASE_QUERY = gql`
    query AapMix ($aapMixId: String!, $entityId: String!, $phaseCode: String) {
        aapMix(_id: $aapMixId) {
            _id
            code
            name
            productCodes
            products {
                _id
                code
                name
                value
                maxValue
                measureUnit
                descriptionURL
                price
            }
            additional {
                _id
                code
                name
                description
                products {
                    _id
                    code
                    name
                    value
                    maxValue
                    measureUnit
                    descriptionURL
                    price
                    categoryCode
#                    category {
#                        code
#                        name
#                    }
                }
            }
        }
        farmersCultureById(_id: $entityId) {
            _id
            area
            aapAvailableArea(phaseCode: $phaseCode)
            farmer {
                _id
                name
                email
                phone
                location
                region {
                    _id
                    name
                }
            }
            culture {
                _id
                code
                name
                aapPhases {
                    _id
                    code
                    shortCode
                    name
                    image {
                        uri
                    }
                }
            }
        }
    }
`;

export const INSERT_AAP_RECORD = gql`
  mutation InsertAapRecord($entityId: String, $record: inAapRecord) {
      insertAapRecord(entityId: $entityId, record: $record) {
          _id
      }
  }
`;

export const REMOVE_AAP_RECORD = gql`
  mutation RemoveAapRecord($entityId: String!, $index: Int!) {
      removeAapRecord(entityId: $entityId, index: $index) {
          _id
      }
  }
`;

export const AAP_PRODUCTS = gql`
  query AapProducts($cultureId: String!) {
      aapProducts {
          _id
          name
          code
          price
          measureUnit
          value
          maxValue
          categoryCode
          category {
              name
          }
          phaseIds(cultureId: $cultureId)
      }
      additionalFertilizers {
          _id
          name
          code
          price
          measureUnit
          value
          maxValue
          categoryCode
          category {
              name
          }
          phaseIds(cultureId: $cultureId)
      }
  }
`;

export const FARMERS_CULTURE_FOR_AAP = gql`
    query FarmersCultureForFertilizer($_id: String!) {
        farmersCultureById(_id: $_id) {
            _id
            area
            aap {
                date
                phase {
                    _id
                    name
                    code
                }
                area
                phaseCode
                products {
                    product {
                        _id
                        name
                        measureUnit
                    }
                    productCode
                    measureUnit
                    quantity
                    price
                    isAdditional
                }
            }
        }
    }
`;
