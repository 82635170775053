export const FILE_TYPES = [
  {
    type: 'application/pdf',
    label: 'PDF',
    icon: 'pdf_file.png',
    extension: 'pdf',
  },
  {
    type: 'image/jpeg',
    label: 'FOTO',
    icon: 'image_file.png',
    extension: 'jpeg',
  },
  {
    type: 'image/png',
    label: 'FOTO',
    icon: 'image_file.png',
    extension: 'png',
  },
  {
    type: 'video/x-msvideo',
    label: 'AVI',
    icon: 'video_file.png',
    extension: 'avi',
  },
  {
    type: 'video/x-sgi-movie',
    label: 'MOV',
    icon: 'video_file.png',
    extension: 'mov',
  },
  {
    type: 'application/mp4',
    label: 'MP4',
    icon: 'video_file.png',
    extension: 'mp4',
  },
  {
    type: 'application/msword',
    label: 'WORD',
    icon: 'word_file.png',
    extension: 'doc',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    label: 'WORD',
    icon: 'word_file.png',
    extension: 'docx',
  },
  {
    type: 'application/vnd.ms-excel',
    label: 'EXCEL',
    icon: 'excel_file.png',
    extension: 'xls',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    label: 'EXCEL',
    icon: 'excel_file.png',
    extension: 'xlsx',
  },
  {
    type: 'application/vnd.ms-powerpoint',
    label: 'Power Point',
    icon: 'powerpoint_file.png',
    extension: 'ppt',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    label: 'Power Point',
    icon: 'powerpoint_file.png',
    extension: 'pptx',
  },
  {
    type: 'application/cdr',
    label: 'CDR',
    icon: 'cdr_file.png',
    extension: 'cdr',
  },
  {
    type: 'application/eps',
    label: 'EPS',
    icon: 'eps_file.png',
    extension: 'eps',
  },
  {
    type: 'video',
    icon: 'video_file.png',
    label: 'VIDEO',
  }
];

export const FILE_TYPE_ICONS = FILE_TYPES.reduce((acc, item) => ({ ...acc, [item.type]: item.icon }), {});

export const FILE_TYPE_LABELS = FILE_TYPES.reduce((acc, item) => ({ ...acc, [item.type]: item.label }), {});

export const FILE_ICONS_ROOT = 'assets/images/fileIcons/';
