export const ADDITIONAL_CATEGORIES = [
  {
    type: 'CHELATED_FERTILIZER',
    label: 'Chelatinės trąšos',
  },
  {
    type: 'BIOSTIMULANT',
    label: 'Biostimuliatoriai',
  },
  {
    type: 'LEAF_FERTILIZER',
    label: 'Lapų trąšos',
  },
  {
    type: 'HUMIC_ACIDS',
    label: 'Huminės rūgštys',
  },
  {
    type: 'NPK_STANDART',
    label: 'NPK ir startinės trąšos',
  },
  {
    type: 'SPEC_CORECTORS',
    label: 'Specialūs korektoriai',
  },
];
