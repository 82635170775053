import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GrainsMenu from './components/GrainsMenu';
import GrainPrices from './components/GrainPrices';
import GrainCalculator from './components/GrainCalculator';

const GrainsModule = () => (
  <Switch>
    <Route path="/grains/menu" component={GrainsMenu} />
    <Route path="/grains/prices" component={GrainPrices} />
    <Route path="/grains/calculator" component={GrainCalculator} />
    <Redirect to="/grains/menu" />
  </Switch>
);

export default GrainsModule;
