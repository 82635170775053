import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { CULTURE_OVERVIEW_QUERY, SEND_HTML_PROPOSAL } from '../queries/cultureOverview.graphql';
import { FARMER_PROPOSALS, ALL_PROPOSALS } from '../../../proposals/queries/proposals.graphql';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import ExpandableRow from '../../../common/components/ExpandableRow/ExpandableRow';
import Loader from '../../../common/components/Loader/Loader';
import ErrorFetch from '../../../common/components/Error/ErrorFetch';
import { PROPOSAL_TYPES } from '../../../common/constants/proposal.constants';
import CultureOverviewFertilizer from './CultureOverviewFertilizer';
import CultureOverviewAap from './CultureOverviewAap';
// import CultureOverviewFinance from './CultureOverviewFinance';
import SeedSelected from '../../seeds/components/SeedSelected';
import './CultureOverview.css';

export const CultureOverviewContent = ({ farmersCulture,
                                         farmerId,
                                         cultureId,
                                         area,
                                         editable,
                                         maxArea,
                                         hideSeedsArea
}) => [
  <div className="CultureOverviewContent" key="CultureOverviewContent">
    <ExpandableRow
      title="Sėja"
      content={
        <div>
          <SeedSelected
            entityId={farmersCulture._id}
            seeds={farmersCulture.seeds}
            totalArea={farmersCulture.area}
            farmerId={farmerId}
            cultureId={cultureId}
            cultureCode={farmersCulture.culture.code}
            area={area}
            editable={editable}
            maxArea={maxArea}
            hideSeedsArea={hideSeedsArea}
          />
          {editable ? <Link
            to={`/grow-technology/seeds/${farmersCulture._id}`}
            className="button button-success"
          >
            PASIRINKITE SĖKLAS
          </Link> : null}
        </div>
      }
    />
    <ExpandableRow
      title="Pagrindinis tręšimas"
      content={
        <CultureOverviewFertilizer
          fertilizers={farmersCulture.fertilizers}
          culture={farmersCulture.culture}
          farmerId={farmersCulture.farmer._id}
          entryId={farmersCulture._id}
          area={maxArea || farmersCulture.area}
          editable={editable}
        />
      }
    />
    <ExpandableRow
      title="Augalų apsauga ir Spec. Trąšos "
      content={
        <CultureOverviewAap
          entityId={farmersCulture._id}
          phases={farmersCulture.culture.aapPhases}
          aap={farmersCulture.aap || []}
          farmerId={farmersCulture.farmer._id}
          cultureId={farmersCulture.culture.code}
          area={farmersCulture.area}
          editable={editable}
          maxArea={maxArea}
        />
      }
    />
    {/*<ExpandableRow*/}
      {/*title="Finansinis skaičiavimas"*/}
      {/*content={*/}
        {/*<CultureOverviewFinance*/}
          {/*seeds={farmersCulture.seeds}*/}
          {/*fertilizers={farmersCulture.fertilizers}*/}
          {/*aap={farmersCulture.aap}*/}
          {/*entryId={farmersCulture._id}*/}
          {/*farmerId={farmersCulture.farmer._id}*/}
          {/*cultureId={farmersCulture.culture.code}*/}
          {/*area={farmersCulture.area}*/}
          {/*finances={farmersCulture.finances}*/}
        {/*/>*/}
      {/*}*/}
    {/*/>*/}
  </div>,
  editable ? <Link
    key="CultureOverviewFinButton"
    to={`/grow-technology/financial/${farmersCulture.culture.code}/${farmersCulture.farmer._id}/${farmersCulture.area}/${farmersCulture._id}`}
    className="button button-warning mt-4 block"
  >
    Finansinis skaičiavimas
  </Link> : null
];

class CultureOverview extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  sendProposal = ({ sendProposal, farmerId, cultureId, farmerCulture }) => {
    const closedRows = this.myRef.current.querySelectorAll('.ExpandableRow-mainRow.isClosed');
    [...closedRows].forEach(row => {
      row.click();
    });

    setTimeout(() => {
      const comments = prompt('Komentarai:');
      const domClone = this.myRef.current.cloneNode(true);
      // ą. disable all inputs
      const inputs = domClone.querySelectorAll('input, select');
      [...inputs].forEach(input => {
        input.disabled = true;
      });

      // 2. remove buttons
      const buttons = domClone.querySelectorAll('button, a');
      [...buttons].forEach(button => {
        button.remove();
      });

      // 3. remove expand icons
      const icons = domClone.querySelectorAll('.ExpandableRow-expandIcon');
      [...icons].forEach(icon => {
        icon.remove();
      });

      const user = localStorage.getItem('user-basis-ui2');
      const userId = JSON.parse(user)._id;

      const html = domClone.outerHTML.replace('<h5 class="mt-4">', ' <h5 class="ExpandableRow-title">');

      sendProposal({
        variables: {
          userId,
          farmerId,
          cultureId,
          comments,
          html,
          proposalType: PROPOSAL_TYPES.FULL_CULTURE,

        }
      }).then(() => alert('Pasūlymas išsiųstas'));
      alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    }, 300);
    return;

    //
    // const comments = prompt('Komentarai:');
    //
    // const proposedCultures = {
    //   name: farmerCulture.culture.name,
    //   cultureCode: farmerCulture.culture.code,
    //   area:  farmerCulture.area,
    //   seeds: farmerCulture.seeds.map(({ variety, rate, area, price, packsRequired}) => ({
    //     varietyName: variety.name,
    //     rate: parseFloat(rate),
    //     price: parseFloat(price),
    //     area: parseFloat(area),
    //     packsRequired
    //   })),
    //   fertilizers: farmerCulture.fertilizers.map(({ fertilizer, rate, price}) => ({
    //     name: fertilizer.name,
    //     rate: parseFloat(rate),
    //     price: parseFloat(price)
    //   })),
    //   aaps: farmerCulture.aap.map(aap => ({
    //     phaseName: aap.phase.name,
    //     date: aap.date,
    //     area: parseFloat(aap.area),
    //     products: aap.products.map(product => ({
    //       name: product.product.name,
    //       measureUnit: product.measureUnit || product.product.measureUnit,
    //       rate: parseFloat(product.quantity),
    //       price: parseFloat(product.price),
    //       isAdditional: product.isAdditional
    //     }))
    //   })),
    // };
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     cultureId,
    //     comments,
    //     proposedCultures,
    //     proposalType: PROPOSAL_TYPES.FULL_CULTURE,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    const { match } = this.props;
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    return (
      <div className="CultureOverview">
        <Query
          query={CULTURE_OVERVIEW_QUERY}
          variables={{
            farmerId: match.params.farmer,
            cultureId: match.params.culture,
            area: parseFloat(match.params.area),
          }}
        >
          {({ loading, data }) =>
            loading ? <Loader /> :
              !data || !data.farmersCulture ? <ErrorFetch /> :
                [
                  <h5 key="CultureOverviewHeader">
                    {
                      data.farmersCulture.culture.whom ?
                        data.farmersCulture.culture.whom.toUpperCase() :
                        data.farmersCulture.culture.name.toUpperCase()} <br/>
                    AUGINIMO TECHNOLOGIJA
                  </h5>,
                  <FarmersCards
                    key="FarmersCards"
                    farmer={data.farmersCulture.farmer}
                    pathRoot={match.path.split('/')[1]}
                  />,
                  <div ref={this.myRef} key="CultureOverviewContent">
                    <CultureOverviewContent
                      key="CultureOverviewContentId"
                      farmersCulture={data.farmersCulture}
                      farmerId={match.params.farmer}
                      cultureId={match.params.culture}
                      area={parseFloat(match.params.area)}
                      editable
                    />
                  </div>,
                  <Mutation
                    key="CultureOverviewMutation"
                    mutation={SEND_HTML_PROPOSAL}
                    variables={{
                      farmerId: match.params.farmer,
                      cultureId: match.params.culture,
                      proposalType: PROPOSAL_TYPES.FULL_CULTURE,
                      proposedCultures: ''
                    }}
                    refetchQueries={[
                      {
                        query: FARMER_PROPOSALS,
                        variables: { farmerId: match.params.farmer }
                      },
                      {
                        query: ALL_PROPOSALS,
                        variables: { userId }
                      }
                    ]}
                  >
                    {sendProposal => (
                      <button
                        className="button button-success mt-2"
                        onClick={() =>this.sendProposal({
                          sendProposal,
                          farmerId: match.params.farmer,
                          cultureId: data.farmersCulture.culture._id,
                          farmerCulture: data.farmersCulture
                        })}
                      >
                        Išsiųsti pasiūlymą
                      </button>
                    )}
                  </Mutation>
                ]
          }
        </Query>
      </div>
    );
  }
}

export default CultureOverview;
