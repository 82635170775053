import React from 'react';
import { Query } from 'react-apollo';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { FARMER_QUERY } from '../../queries/farmers.graphql';
import CULTURES_QUERY from '../../queries/cultures.graphql';
import FarmersCards from '../FarmersCard/FarmersCard';

import './FarmersDetails.css';
import Loader from "../../../../common/components/Loader/Loader";
import ErrorFetch from "../../../../common/components/Error/ErrorFetch";

const showCulture = (culture, farmer) =>
  farmer.areas &&
  farmer.areas[culture.code] !== null &&
  farmer.areas[culture.code] !== 0;

const FarmersCulturesListItem = ({ culture, farmer }) => (
  <Link
    className="FarmersCulturesListItem"
    to={`/grow-technology/culture-overview/${culture.code}/${farmer._id}/${
      farmer.areas[culture.code]
    }`}
  >
    <img
      className="FarmersCulturesListItem-avatar"
      src={`/assets/images/pictures/${culture.code}.jpg`}
      alt="img"
    />
    <div className="FarmersCulturesListItem-content">
      <div className="FarmersCulturesListItem-contentName">{culture.name}</div>
      <div className="FarmersCulturesListItem-contentArea">
        Plotas: {farmer.areas[culture.code]} ha
      </div>
    </div>
    <FontAwesome
      name="chevron-right"
      className="FarmersCulturesListItem-chevron"
    />
  </Link>
);

const FarmersCulturesList = ({ farmer }) => (
  <div className="FarmersCulturesList">
    <Query query={CULTURES_QUERY}>
      {({ loading, data }) =>
        loading ? <Loader /> :
          (!data || !data.cultures) ? <ErrorFetch /> :
            data.cultures.map(culture =>
              showCulture(culture, farmer) ? (
                <FarmersCulturesListItem
                  key={culture.code}
                  culture={culture}
                  farmer={farmer}
                />
              ) : null
            )
      }
    </Query>
  </div>
);

const FarmersDetails = ({ match }) => (
  <div className="FarmersDetails">
    <h5>AUGINAMŲ AUGALŲ SĄRAŠAS</h5>
    <Query query={FARMER_QUERY} variables={{ _id: match.params._id }}>
      {({ loading, data }) =>
        loading ? <Loader /> :
          (!data || !data.farmer) ? <ErrorFetch /> :
            [
              <FarmersCards
                key="FarmersCard"
                farmer={data.farmer}
                pathRoot={match.path.split('/')[1]}
              />,
              <FarmersCulturesList key="FarmersCulturesList" farmer={data.farmer} />,
            ]
      }
    </Query>
  </div>
);

export default FarmersDetails;
