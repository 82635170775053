import gql from 'graphql-tag';

export const FARMERS_CULTURE_FOR_SEEDS = gql`
  query FarmersCultureForFertilizer($_id: String!) {
    farmersCultureById(_id: $_id) {
      _id
      area
      culture {
        _id
        name
        code
        type
      }
      farmer {
        _id
        name
        email
        phone
        location
        region {
          _id
          name
        }
      }
      seeds {
        variety {
          _id
          name
        }
        varietyId
        germination
        weightPer1000
        ratePerM2
        packsRequired
        recomendedDate
        rate
        area
        price
        knownParams
      }
    }
  }
`;

export const SEED_VARIETIES = gql`
  query SeedsVarieties($cultureId: String) {
    seedVarieties(cultureId: $cultureId) {
      _id
      name
      description
      shortDescription
      descriptionURL
      price
      ratePerM2
      culture {
        _id
        code
        name
      }
      germination
      weightPer1000
      quantityPerPack
      recomendedDate
    }
  }
`;

export const CULTURE_SEEDS_UPDATE = gql`
  mutation UpdateFarmersCultureSeeds(
    $entityId: String!
    $index: Int
    $seed: inCultureSeed!
  ) {
    updateFarmersCultureSeeds(entityId: $entityId, index: $index, seed: $seed) {
      _id
    }
  }
`;

export const CULTURE_SEEDS_REMOVE = gql`
  mutation RemoveFarmersCultureSeeds($entityId: String!, $index: Int!) {
    removeFarmersCultureSeeds(entityId: $entityId, index: $index) {
      _id
    }
  }
`;
