import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FarmersList from './FarmersList/FarmersListScreen';
import FarmersForm from './FarmersForm/FarmersForm';
import FarmersDetails from './FarmersDetails/FarmersDetails';

const FarmersRoot = ({ match, isCattle }) => {
  const rootPath = match.path.split('/')[1];
  return (
    <Switch>
      <Route exact path={`/${rootPath}/farmers`} render={(props) => <FarmersList {...props} isCattle={isCattle} />} />
      <Route path={`/${rootPath}/farmers/form/:_id`} component={FarmersForm} />
      <Route
        path={`/${rootPath}/farmers/details/:_id`}
        component={FarmersDetails}
      />
    </Switch>
  );
};

export default FarmersRoot;
