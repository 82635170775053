import { LOGIN, LOGOUT } from '../actions/action-types';
import { getToken, getUser } from '../common/utils/auth.utils';

const token = getToken();
const user = getUser();

const initialState = {
  isLoggedIn: !!token,
  token,
  user,
};

export default (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case LOGIN: {
      return {
        isLoggedIn: true,
        token: action.token,
        user: action.user,
      };
    }
    case LOGOUT: {
      return {
        isLoggedIn: false,
        token: null,
        user: null,
      };
    }
    default:
      return state;
  }
};
