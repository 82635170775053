import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import GrowTechnologyRoot from '../../../grow-technology/GrowTechnologyRoot';
import GrainsRoot from '../../../grains/GrainsRoot';
import CattleRoot from '../../../cattle/CattleRoot';
import StocksRoot from '../../../stocks/StockRoot';
import FileRoot from '../../../files/FileRoot';
import Calendar from '../../../calendar/components/Calendar';
import Recommendations from '../../../recommendations/components/Recommendations';
import Proposals from '../../../proposals/components/Proposals';
import Sidebar from '../Sidebar/Sidebar';
import './App.css';

class App extends Component {
  state = { isSidebarVisible: false };

  toggleSidebar = () => {
    this.setState(state => ({ isSidebarVisible: !state.isSidebarVisible }));
  };

  render() {
    return [
      <Sidebar
        key="Sidebar"
        visible={this.state.isSidebarVisible}
        close={this.toggleSidebar}
        isLimited={this.props.isLimited}
      />,
      <div className="App" key="App">
        <Header openSidebar={this.toggleSidebar} isSidebarVisible={this.state.isSidebarVisible} />
        <div className="App-container">
          <div className="App-content">
            <Switch>
              <Route exact path="/" render={(props) => <MainMenu {...props} isLimited={this.props.isLimited} />} />
              <Route path="/grow-technology" component={GrowTechnologyRoot} />
              <Route path="/cattle" component={CattleRoot} />
              <Route path="/grains" component={GrainsRoot} />
              <Route path="/stocks" component={StocksRoot} />
              <Route path="/files" component={FileRoot} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/recommendation" component={Recommendations} />
              <Route path="/proposals" component={Proposals} />
            </Switch>
          </div>
        </div>
      </div>,
    ];
  }
}

export default App;
