import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StockList from './components/StockList';
import StockItem from './components/StockItem';

const StocksModule = () => (
  <Switch>
    <Route path="/stocks/item/:type/:id" component={StockItem} />
    <Route path="/stocks/:type?" component={StockList} />
  </Switch>
);

export default StocksModule;
