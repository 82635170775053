import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual';
import ExpandableRow from '../../../common/components/ExpandableRow/ExpandableRow';
import {
  AAP_MIX_AND_PHASE_QUERY,
} from '../queries/aap.graphql';
import { Link } from 'react-router-dom';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import './AapPhases.css';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class AapAdditionalOptions extends PureComponent {
  render() {
    const { match } = this.props;
    const entityId = match.params.id;
    const phaseId = match.params.phaseId;
    const aapMixId = match.params.mixId;
    const additionalTypes = match.params.additionalTypes.split('|').sort();
    return (
      <div className="CultureOverview">
        <h5>AAP MAIŠYMO VARIANTAI</h5>
        <Query variables={{ entityId, aapMixId }} query={AAP_MIX_AND_PHASE_QUERY}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const aapPhase = data.farmersCultureById.culture.aapPhases.find(
              phase => phase._id === phaseId
            );
            const filtered = data.aapMix.additional.reduce((acc, item) => {
              const categoryCodes = uniq(item.products.map(product => product.categoryCode).sort());
              return (isEqual(categoryCodes, additionalTypes)) ? [ ...acc, item ] : acc;
            }, []);
            return [
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={match.path.split('/')[1]}
              />,
              <div key="Augalai" className="AapType-nameTitle">
                Augalai: {data.farmersCultureById.culture.name}
              </div>,
              <div key="Augimo" className="AapType-nameTitle">
                Augimo tarpsnis: {aapPhase && aapPhase.name}
              </div>,
              <div key="MixName" className="AapType-nameTitle mb-4">
                Augalų apsaugos produktas: {data.aapMix.name}
              </div>,
              filtered.map((option, index) =>
                <ExpandableRow
                  key={`${option.name}${index}`}
                  title={option.name}
                  content={
                    <div>
                      <div>{option.description}</div>
                      <Link
                        to={`/grow-technology/aap/addSummary/${entityId}/${phaseId}/${aapMixId}/${option._id}`}
                        className="button button-success"
                      >
                        Pasirinkti
                      </Link>
                    </div>
                  }
                />
              )
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default AapAdditionalOptions;
