import gql from 'graphql-tag';

export const AAP_PRODUCTS = gql`
  query AapProductList($categoryCode: String) {
      aapProducts(categoryCode: $categoryCode) {
          _id
          name
          shortDescription
          descriptionURL
          measureUnit
          value
          maxValue
          price
      }
  }
`;

export const AapItem = gql`
    query AapItem($_id: String!) {
        aapProduct(_id: $_id) {
            name
            image {
                uri
            }
            shortDescription
            description
            price
        }
    }
`;
