import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';
import {
  SEED_VARIETIES,
  CULTURE_SEEDS_UPDATE,
  FARMERS_CULTURE_FOR_SEEDS,
} from '../queries/seeds.graphql';
import './SeedCompare.css';
import Input from "../../../common/components/Input/Input";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class SeedAddContent extends PureComponent {
  state = {};

  addKnown = (addSeeds, variables, rate) => {
    const { seed, entityId } = variables;
    addSeeds({ variables: { entityId, seed: { ...seed, rate, knownParams: false } } });
    this.props.onSelected();
  };

  updateDate = ({ target }) => this.setState({ recomendedDate: target.value });
  updateGermination = ({ target }) => this.setState({ germination: target.value });
  updateQuantityPerPack = ({ target }) => this.setState({ quantityPerPack: target.value });
  updateDensity = ({ target }) => this.setState({ density: target.value });
  updateWeightPer1000 = ({ target }) => this.setState({ weightPer1000: target.value });
  updateRatePerM2 = ({ target }) => this.setState({ ratePerM2: target.value });

  render() {
    const { variety, area, entityId, cultureCode } = this.props;

    const germination = this.state.germination || this.state.germination === '' ?
      this.state.germination : variety.germination;
    const quantityPerPack = this.state.quantityPerPack || this.state.quantityPerPack === '' ?
      this.state.quantityPerPack : variety.quantityPerPack;
    const density = this.state.density || this.state.density === '' ? this.state.density : variety.density;
    const weightPer1000 = this.state.weightPer1000 || this.state.weightPer1000 === '' ?
      this.state.weightPer1000 : variety.weightPer1000;
    const ratePerM2 = this.state.ratePerM2 || this.state.ratePerM2 === '' ? this.state.ratePerM2 : variety.ratePerM2;

    const rate = !germination || germination === 0 ? 0 : (ratePerM2 * weightPer1000) / germination;
    const demandInPack = !quantityPerPack || quantityPerPack === 0 ? 0 :
      ((density * 100000) / (quantityPerPack * 1000000)) / 10;
    const variables = {
      entityId,
      seed: {
        varietyId: variety._id,
        germination: parseFloat(germination),
        weightPer1000: parseFloat(weightPer1000),
        packsRequired: demandInPack ? (Math.round(demandInPack * 100) / 100) : null,
        ratePerM2: variety.ratePerM2,
        recomendedDate: this.state.recomendedDate,
        rate: variety.ratePerM2,
        price: variety.price,
        area,
        knownParams: false
      },
    };

    return (
      <Mutation
        mutation={CULTURE_SEEDS_UPDATE}
        refetchQueries={() => [
          {
            query: FARMERS_CULTURE_FOR_SEEDS,
            variables: { _id: entityId },
          },
        ]}
      >
        {addSeeds => (
          <div className="text-center px-2 py-2">
            {/*{variety.shortDescription}*/}
            {cultureCode !== 'rapsai' ? (
              <div className="mb-2 SeedCompare-addUnknown">
                <button
                  className="button button-warning mt-2"
                  onClick={() => {addSeeds({ variables }); this.props.onSelected()}}
                >
                  Kokybiniai parametrai nežinomi
                </button>
              </div>) : (<div className="mb-2 mt-2 SeedCompare-addUnknown" />)
            }
            <div>
              Rekomenduojama sėjos data
              <Input
                type="text"
                className="text-field text-center"
                value={this.state.recomendedDate}
                onChange={this.updateDate}
              />
            </div>

            {cultureCode !== 'rapsai' ?
              [
                <div>
                  Sėklos daigumas procentais, %
                  <Input
                    type="number"
                    className="text-field text-center"
                    value={germination}
                    onChange={this.updateGermination}
                  />
                </div>,
                <div>
                1000 sėklų svoris, g.
                <Input
                  type="number"
                  className="text-field text-center"
                  value={weightPer1000}
                  onChange={this.updateWeightPer1000}
                />
                </div>,
                <div>
                  Planuojamas pasėlio tankis - augalų / m&#178;
                  <Input
                    type="number"
                    className="text-field text-center"
                    value={ratePerM2}
                    onChange={this.updateRatePerM2}
                  />
                </div>,
                <h5>Paskaičiuota norma: {numeral(rate).format('0')} (kg/ha)</h5>
              ] : [
                <div>
                  Sėklų kiekis pakuotėje – mln.
                  <Input
                    className="text-field text-center"
                    disabled
                    value={quantityPerPack}
                    onChange={this.updateQuantityPerPack}
                  />
                </div>,
                <div>
                  Planuojamas pasėlio tankis – augalų / m2
                  <Input
                    type="number"
                    className="text-field text-center"
                    value={density}
                    onChange={this.updateDensity}
                  />
                </div>,
                <h5>Sėklų poreikis pakuotėmis: {numeral(demandInPack).format('0.[00]')} (vnt./ha)</h5>
              ]
            }
            <div className="pt-1">
              <button
                className="button button-success"
                disabled={!rate && !demandInPack} onClick={() => this.addKnown(addSeeds, variables, rate )}
              >
                Pridėti sėklas
              </button>
            </div>
          </div>)
        }
      </Mutation>
    )
  }
}

const SeedCompareRow = ({
  entityId,
  variety,
  area,
  index,
  opened,
  toggleRow,
  cultureCode,
}) => [
  <tr key={variety._id} onClick={() => toggleRow(index)}>
    <td className="SeedCompare-name">
      {variety.descriptionURL ? <a target="_blank" href={variety.descriptionURL}>{variety.name}</a> : variety.name}
    </td>
    <td>{variety.ratePerM2}</td>
    <td>{variety.price}</td>
    <td>
      <FontAwesome name="shopping-cart" />
    </td>
  </tr>,
  opened === index ? (
    <tr key={`${variety._id}_details`}>
      <td colSpan="4">
        <SeedAddContent
          variety={variety}
          area={area}
          entityId={entityId}
          cultureCode={cultureCode}
          onSelected={() => toggleRow(index)}
        />
      </td>
    </tr>
  ) : null,
];

class SeedCompare extends PureComponent {
  state = { opened: -1 };

  toggleOpen = index =>
    this.setState(state => ({ opened: state.opened === index ? -1 : index }));

  render() {
    return (
      <div>
        <h5>Prekių sąrašas</h5>
        <table className="table SeedCompare-table" align="center">
          <tbody>
            <tr>
              <td>
                <strong>Prekė</strong>
              </td>
              <td className="SeedCompare-normColumn">
                <strong>Norma (kg/ha)</strong>
              </td>
              <td>
                <strong>Kaina (Eur/t)</strong>
              </td>
              <td>
                <strong>Pasirinkti</strong>
              </td>
            </tr>
            <Query
              variables={{
                cultureId: this.props.cultureId,
              }}
              query={SEED_VARIETIES}
            >
              {({ loading, data }) =>
                loading ? <tr><td colSpan="4"><Loader /></td></tr> :
                  (!data || !data.seedVarieties) ? <tr><td colSpan="4"><ErrorFetch /></td></tr> :
                    data.seedVarieties
                      .filter(item => !this.props.selectedSeeds.includes(item._id))
                      .map((variety, index) => (
                        <SeedCompareRow
                          key={variety._id}
                          variety={variety}
                          index={index}
                          opened={this.state.opened}
                          toggleRow={this.toggleOpen}
                          area={this.props.area}
                          entityId={this.props.entityId}
                          cultureCode={this.props.cultureCode}
                        />
                      ))
              }
            </Query>
          </tbody>
        </table>
      </div>
    );
  }
}

export default SeedCompare;
