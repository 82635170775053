import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { withApollo } from 'react-apollo';
import { LOG_ACTION } from '../../queries/log.mutation';

import 'font-awesome/css/font-awesome.css';
import './Header.css';

class Header extends Component {
  static propTypes = {
    openSidebar: PropTypes.func,
    isSidebarVisible: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    this.state = {
      userId,
      stepCount: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location.pathname);
    }
  }

  onRouteChanged(path) {
    // console.log("ROUTE CHANGED", path, this.props.client.mutate);
    const {userId, stepCount } = this.state;
    const step = stepCount + 1;
    this.props.client.mutate({
      mutation: LOG_ACTION,
      variables: {
        action: {
          userId,
          url: path,
          step
        }
      }
    });
    this.setState({ stepCount: step });
  }

  backHandler = () => {
    if (this.props.location.pathname !== '/') {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <header className="Header">
        <div className="Header-leftButton">
          {this.props.location.pathname !== '/' ? (
            <div className="Header-button" onClick={this.backHandler}>
              <FontAwesome name="arrow-left" size="2x" />
            </div>
          ) : null}
        </div>
        <div className="Header-mainContainer">
          <NavLink to="/" className="Header-logo">
            <img alt="logo" className="Header-logoImage" src="/assets/logo-dark.png" />
          </NavLink>
        </div>
        <div className="Header-rightButton">
          <div className="Header-button" onClick={this.props.openSidebar}>
            <button id="burger" className={this.props.isSidebarVisible ? 'open' : ''}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default withApollo(withRouter(Header));
