import React from 'react';

const FertilizerBalance = ({
  getNeed,
  yields,
  selectedYield,
  fertilizeType,
  // cultureNPKS, => yields.NPKS
  balance,
}) => (
  <div>
    <table cellSpacing="0" className="table" align="center">
      <thead>
        <tr>
          <td>&nbsp;</td>
          <td>
            <strong>N</strong>
          </td>
          <td>
            <strong>P</strong>
          </td>
          <td>
            <strong>K</strong>
          </td>
          <td>
            <strong>S</strong>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Maisto medžiagų poreikis</strong>
          </td>
          <td>
            <strong>{getNeed(yields[selectedYield].NPKS.N, fertilizeType)}</strong>
          </td>
          <td>
            <strong>{getNeed(yields[selectedYield].NPKS.P, fertilizeType)}</strong>
          </td>
          <td>
            <strong>{getNeed(yields[selectedYield].NPKS.K, fertilizeType)}</strong>
          </td>
          <td>
            <strong>{getNeed(yields[selectedYield].NPKS.S, fertilizeType)}</strong>
          </td>
        </tr>
        <tr>
          <td>Balansas su trąšomis</td>
          <td>{Math.round(balance.N)}</td>
          <td>{Math.round(balance.P)}</td>
          <td>{Math.round(balance.K)}</td>
          <td>{Math.round(balance.S)}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default FertilizerBalance;
