import gql from 'graphql-tag';

export const FERTILIZERS_LIST = gql`
  query FertilizerList($typeCode: String) {
      fertilizers(type: $typeCode) {
          _id
          name
          shortDescription
          descriptionURL
          price
          acuracy
      }
  }
`;

export const FertilizerItem = gql`
    query FertilizerItem($_id: String!) {
        fertilizer(_id: $_id) {
            name
            image {
                uri
            }
            shortDescription
            description
            price
        }
    }
`;
