import React, { Component } from 'react';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CULTURE_CATTLE_REMOVE_ROW, CULTURE_CATTLE_UPDATE_ROW, FARMERS_CATTLE } from "../queries/cattle.query";
import { Mutation } from "react-apollo";
import Input from "../../common/components/Input/Input";

class RowTr extends Component {
  state = {};

  handleNormChange = ({ target }) => this.setState({ norm: target.value });
  handleRateChange = ({ target }) => this.setState({ rate: target.value });
  getValue = (state, farmersCattle, option) => (state[option] || state[option] === '') ?
    state[option] : farmersCattle[option];

  handleEdit = ({ editRow, product, norm, rate }) => (this.state.norm || this.state.rate) && editRow({
    variables: {
      _id: this.props.entityId,
      index: this.props.index,
      farmersCattleProduct: {
        productId: product.productId,
        norm: parseFloat(norm),
        rate: parseFloat(rate)
      }
    },
    onCompleted: () =>
      this.setState({
        norm: null,
        rate: null,
      }),
  });

  render() {
    const { product, onRemove, animalCount, farmerId, categoryCode } = this.props;
    const norm = this.getValue(this.state, product, 'norm');
    const rate = this.getValue(this.state, product, 'rate');

    return (
      <Mutation
        mutation={CULTURE_CATTLE_UPDATE_ROW}
        refetchQueries={[
          {
            query: FARMERS_CATTLE,
            variables: { farmerId, categoryCode }
          }
        ]}
      >
        {editRow => {
          const editValue = () => this.handleEdit({ editRow, product, norm, rate });
          return (
            <tr>
              <td className="text-left">
                {product.product.name}
              </td>
              <td>
                <Input
                  type="number"
                  className="table-input"
                  value={norm}
                  onChange={this.handleNormChange}
                  onBlur={editValue}
                />
              </td>
              <td>
                <Input
                  type="number"
                  className="table-input"
                  value={rate}
                  onChange={this.handleRateChange}
                  onBlur={editValue}
                />
              </td>
              <td>
                {product.product.measureUnit}
              </td>
              <td>
                {numeral(product.norm * animalCount).format('0.[00]')}
              </td>
              <td onClick={onRemove}>
                <FontAwesomeIcon icon={faTrash} />
              </td>
            </tr>
          )}
        }
      </Mutation>

    )
  }
}

const CattleSelectedRow = ({ product, index, animalCount, entityId, farmerId, categoryCode }) => (
  <Mutation
    mutation={CULTURE_CATTLE_REMOVE_ROW}
    refetchQueries={[
      {
        query: FARMERS_CATTLE,
        variables: { farmerId, categoryCode }
      }
    ]}
  >
    {removeRow => {
      const handleRemove = () => removeRow({ variables: { _id: entityId, index }});
      return (
        <RowTr
          entityId={entityId}
          index={index}
          product={product}
          onRemove={handleRemove}
          animalCount={animalCount}
          farmerId={farmerId}
          categoryCode={categoryCode}
        />
      )
    }}
  </Mutation>
);

const CattleSelectedProducts = ({ products, animalCount, entityId, farmerId, categoryCode }) => {
  const [ rows, sum ] = products.reduce((acc, product, index) =>
    [ [ ...acc[0], (
      <CattleSelectedRow
        product={product}
        index={index}
        animalCount={animalCount}
        entityId={entityId}
        categoryCode={categoryCode}
        farmerId={farmerId}
      />
    ) ], acc[1] + (product.rate * product.norm * animalCount) ],
    [ [], 0 ]
  );
  return [
    <h5>Įdėta prekių</h5>,
    <table className="table" align="center">
      <tbody>
      <tr>
        <td className="text-left">
          <strong>Prekė</strong>
        </td>
        <td>
          <strong>Norma</strong>
        </td>
        <td>
          <strong>Kaina Eur/t</strong>
        </td>
        <td>
          <strong>Mato vnt.</strong>
        </td>
        <td>
          <strong>Kiekis</strong>
        </td>
        <td />
      </tr>
      {rows}
      <tr>
        <td colSpan="3">Pasiūlųmo kaina:</td>
        <td colSpan="3">{numeral(sum).format('0.[00]')} Eur</td>
      </tr>
      </tbody>
    </table>
  ];
};

export default CattleSelectedProducts;
