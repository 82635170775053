import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import uniq from 'lodash/uniq';
import { ADDITIONAL_FERTILEZER_LABELS } from '../aap.constants';
import {
  AAP_MIX_AND_PHASE_QUERY,
} from '../queries/aap.graphql';
import './AapType.css';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import { Link } from 'react-router-dom';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

const Button = props => <button {...props} />;

class AapType extends PureComponent {
  state = { selected: [] };
  toggleSelected = id =>
    this.setState(state => {
      const selectedIndex = state.selected.indexOf(id);
      const currentSelection = [...state.selected];
      if (selectedIndex > -1) {
        currentSelection.splice(selectedIndex, 1);
      } else {
        currentSelection.push(id);
      }
      return {
        selected: currentSelection,
      };
    });
  render() {
    const entityId = this.props.match.params.id;
    const phaseId = this.props.match.params.phaseId;
    const aapMixId = this.props.match.params.mixId;
    return (
      <div className="AapType">
        <h5>SPEC. TRĄŠŲ MAIŠYMO VARIANTAI</h5>
        <Query variables={{ entityId, aapMixId }} query={AAP_MIX_AND_PHASE_QUERY}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const aapPhase = data.farmersCultureById.culture.aapPhases.find(
              phase => phase._id === phaseId
            );

            const {
              categoriesAll,
              availableMixes,
            } = data.aapMix.additional.reduce(
              (acc, item) => {
                acc.categoriesAll.push(...item.products.map(product => product.categoryCode));
                if (this.state.selected.length > 0) {
                  const uniqCodes = uniq(item.products.map(product => product.categoryCode));
                  const matches =
                    uniqCodes.length === this.state.selected.length &&
                    uniqCodes.reduce(
                      (ac, code) =>
                        ac && this.state.selected.includes(code),
                      true
                    );

                  if (matches) {
                    acc.availableMixes.push(item);
                  }
                }
                return acc;
              },
              { categoriesAll: [], availableMixes: [] }
            );
            // console.log(data.aapMix.additional, categoriesAll, availableMixes);
            const LinkComponent =
              availableMixes.length === 0 ? Button : Link;

            const categoriesList = uniq(categoriesAll).map((category, index) => (
              <button
                key={`categoriesListButton${index}`}
                className={`tasklist-item ${this.state.selected.includes(
                  category
                ) && 'tasklist-completed'}`}
                onClick={() => this.toggleSelected(category)}
              >
                <i className="fa fa-check" />
                <h5>{ADDITIONAL_FERTILEZER_LABELS[category]}</h5>
              </button>
            ));
            return [
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={this.props.match.path.split('/')[1]}
              />,
              <div key="Augalai" className="AapType-nameTitle">
                Augalai: {data.farmersCultureById.culture.name}
              </div>,
              <div key="Augimo" className="AapType-nameTitle">
                Augimo tarpsnis: {aapPhase && aapPhase.name}
              </div>,
              <div key="MixName" className="AapType-nameTitle mb-4">
                Augalų apsaugos produktas: {data.aapMix.name}
              </div>,
              <div key="description" className="AapType-description mb-1">
                Pasirinkite augalų apsaugos produktus kuriuos norite naudoti vienu metu šiame tarpsnyje:
              </div>,
              ...categoriesList,
              <div className="mt-4 text-center" key="Galimi_produktai">
                <LinkComponent
                  to={`/grow-technology/aap/mixAdditionalOptions/${entityId}/${phaseId}/${data.aapMix._id}/${this.state.selected.join('|')}`}
                  className="button button-success"
                  disabled={availableMixes.length === 0}
                >
                  Galimi produktai ({availableMixes.length})
                </LinkComponent>
              </div>
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default AapType;
