import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import ExpandableRow from '../../../common/components/ExpandableRow/ExpandableRow';
import { AAP_MIXES_QUERY, FARMERS_CULTURE_PHASES } from '../queries/aap.graphql';
import { Link } from 'react-router-dom';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import './AapPhases.css';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";
import { PRODUCT_CATEGORIES_LABELS } from '../aap.constants';

class AapMixOptions extends PureComponent {
  render() {
    const entityId = this.props.match.params.id;
    const phaseId = this.props.match.params.phaseId;
    const mixTypes = this.props.match.params.mixTypes.split('|');
    return (
      <div className="CultureOverview">
        <h5>AAP MAIŠYMO VARIANTAI</h5>
        <Query variables={{ _id: entityId }} query={FARMERS_CULTURE_PHASES}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const aapPhase = data.farmersCultureById.culture.aapPhases.find(
              phase => phase._id === phaseId
            );
            return [
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={this.props.match.path.split('/')[1]}
              />,
              <div key="Augalai" className="AapType-nameTitle">
                Augalai: {data.farmersCultureById.culture.name}
              </div>,
              <div key="Augimo" className="AapType-nameTitle">
                Augimo tarpsnis: {aapPhase && aapPhase.name}
              </div>,
              <div key="tipai" className="AapType-nameTitle mb-4">
                AAP produktai: {mixTypes.map(type => PRODUCT_CATEGORIES_LABELS[type]).join(' + ')}
              </div>,
              <div key="description" className="AapType-description mb-1">
                Pasirinkite vieną iš visų galimų purkšimo variantų:
              </div>,
              <Query
                key="AAP_MIXES_QUERY"
                variables={{ culturePhaseCode: aapPhase.code, categories: mixTypes }}
                query={AAP_MIXES_QUERY}
              >
                {(optionsResponse) => {
                  if (optionsResponse.loading) return <Loader />;
                  if (!optionsResponse.data || !optionsResponse.data.aapMixes) return <ErrorFetch />;

                  return [

                    optionsResponse.data.aapMixes.map(option =>
                      <ExpandableRow
                        key={option._id}
                        title={option.name}
                        content={
                          <div>
                            <div>{option.description}</div>
                            <Link
                              to={`/grow-technology/aap/mixAdditionalCategories/${entityId}/${phaseId}/${option._id}`}
                              className="button button-success"
                            >
                              Pasirinkti
                            </Link>
                          </div>
                        }
                      />
                    )
                  ];
                }}
              </Query>
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default AapMixOptions;
