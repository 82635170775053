import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AapPhases from './components/AapPhases';
import AapTypes from './components/AapTypes';
import AapMixOptions from './components/AapMixOptions';
import AapAdditionalCategories from './components/AapAdditionalCategories';
import AapAdditionalOptions from './components/AapAdditionalOptions';
import AapAddSummary from './components/AapAddSummary';
import AapAddPro from './components/AapAddPro';

const AapRoot = () => (
  <Switch>
    <Route exact path="/grow-technology/aap/:id/:isPro" component={AapPhases} />
    <Route
      exact
      path="/grow-technology/aap/type/:id/:phaseId/:phaseCode"
      component={AapTypes}
    />
    <Route
      exact
      path="/grow-technology/aap/mixOptions/:id/:phaseId/:mixTypes"
      component={AapMixOptions}
    />
    <Route
      exact
      path="/grow-technology/aap/mixAdditionalCategories/:id/:phaseId/:mixId"
      component={AapAdditionalCategories}
    />
    <Route
      exact
      path="/grow-technology/aap/mixAdditionalOptions/:id/:phaseId/:mixId/:additionalTypes"
      component={AapAdditionalOptions}
    />
    <Route
      exact
      path="/grow-technology/aap/addSummary/:id/:phaseId/:mixId/:additionalId"
      component={AapAddSummary}
    />
    <Route
      exact
      path="/grow-technology/aap/pro/:id/:phaseId/:phaseCode"
      component={AapAddPro}
    />
  </Switch>
);

export default AapRoot;
