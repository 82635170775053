import gql from 'graphql-tag';

export const SeedList = gql`
  query SeedList($cultureCode: String) {
      seedVarieties(cultureCode: $cultureCode) {
          _id
          name
          shortDescription
          descriptionURL
          price
          ratePerM2
          rateUnit
      }
  }
`;

export const SeedItem = gql`
  query SeedItem($_id: String!) {
      seedVariety(_id: $_id) {
          name
          image {
              uri
          }
          shortDescription
          description
          price
      }
  }
`;
