import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import numeral from 'numeral';
import {
  FARMERS_CULTURE_FOR_FERTILIZER,
  FERTILIZER_REMOVE_MUTATION,
  FERTILIZER_UPDATE_MUTATION,
} from '../queries/fertilizers.graphql';
import Input from "../../../common/components/Input/Input";

import './SelectedFertilizers.css';

class SelectedFertilizers extends PureComponent {
  state = { modifiedRate: {}, modifiedPrice: {} };

  render() {
    const { entityId, fertilizers, getValue, area } = this.props;
    let totalRate = 0;
    let totalPrice = 0;
    return (
      <div>
        <div>Tręšimas skaičiuojamas {area} Ha</div>
        <table className="table" align="center">
          <tbody>
            <tr>
              <td>
                <strong>Produktas</strong>
              </td>
              <td>
                <strong>kg/ha</strong>
              </td>
              <td>
                <strong>Trašų kiekis t</strong>
              </td>
              <td>
                <strong>Kaina t.</strong>
              </td>
              <td>
                <strong>N</strong>
              </td>
              <td>
                <strong>P</strong>
              </td>
              <td>
                <strong>K</strong>
              </td>
              <td>
                <strong>S</strong>
              </td>
              <td />
            </tr>
            {fertilizers.map((fertilizer, index) => {
              const { modifiedRate, modifiedPrice } = this.state;
              const rate =
                modifiedRate[index] || modifiedRate[index] === '' ?
                  modifiedRate[index] : fertilizer.rate;
              const price =
                modifiedPrice[index] || modifiedPrice[index] === '' ?
                  modifiedPrice[index] : fertilizer.price;
              totalRate += rate * area / 1000;
              totalPrice += rate * area * price / 1000;
              return [
                <Mutation
                  key={`selectedFertis${index}`}
                  mutation={FERTILIZER_REMOVE_MUTATION}
                  variables={{ index, entityId }}
                  refetchQueries={[
                    {
                      query: FARMERS_CULTURE_FOR_FERTILIZER,
                      variables: { _id: entityId },
                    },
                  ]}
                >
                  {removeMutation => (
                    <Mutation
                      mutation={FERTILIZER_UPDATE_MUTATION}
                      refetchQueries={[
                        {
                          query: FARMERS_CULTURE_FOR_FERTILIZER,
                          variables: { _id: entityId },
                        },
                      ]}
                    >
                      {updateMutation => {
                        const onBlur = () => {
                          if (modifiedPrice[index] || modifiedRate[index]) {
                            updateMutation({
                              variables: {
                                entityId,
                                index,
                                rate:
                                  parseFloat(modifiedRate[index]) ||
                                  fertilizer.rate,
                                price:
                                  parseFloat(modifiedPrice[index]) ||
                                  fertilizer.price,
                              },
                              onCompleted: () =>
                                this.setState({
                                  modifiedRate: {},
                                  modifiedPrice: {},
                                }),
                            });
                          }
                        };
                        return (
                          <tr>
                            <td>{fertilizer.fertilizer.name}</td>
                            <td>
                              <Input
                                className="SelectedFertilizers-input"
                                type="number"
                                value={rate}
                                onChange={event =>
                                  this.setState({
                                    modifiedRate: { [index]: event.target.value },
                                  })
                                }
                                onBlur={onBlur}
                              />
                            </td>
                            <td>
                              {numeral((area * rate) / 1000).format('0.[000]')}
                            </td>
                            <td>
                              <Input
                                className="SelectedFertilizers-input"
                                type="number"
                                value={price}
                                onChange={event =>
                                  this.setState({
                                    modifiedPrice: {
                                      [index]: event.target.value,
                                    },
                                  })
                                }
                                onBlur={onBlur}
                              />
                            </td>
                            <td>{getValue(fertilizer, 'N')}</td>
                            <td>{getValue(fertilizer, 'P')}</td>
                            <td>{getValue(fertilizer, 'K')}</td>
                            <td>{getValue(fertilizer, 'S')}</td>
                            <td>
                              <strong>
                                <button onClick={removeMutation}>x</button>
                              </strong>
                            </td>
                          </tr>
                        );
                      }}
                    </Mutation>
                  )}
                </Mutation>
              ]
            })}
            <tr>
              <td className="table-summaryRow text-left" colSpan="2">Viso:</td>
              <td className="table-summaryRow">{numeral(totalRate).format('0.[000]')}</td>
              <td className="table-summaryRow">Viso kaina:</td>
              <td className="table-summaryRow text-right" colSpan="4">{numeral(totalPrice).format('0.[00]')}</td>
              <td className="table-summaryRow"></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default SelectedFertilizers;
