import React from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import { GRAIN_PRICES } from '../queries/grains.graphql';
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const GrainPrices = () => (
  <div className="px-3">
    <h5>Grūdų dienos kaina</h5>
    <Query query={GRAIN_PRICES}>
      {({ loading, data }) => {
        if (loading) return <Loader />;
        if (!data || !data.grainPrices) return <ErrorFetch />;

        const lastUpdatedStamp = data.grainPrices.reduce(
          (acc, item) => item.updateTime > acc ? item.updateTime : acc, 0
        );
        const lastUpdateComponent = !lastUpdatedStamp ? null :
          <h4>{moment(lastUpdatedStamp * 1000).format('YYYY-MM-DD HH:mm')}</h4>;
        return [
          lastUpdateComponent,
          <table className="table">
            <tbody>
              <tr>
                <td><b>Kultūra</b></td>
                <td><b>Kaina Eur/t</b></td>
              </tr>
              {data.grainPrices.map(item => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>,
          <div className="text-center">Kainininkas galioja iki sekančio atnaujinimo</div>
        ]
      }}
    </Query>
  </div>
);

export default GrainPrices
