import React from 'react';
import { Query } from 'react-apollo';
import { FileTypesQuery } from '../queries/files.query';
import '../../common/components/MenuScreen/MenuScreen.css';
import { NavLink } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const FileTypes = () => (
  <div className="MenuScreen-root FileTypes">
    <h5 className="MenuScreen-container">FAILŲ SAUGYKLA</h5>
    <div className="MenuScreen-container">
      <Query query={FileTypesQuery} >
        {
          ({loading, data}) =>
            loading ? <Loader /> :
              !data || !data.fileTypes ? <ErrorFetch /> :
                data.fileTypes.map(type => (
                  <NavLink className="MenuItem" to={`/files/subtypes/${type._id}`}>
                    <div className="MenuItem-label">{type.name}</div>
                  </NavLink>
                ))
        }
      </Query>
    </div>
  </div>
);

export default FileTypes;
