import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FarmersRoot from './farmers/components/FarmersRoot';
import AapRoot from './aap/AapRoot';
import CultureOverview from './cultureOverview/components/CultureOverview';
import FarmersCultureFinancial from './financial/components/FarmersCultureFinancial';
import Fertilizers from './fertilizers/components/Fertilizers';
import Seeds from './seeds/components/Seeds';
import FarmersDetails from "./farmers/components/FarmersDetails/FarmersDetails";

const GrowTechnologyModule = () => (
  <Switch>
    <Route path="/grow-technology/farmers" component={FarmersRoot} />
    <Route path="/grow-technology/aap" component={AapRoot} />
    <Route path="/grow-technology/list/:_id" component={FarmersDetails} />
    <Route
      path="/grow-technology/culture-overview/:culture/:farmer/:area"
      component={CultureOverview}
    />
    <Route
      path="/grow-technology/financial/:culture/:farmer/:area/:entityId"
      component={FarmersCultureFinancial}
    />
    <Route path="/grow-technology/fertilizers/:id" component={Fertilizers} />
    <Route path="/grow-technology/seeds/:id" component={Seeds} />

    <Redirect to="/grow-technology/farmers" />
  </Switch>
);

export default GrowTechnologyModule;
