import gql from 'graphql-tag';
export default gql`
  {
    regions {
      _id
      name
      code
    }
  }
`;
