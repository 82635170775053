import { GRAINS_MENU } from '../../grains/grains.constants';
import {
  faBook,
  faAddressCard,
  faAddressBook,
  faHandHoldingUsd,
  faWarehouse,
  faHandPointRight,
  faCalendar,
  faArchive,
  faBarcode,
  faSeedling,
  faListAlt,
  faList,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt as farListAlt } from '@fortawesome/free-regular-svg-icons';

export const MENU_LIST = isLimited => isLimited ?
  [
    {
      path: '/grow-technology',
      label: 'BA AUGINIMO TECHNOLOGIJA',
      iconName: faBook,
      sub: [
        {
          path: '/grow-technology/farmers',
          label: 'Ūkininkų sąrašas',
          iconName: faAddressCard
        },
        {
          path: '/grow-technology/farmers/form/null',
          label: 'Pridėti ūkininką',
          iconName: faAddressBook
        }
      ]
    }
  ]
  : [
  {
    path: '/grow-technology',
    label: 'BA AUGINIMO TECHNOLOGIJA',
    iconName: faBook,
    sub: [
      {
        path: '/grow-technology/farmers',
        label: 'Ūkininkų sąrašas',
        iconName: faAddressCard
      },
      {
        path: '/grow-technology/farmers/form/null',
        label: 'Pridėti ūkininką',
        iconName: faAddressBook
      }
    ]
  },
  {
    path: '/stocks',
    label: 'PRODUKTŲ KATALOGAS',
    iconName: faBarcode,
    sub: [
      {
        path: '/stocks/seeds',
        label: 'Sėklos',
        iconName: faSeedling
      },
      {
        path: '/stocks/fertilizer',
        label: 'Trąšos',
        iconName: farListAlt
      },
      {
        path: '/stocks/aap',
        label: 'AAP',
        iconName: faListAlt
      },
      {
        path: '/stocks/additional',
        label: 'Spec. Trašos',
        iconName: faList
      },
      {
        path: '/stocks/cattle',
        label: 'Gyvulininkystės',
        iconName: faWarehouse
      },
    ]
  },
  {
    path: '/grains',
    label: 'GRŪDŲ SUPIRKIMAS',
    iconName: faHandHoldingUsd,
    sub: GRAINS_MENU
  },
  {
    path: '/cattle',
    label: 'GYVULININKYSTĖ',
    iconName: faWarehouse,
    sub: [
      {
        path: '/cattle/farmers',
        label: 'Pasiūlymas',
        iconName: faAddressCard
      },
      {
        path: '/stocks/cattle',
        label: 'Prekės',
        iconName: faBarcode
      },
    ]
  },
  {
    path: '/recommendation',
    label: 'DIENOS REKOMENDACIJA',
    iconName: faHandPointRight,
  },
  {
    path: '/calendar',
    label: 'KALENDORIUS',
    iconName: faCalendar
  },
  {
    path: '/files',
    label: 'FAILŲ SAUGYKLA',
    iconName: faArchive
  },
  {
    path: '/proposals',
    label: 'IŠSIŲSTI PASIŪLYMAI',
    iconName: faFile
  },
];
