import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { AAP_CATEGORIES } from '../constants/aap.constants';
import { AAP_PRODUCTS } from '../queries/aap.query';
import ListRow from './ListRow';
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

class AapList extends Component {
  state = { selectedType: 'ALL'};

  handleTypeSelect = ({ target }) =>
    this.setState({ selectedType: target.value });;

  render() {
    const { selectedType } = this.state;
    const categoryCode = selectedType === 'ALL' ? undefined : selectedType;
    return [
      <select
        className="select"
        onChange={this.handleTypeSelect}
        value={this.state.selectedType}
      >
        <option value="ALL">Visi AAP produktai</option>
        {AAP_CATEGORIES.map(item => (
          <option
            key={`categoryCodeOption${item.type}`}
            value={item.type}
            onChange={this.handleTypeSelect}
          >
            {item.label}
          </option>
        ))}
      </select>,
      <div className="ListItemsContainer">
        <table className="table" align="center">
          <tbody>
            <tr>
              <td className="text-left">
                <strong>Prekė</strong>
              </td>
              <td>
                <strong>Norma nuo</strong>
              </td>
              <td>
                <strong>Norma iki</strong>
              </td>
              <td>
                <strong>Kaina (vnt.)</strong>
              </td>
              {/*<td />*/}
            </tr>
            <Query
              query={AAP_PRODUCTS}
              variables={{ categoryCode }}
            >
              {({ loading, data }) =>
                loading ? <Loader /> :
                  !data || !data.aapProducts ? <ErrorFetch /> :
                  data.aapProducts.map(item => (<ListRow product={item} type="aap" />))
              }
            </Query>
          </tbody>
        </table>
      </div>
    ]
  }

}

export default AapList;
