import React, { Component } from 'react';
import { Mutation, Query } from "react-apollo";
import Select from 'react-select';
import {
  FARMERS_CATTLE,
  CULTURE_CATTLE_UPDATE,
  CULTURE_CATTLE_UPDATE_ROW
} from "../queries/cattle.query";
import FarmersCards from "../../grow-technology/farmers/components/FarmersCard/FarmersCard";
import CattleProductsTable from './CattleProductsTable';
import CattleSelectedProducts from "./CattleSelectedProducts";
import './CattleCalculator.css';
import { FarmersQuery } from "../../files/queries/files.query";
import { SEND_HTML_PROPOSAL } from "../../grow-technology/cultureOverview/queries/cultureOverview.graphql";
import { PROPOSAL_TYPES } from "../../common/constants/proposal.constants";
import { FARMER_PROPOSALS, ALL_PROPOSALS } from "../../proposals/queries/proposals.graphql";
import Input from "../../common/components/Input/Input";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

class CattleCalculator extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = { selectedFarmers: [] };

  handleAnimalCountChange = ({ target }) => this.setState({ animalCount: target.value });
  handleDaysValidChange = ({ target }) => this.setState({ daysValid: target.value });
  getValue = (state, farmersCattle, option) => (state[option] || state[option] === '') ?
    state[option] : farmersCattle[option];

  onFarmersChange = (selected) => this.setState({ selectedFarmers: selected.map(item => item._id) });

  handleProposal = ({sendProposal, products, animalCount, daysValid, entityId, categoryCode, farmerId}) => {
    const comments = prompt('Komentarai:');
    const domClone = this.myRef.current.cloneNode(true);
    // 2. disable all inputs
    const inputs = domClone.querySelectorAll('input, select');
    [...inputs].forEach(input => {
      input.disabled = true;
    });

    // 2. disable all inputs
    const buttons = domClone.querySelectorAll('button, a');
    [...buttons].forEach(button => {
      button.remove();
    });

    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;

    sendProposal({
      variables: {
        userId,
        farmerId,
        comments,
        html: domClone.outerHTML,
        proposalType: PROPOSAL_TYPES.CATTLE,

      }
    }).then(() => alert('Pasūlymas išsiųstas'));
    alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');

    // const comments = prompt('Komentarai:');
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // const proposedCattle = {
    //   category: categoryCode,
    //   animalCount,
    //   daysValid,
    //   products: products.map(product => ({
    //     productId: product.productId,
    //     name: product.product.name,
    //     norm: product.norm,
    //     rate: product.rate,
    //     measureUnit: product.product.measureUnit
    //   }))
    // };
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     comments,
    //     proposedCattle,
    //     proposalType: PROPOSAL_TYPES.CATTLE,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    const { params } = this.props.match;
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    return (
      <div className="px-3">
        <Query
          query={FARMERS_CATTLE}
          variables={{ farmerId: params.farmer, categoryCode: params.category }}
        >
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCattle) return <ErrorFetch />;

            const { farmersCattle, cattleProducts } = data;

            const animalCount = this.getValue(this.state, farmersCattle, 'animalCount');
            const daysValid = this.getValue(this.state, farmersCattle, 'daysValid');

            return [
              <FarmersCards farmer={farmersCattle.farmer} pathRoot={this.props.match.path.split('/')[1]}/>,
              <h5>PAPILDŲ SKAIČIUOKLĖ</h5>,
              <div ref={this.myRef}>
                <Mutation
                  mutation={CULTURE_CATTLE_UPDATE}
                  refetchQueries={[
                    {
                      query: FARMERS_CATTLE,
                      variables: { farmerId: params.farmer, categoryCode: params.category }
                    }
                  ]}
                >
                  {updateFarmersCattle => {
                    const handleBlur = () => (this.state.animalCount || this.state.daysValid) && updateFarmersCattle({
                      variables: {
                        farmersCattle: {
                          _id: farmersCattle._id,
                          animalCount: animalCount && parseInt(animalCount),
                          daysValid: daysValid && parseInt(daysValid)
                        }
                      },
                      onCompleted: () =>
                        this.setState({
                          animalCount: null,
                          daysValid: null,
                        }),
                    });

                    return (
                      <div className="CattleCalculator-inputBlock">
                        <div className="CattleCalculator-inputRow">
                          <div className="CattleCalculator-inputLabel">Gyvulių skaičius</div>
                          <div>
                            <Input
                              type="number"
                              step="1"
                              className="text-field"
                              value={animalCount}
                              onChange={this.handleAnimalCountChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="CattleCalculator-inputRow">
                          <div className="CattleCalculator-inputLabel">Pasiūlymo dienų sk.</div>
                          <div>
                            <Input
                              type="number"
                              step="1"
                              className="text-field"
                              value={daysValid}
                              onChange={this.handleDaysValidChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </Mutation>
                <CattleSelectedProducts
                  products={farmersCattle.products}
                  animalCount={farmersCattle.animalCount}
                  entityId={farmersCattle._id}
                  categoryCode={farmersCattle.categoryCode}
                  farmerId={params.farmer}
                />
              </div>,
              <Query query={FarmersQuery}>
                {
                  farmersResult => {
                    if (farmersResult.loading) return <Loader />;
                    if (!farmersResult.data || !farmersResult.data.farmers) return <ErrorFetch />;

                    if (farmersResult.loading || !farmersResult.data) return null;
                    const farmersOptions = farmersResult.data.farmers.map(farmer =>
                      ({ ...farmer, value: farmer._id, label: farmer.name })
                    );
                    return [
                      <Select
                        isMulti
                        placeholder="Pasirinkite ūkininkus iš sąrašo"
                        options={farmersOptions}
                        onChange={this.onFarmersChange}
                      />,
                      <Mutation
                        mutation={SEND_HTML_PROPOSAL}
                        refetchQueries={[
                          {
                            query: FARMER_PROPOSALS,
                            variables: { farmerId: params.farmer }
                          },
                          {
                            query: ALL_PROPOSALS,
                            variables: { userId }
                          }
                        ]}
                      >
                        {sendProposal => (
                          <button
                            className="button button-success mt-2 full-width"
                            onClick={() =>this.handleProposal({
                              sendProposal,
                              products: farmersCattle.products,
                              animalCount: farmersCattle.animalCount,
                              daysValid: farmersCattle.daysValid,
                              entityId: farmersCattle._id,
                              categoryCode: farmersCattle.categoryCode,
                              farmerId: params.farmer
                            })}
                          >
                            IŠSIUSTI PASIŪLYMĄ
                          </button>
                        )}
                      </Mutation>
                    ];
                  }
                }
              </Query>,
              <Mutation
                mutation={CULTURE_CATTLE_UPDATE_ROW}
                refetchQueries={[
                  {
                    query: FARMERS_CATTLE,
                    variables: { farmerId: params.farmer, categoryCode: params.category }
                  }
                ]}
              >
                {addProduct => {
                  const handleSelect = product => addProduct({
                    variables: {
                      _id: farmersCattle._id,
                      index: farmersCattle.products.length,
                      farmersCattleProduct: {
                        productId: product._id,
                        norm: product.norm,
                        rate: product.rate,
                        amount: product.norm * farmersCattle.animalCount
                      }
                    }
                  });

                  return (
                    <CattleProductsTable
                      products={cattleProducts}
                      selected={farmersCattle.products.map(product => product.productId)}
                      animalCount={farmersCattle.animalCount}
                      onSelect={handleSelect}
                    />
                  )
                }}
              </Mutation>
            ]
          }

          }
        </Query>


      </div>
    );
  }
}

export default CattleCalculator;
