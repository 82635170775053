import React from 'react';

const SelectComponent = ({ options, selected, onSelect, placeholder }) => {
  const selectHandler = event => onSelect(event.target.value);
  return (
    <select className="select" onChange={selectHandler} value={selected}>
      {placeholder ? (<option key="" value="">
        {placeholder}
      </option>): null}
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectComponent;
