import React from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from "react-apollo";
import numeral from 'numeral';
import { REMOVE_AAP_RECORD } from "../../aap/queries/aap.graphql";
import { CULTURE_OVERVIEW_QUERY } from "../queries/cultureOverview.graphql";

const CultureOverviewAap = ({entityId, phases, aap, farmerId, cultureId, area, editable, maxArea}) => {
  const removeRecord = (entityId, index, mutation) => {
    const response = window.confirm('Ar tikrai norite ištrinti?');
    if (response) {
      mutation({
        variables: {
          entityId,
          index
        }
      });
    }
  };

  let counter = 1;

  const getCounter = () => counter++;
  const beicas = phases.find(phase => phase.name === 'Beicavimas');
  const beicasCode = beicas ? beicas.code : '';

  return (
    <Mutation
      mutation={REMOVE_AAP_RECORD}
      refetchQueries={[{
        query: CULTURE_OVERVIEW_QUERY,
        variables: {
          farmerId,
          cultureId,
          area,
        }
      }]}
    >
      {mutation => [
        <div key="CultureOverviewAppMain">
          {phases.map(phase => {
            const records = aap.filter(record => (record.phaseCode === phase.code || record.phaseCode === phase._id));
            const isBeicas = phase.code === beicasCode;
            return (records.length === 0) ? null : (
              <div className="CultureOverviewAap-block mt-3" key={phase._id}>
                {records.map((record, index) => {
                  const totalPrice = record.products.reduce((acc, item) =>
                    acc + item.quantity * (maxArea || record.area) * item.price,
                    0
                  );
                  return (
                    <div key={`aapMixIndex${index}`}>
                      <h5>{getCounter()} {phase.name} ({phase.shortCode})</h5>
                      <p className="">
                        Rekom. purškimo data: {record.date} {!maxArea ? `${isBeicas ? 'Kiekis' : 'Plotas'}: ${record.area}${isBeicas ? ' t' : 'ha'}` : ''}
                      </p>
                      <table cellSpacing="0" className="table">
                        <tbody>
                        <tr>
                          <td><b>Produktas</b></td>
                          <td><b>Norma</b></td>
                          <td><b>Kiekis (l/kg)</b></td>
                          <td><b>Kaina (Eur)</b></td>
                          <td><b>Viso (Eur)</b></td>
                        </tr>
                        {record.products.map((aapProduct, productIndex) => (
                          <tr key={`productIndex${productIndex}`}>
                            <td>{aapProduct.product ? aapProduct.product.name : ''}</td>
                            <td>{aapProduct.quantity} {isBeicas ? 'l/t' : aapProduct.measureUnit}</td>
                            <td>{numeral(aapProduct.quantity * (maxArea || record.area)).format('0.[000]')}</td>
                            <td>{aapProduct.price}</td>
                            <td>
                              {numeral(aapProduct.quantity * (maxArea || record.area) * aapProduct.price).format('0.[00]')}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="4" className="table-summaryRow">Viso:</td>
                          <td className="table-summaryRow">{numeral(totalPrice).format('0.[00]')}</td>
                        </tr>
                        </tbody>
                      </table>
                      <div>
                        {/*<Link*/}
                        {/*to={`/grow-technology/aap/edit/${entityId}`}*/}
                        {/*className="button button-success"*/}
                        {/*>*/}
                        {/*Redaguoti*/}
                        {/*</Link>*/}
                        {editable ? <button
                          onClick={
                            () => {
                              removeRecord(entityId, aap.findIndex(item => (item._id === record._id)), mutation)
                            }
                          }
                          className="button button-warning"
                        >
                          Trinti
                        </button> : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            );
          })}
        </div>,
        editable ? <div key="CultureOverviewAppButtons">
          <Link
            to={`/grow-technology/aap/${entityId}/false`}
            className="button button-success mt-3"
          >
            Pridėti maišymo variantą
          </Link>
          <Link
            to={`/grow-technology/aap/${entityId}/true`}
            className="button button-warning"
          >
            Sukurti maišymo variantą
          </Link>
        </div> : null,
      ]}
    </Mutation>
  );
};

export default CultureOverviewAap;
