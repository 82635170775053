import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { SEED_TYPES } from '../constants/seed.constants';
import { SeedList } from '../queries/seed.query';
import ListRow from './ListRow';
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

class SeedsList extends Component {
  state = { selectedType: 'ALL'};

  handleTypeSelect = ({ target }) =>
    this.setState({ selectedType: target.value });;

  render() {
    const { selectedType } = this.state;
    const cultureCode = selectedType === 'ALL' ? undefined : selectedType;
    return [
      <select
        className="select"
        onChange={this.handleTypeSelect}
        value={this.state.selectedType}
      >
        <option value="ALL">Visos Sėklos</option>
        {SEED_TYPES.map(item => (
          <option
            key={`seedTypeOption${item.type}`}
            value={item.type}
            onChange={this.handleTypeSelect}
          >
            {item.label}
          </option>
        ))}
      </select>,
      <div className="ListItemsContainer">
        <table className="table" align="center">
          <tbody>
            <tr>
              <td className="text-left">
                <strong>Prekė</strong>
              </td>
              <td>
                <strong>Norma</strong>
              </td>
              <td>
                <strong>Kaina (vnt.)</strong>
              </td>
              {/*<td />*/}
            </tr>
            <Query
              query={SeedList}
              variables={{ cultureCode }}
            >
              {({ loading, data }) =>
                loading ? <Loader /> :
                  !data || !data.seedVarieties ? <ErrorFetch /> :
                  data.seedVarieties.map(item =>
                    (<ListRow product={item} type="seeds" />)
                  )
              }
            </Query>
          </tbody>
        </table>
      </div>
    ]
  }

}

export default SeedsList;
