import numeral from "numeral";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ListRow = ({ product, type }) => {
  // const handleSelect = () => onSelect(product);
  return (
    <tr>
      <td className="text-left">
        {product.descriptionURL ? <a target="_blank" href={product.descriptionURL}>{product.name}</a> : product.name}
      </td>
      <td>
        {numeral(product.norm || product.value || product.ratePerM2).format('0.[00]')} {product.ratePerM2 ? 'vnt/m²' : product.measureUnit}
      </td>
      {type === 'aap' || type === 'additional' ?
        <td>
          {numeral(product.maxValue).format('0.[00]')} {product.measureUnit}
        </td> :
        null
      }
      <td>
        {numeral(product.rate || product.price).format('0.[00]')}
      </td>
      {/*<td onClick={handleSelect}>*/}
        {/*<FontAwesomeIcon icon={faShoppingCart} />*/}
      {/*</td>*/}
    </tr>
  )
};

export default ListRow;
