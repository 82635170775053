import gql from 'graphql-tag';
export const CULTURE_OVERVIEW_QUERY = gql`
  query FarmesCulture($farmerId: String!, $cultureId: String!, $area: Float) {
    farmersCulture(farmerId: $farmerId, cultureId: $cultureId, area: $area) {
      _id
      farmer {
        _id
        name
        email
        phone
        location
        region {
          _id
          name
        }
      }
      culture {
        _id
        code
        name
        whom
        aapPhases{
          _id
          code
          shortCode
          name
        }
        yields {
          amount
          NPKS {
            N
            K
            P
            S
          }
        }
      }
      area
      seeds {
        variety {
            _id
            name
        }
        varietyId
        germination
        weightPer1000
        ratePerM2
        packsRequired
        recomendedDate
        rate
        area
        price
        knownParams
      }
      fertilizers {
        fertilizer {
          _id
          name
        }
        rate
        price
        selectedYield
        isSurface
      }
      aap {
        _id
        date
        phase {
          _id
          name
          code
        }
        area
        phaseCode
        products {
          product {
            _id
            name
            measureUnit
          }
          productCode
          measureUnit
          quantity
          price
          isAdditional
        }
      }
      finances {
          area
          workCost
          subsidies
          yieldForecast
          kilometers
          matif
          deliveryPlace
          elevatorId
          profitPerHa
      }
    }
  }
`;

export const SEND_PROPOSAL = gql`
    mutation SendProposal(
      $userId: String!,
      $farmerId: String!,
      $additionalFarmerIds: [String],
      $comments: String,
      $proposedCultures: [inProposedCulture],
      $proposedCattle: inProposedCattle,
      $proposalType: String
    ) {
      emailProposal(
        userId: $userId,
        farmerId: $farmerId,
        additionalFarmerIds: $additionalFarmerIds,
        comments: $comments,
        proposedCultures: $proposedCultures,
        proposedCattle: $proposedCattle,
        proposalType: $proposalType
      ) {
          status
      }
    }
`;
export const SEND_HTML_PROPOSAL = gql`
    mutation SendHtmlProposal(
      $userId: String!,
      $farmerId: String!,
      $cultureId: String,
      $additionalFarmerIds: [String],
      $comments: String,
      $html: String!,
      $proposalType: String
    ) {
        emailProposalHtml(
        userId: $userId,
        farmerId: $farmerId,
        cultureId: $cultureId,
        additionalFarmerIds: $additionalFarmerIds,
        comments: $comments,
        html: $html,
        proposalType: $proposalType
      ) {
          status
      }
    }
`;

