import React from 'react';
import { Query } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { RECOMMENDATIONS } from '../queries/recommendations.query';
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const Recommendations = () => (
  <div className="px-4">
    <Query query={RECOMMENDATIONS}>
      {({ loading, data }) =>
        loading ? <Loader /> :
          !data || !data.recommendations ? <ErrorFetch /> :
          data.recommendations.map(item => [
            <div className="heading-style-5">
              <h4 className="heading-title">{item.date}</h4>
              <FontAwesomeIcon icon={faCalendarAlt} className="heading-icon" size="xs"/>
              <div className="line bg-black" />
              <div className="Recommendation-text">{item.text}</div>
            </div>,
            <div className="decoration" />,
            (item.videoTitle || item.videoLink) ? [
              <div>
                {item.videoTitle ? <h3>{item.videoTitle}</h3> : null}
                {item.videoText ? <p>{item.videoText}</p> : null}
                {item.videoLink ? (
                  <div className="responsive-video full-bottom">
                    <iframe title='recommendation' src={item.videoLink} />
                  </div>
                ) : null}
              </div>,
              <div className="decoration hide-if-responsive" />
            ] : null,
          ])
      }
    </Query>
  </div>
);

export default Recommendations;
