import React from 'react';
import { NavLink } from 'react-router-dom';
import './MenuScreen.css';

const MenuItem = ({ path, label }) => (
  <NavLink className="MenuItem" to={path}>
    <div className="MenuItem-label">{label}</div>
  </NavLink>
);

const MenuScreen = ({ title, list }) => (
  <div className="MenuScreen-root">
    <img className="MenuScreen-image" src="/assets/images/basis_titulinis.png" alt="" />
    <h5 className="MenuScreen-container">{title}</h5>
    <div className="MenuScreen-container">
      {list.map(({ path, label }) => (
        <MenuItem key={path} path={path} label={label} />
      ))}
    </div>
  </div>
);

export default MenuScreen;
