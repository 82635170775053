import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import { FARMERS_CULTURE_PHASES } from '../queries/aap.graphql';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import './AapPhases.css';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

const AapPhasesItem = ({ phase, entityId, isPro }) => (
  <Link
    className="AapPhasesItem"
    to={`/grow-technology/aap/${isPro ? 'pro' : 'type'}/${entityId}/${phase._id}/${phase.code}`}
  >
    <img className="AapPhasesItem-avatar" alt={phase._id} src={phase.image && phase.image.uri} />
    <div className="AapPhasesItem-content">
      <div className="AapPhasesItem-contentName">{phase.name}</div>
      <div className="AapPhasesItem-contentLocation">{phase.shortCode}</div>
    </div>
    <FontAwesome name="chevron-right" className="AapPhasesItem-chevron" />
  </Link>
);

class AapPhases extends PureComponent {
  render() {
    const entityId = this.props.match.params.id;
    const isPro = this.props.match.params.isPro === 'true';
    return (
      <div className="CultureOverview">
        <h5>AUGIMO TARPSNIAI</h5>
        <Query variables={{ _id: entityId }} query={FARMERS_CULTURE_PHASES}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            return [
              <h4 key="culture-title">
                {data.farmersCultureById.culture.name}
              </h4>,
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={this.props.match.path.split('/')[1]}
              />,
              data.farmersCultureById.culture.aapPhases.map(phase => (
                <AapPhasesItem
                  key={phase._id}
                  phase={phase}
                  entityId={entityId}
                  isPro={isPro}
                />
              )),
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default AapPhases;
