export const FERTILIZER_TYPES = [
  {
    type: 'N',
    label: 'Azotas'
  },
  {
    type: 'NPK',
    label: 'NPK trašos'
  },
  {
    type: 'KITA',
    label: 'Kitos trašos'
  },
];
