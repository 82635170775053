import gql from 'graphql-tag';
export const FARMERS_QUERY = gql`
  query Farmers($region: String, $name: String, $isCattle: Boolean) {
    farmers(regionId: $region, name: $name, isCattle: $isCattle) {
      _id
      name
      region {
        name
        _id
      }
      location
    }
  }
`;

export const FARMER_QUERY = gql`
  query GetFarmer($_id: String!) {
    farmer(_id: $_id) {
      _id
      name
      email
      location
      phone
      regionId
      areaTotal
      region {
        name
        _id
      }
      areas {
        zKvieciai
        vKvieciai
        rapsai
        zirniai
        pupos
        mieziai
      }
    }
  }
`;

export const UPDATE_FARMER_MUTATION = gql`
  mutation UpdateFarmer(
    $_id: String
    $name: String
    $email: String
    $phone: String
    $location: String
    $areaTotal: Int
    $regionId: String
    $areas: inAreas
    $hidden: Boolean
    $isCattle: Boolean
  ) {
    updateFarmer(
      _id: $_id
      name: $name
      email: $email
      phone: $phone
      location: $location
      regionId: $regionId
      areaTotal: $areaTotal
      areas: $areas
      hidden: $hidden
      isCattle: $isCattle
    ) {
      _id
      name
      email
      region {
        _id
        name
      }
      areaTotal
    }
  }
`;
