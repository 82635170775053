import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorFetch = () => (
  <div className="Loader-container">
    <FontAwesomeIcon icon={faExclamationTriangle} size='2x' color='red' />
    <p>Įvyko klaida! Prašome pranešti administratoriui.</p>
  </div>
);

export default ErrorFetch;
