import { faSlidersH, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

export const GRAINS_MENU = [
  {
    path: '/grains/prices',
    label: 'GRŪDŲ SUPIRKIMO KAINININKAS',
    iconName: faSlidersH
  },
  {
    path: '/grains/calculator',
    label: 'GRUDŲ KAINŲ SKAIČIUOKLĖ',
    iconName: faMoneyBill
  },
];

export const DELIVERY_OPTIONS = [
  {value: 'PORT', name: 'Uostas'},
  {value: 'ELEVATOR', name: 'Elevatorius'},
  {value: 'YARD_ELEVATOR', name: 'Kiemas / Elevatorius'},
  // {name: 'YARD_PORT', label: 'Kiemas / Uostas'},
];

export const DELIVERY_LABELS = {
  PORT: 'Uostas',
  ELEVATOR: 'Elevatorius',
  YARD_ELEVATOR: 'Kiemas / Elevatorius',
  // {name: 'YARD_PORT', label: 'Kiemas / Uostas'},
};
