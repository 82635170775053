import gql from 'graphql-tag';

export const ADDITIONAL_PRODUCTS = gql`
  query AdditionalProductList($categoryCode: String) {
      additionalFertilizers(categoryCode: $categoryCode) {
          _id
          name
          shortDescription
          descriptionURL
          price
          value
          maxValue
      }
  }
`;

export const AdditionalItem = gql`
    query AdditionalItem($_id: String!) {
        additionalFertilizer(_id: $_id) {
            name
            shortDescription
            description
            price
        }
    }
`;
