export const PROPOSAL_TYPES = {
  FULL_CULTURE: 'FULL_CULTURE',
  SEEDS: 'SEEDS',
  FERTILIZERS: 'FERTILIZERS',
  AAP: 'AAP',
  FINANCES: 'FINANCES',
  CATTLE: 'CATTLE',
};

export const PROPOSAL_TYPE_LABELS = {
  FULL_CULTURE: 'Pilnas pasiūlymas',
  SEEDS: 'Sėklos',
  FERTILIZERS: 'Trąšos',
  AAP: 'AAP',
  FINANCES: 'Finansinis',
  CATTLE: 'Gyvulininkystė',
};
