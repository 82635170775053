import gql from 'graphql-tag';

export const UPDATE_FINANCIAL = gql`
  mutation UpdateFarmersCultureFinances($entityId: String!, $finances: inFarmersCultureFinances) {
      updateFarmersCultureFinances(entityId: $entityId, finances: $finances) {
          _id
      }
  } 
`;

export const FINANCIAL_DATA = gql`
  query FinancialData($entityId: String!, $userId: String!, $growTechCulture: String) {
      user(_id: $userId) {
          _id
          elevators {
              _id
              name
              kmToPort
              cultureVisibility {
                  cultureId
                  visible
                  deduction
              }

          }
      }
      calculatorValues {
          transportationPrice {
              perKm
          }
          seedsPrices {
              name
              code
              price
          }
      }
      calculatorCultures(growTechCulture: $growTechCulture) {
          _id
          name
          type
          deduction
          notInPort
      }
      farmersCultureById(_id: $entityId) {
          farmer {
              _id
          }
          culture {
              _id
              name
              code
              aapPhases{
                  _id
                  code
                  shortCode
                  name
              }
          }
          _id
          area
          seeds {
              variety {
                  _id
                  name
              }
              varietyId
              rate
              packsRequired
              area
              price
          }
          fertilizers {
              fertilizer {
                  _id
                  name
              }
              rate
              price
              selectedYield
              isSurface
          }
          aap {
              date
              phase {
                  _id
                  name
                  code
              }
              area
              phaseCode
              products {
                  product {
                      _id
                      name
                      measureUnit
                  }
                  productCode
                  measureUnit
                  quantity
                  price
                  isAdditional
              }
          }
          finances {
              area
              workCost
              subsidies
              yieldForecast
              kilometers
              matif
              deliveryPlace
              elevatorId
              cultureSelected
          }
      }
  }
`;
