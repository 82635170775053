export const COEFFICIENTS = {
  1: 0.93,
  2: 1,
};

export const NPKS_LIST = [ 'N', 'P', 'K', 'S' ];

export default {
  COEFFICIENTS,
};
