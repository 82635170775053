import gql from 'graphql-tag';

export const CattleListQuery = gql`
  query CattleList($categoryCode: String) {
      cattleProducts(categoryCode: $categoryCode) {
          _id
          name
          shortDescription
          descriptionURL
          rate
      }
  }
`;

export const CattleItem = gql`
  query CattleItem($_id: String!) {
      cattleProduct(_id: $_id) {
          name
          shortDescription
          description
          rate
      }
  }
`;
