import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { FARMER_PROPOSALS, ALL_PROPOSALS } from '../queries/proposals.graphql';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const serverRoot =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3030/'
    : '/';

class ProposalsList extends PureComponent{
  static propTypes = {
    farmerId: PropTypes.string,
  };

  render() {
    const { farmerId } = this.props;
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    return (
      <Query
        variables={{ farmerId, userId }}
        query={ farmerId ? FARMER_PROPOSALS : ALL_PROPOSALS }
      >
        {({ loading, data }) => {
          if (loading) return null;

          return (
            <div className="mt-2">
              <table className="table" cellSpacing="0">
                <tbody>
                <tr>
                  {farmerId ? null : (<td><b>Ūkininkas</b></td>)}
                  <td><b>Pasiūlymo tipas</b></td>
                  <td><b>Kultūra</b></td>
                  <td><b>Pasiūlymo data</b></td>
                  <td />
                </tr>
                {data.proposals.reduceRight((acc, proposal) => [ ...acc, (
                  <tr>
                    {farmerId ? null : (<td>{proposal.farmer.name}</td>)}
                    <td>{proposal.typeLabel}</td>
                    <td>{proposal.culture ? proposal.culture.name : ''}</td>
                    <td>{moment.unix(parseInt(proposal.createdAt) / 1000).format('YYYY-MM-DD HH:mm')}</td>
                    <td>
                      {
                        proposal.filePath.includes('html') ?
                          <a href={`${serverRoot}proposal-html/${proposal._id}`} target="_blank">
                            <FontAwesomeIcon icon={faFileArchive} />
                          </a> :
                          <a href={`${serverRoot}proposal-pdf/${proposal._id}`} download={proposal.filePath.split('/')[4]}>
                            <FontAwesomeIcon icon={faFilePdf} />
                          </a>
                      }
                    </td>
                  </tr>
                )], [])}
                </tbody>
              </table>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default ProposalsList;
