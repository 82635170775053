export const CATTLE_TYPES = [
  {
    code: 'cows',
    image: 'karves',
    name: 'Karvės',
  },
  {
    code: 'pigs',
    image: 'kiaules',
    name: 'Kiaulės',
  },
  {
    code: 'sheep',
    image: 'avis',
    name: 'Avys',
  },
  {
    code: 'livestock',
    image: 'galvijai',
    name: 'Galvijai',
  },
];
