export const CATTLE_CATEGORIES = [
  {
    type: 'cows',
    label: 'Karvės'
  },
  {
    type: 'pigs',
    label: 'Kiaulės'
  },
  {
    type: 'sheep',
    label: 'Avys'
  },
  {
    type: 'livestock',
    label: 'Galvijai'
  },
];
