import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import FileTypes from './components/FileTypes';
import FileSubTypes from './components/FileSubTypes';
import FileList from './components/FileList';

const FilesModule = () => (
  <Switch>
    <Route exact path="/files/types" component={FileTypes} />
    <Route exact path="/files/subtypes/:type" component={FileSubTypes} />
    <Route exact path="/files/list/:type/:subTypeId" component={FileList} />
    <Redirect to="/files/types" />
  </Switch>
);

export default FilesModule;
