import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Query } from 'react-apollo';

import { FARMERS_QUERY } from '../../queries/farmers.graphql.js';

import './FarmersList.css';
import Loader from "../../../../common/components/Loader/Loader";
import ErrorFetch from "../../../../common/components/Error/ErrorFetch";

const getAvatarText = name =>
  name.split(' ').reduce((acc, word) => word[0] ? acc + word[0] : acc, '');

const FarmersListItem = ({ farmer, pathRoot }) => (
  <Link
    className="FarmersListItem"
    to={`/${pathRoot}/list/${farmer._id}`}
  >
    <div className="FarmersListItem-avatar">{getAvatarText(farmer.name)}</div>
    <div className="FarmersListItem-content">
      <div className="FarmersListItem-contentName">{farmer.name}</div>
      <div className="FarmersListItem-contentLocation">
        {farmer.location ? `${farmer.location}, ` : ''}
        {farmer.region.name}
      </div>
    </div>
    <FontAwesome name="chevron-right" className="FarmersListItem-chevron" />
  </Link>
);

const FarmersList = ({ region, name, isCattle, pathRoot }) => (
  <Query variables={{ region, name, isCattle }} query={FARMERS_QUERY}>
    {({ loading, data }) =>
      loading ? <Loader /> :
        (!data || !data.farmers) ? <ErrorFetch /> :
          data.farmers.map(farmer => (
            <FarmersListItem key={farmer._id} farmer={farmer} pathRoot={pathRoot} />
          ))
    }
  </Query>
);

export default FarmersList;
