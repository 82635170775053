export const SEED_TYPES = [
  {
    type: 'zKvieciai',
    label: 'Žieminiai kviečiai'
  },
  {
    type: 'vKvieciai',
    label: 'Vasariniai kviečiai'
  },
  {
    type: 'rapsai',
    label: 'Rapsai'
  },
  {
    type: 'zirniai',
    label: 'Žirniai'
  },
  {
    type: 'mieziai',
    label: 'Miežiai'
  },
];
