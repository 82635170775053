import React from 'react';
import { Query } from 'react-apollo';
import { FileTypeQuery } from '../queries/files.query';
import '../../common/components/MenuScreen/MenuScreen.css';
import { NavLink } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const FileSubTypes = ({ match }) => (
  <div className="MenuScreen-root FileTypes">
    <h5 className="MenuScreen-container">FAILŲ SAUGYKLA</h5>
    <Query query={FileTypeQuery} variables={{ type: match.params.type }} >
      {
        ({loading, data}) =>
          loading ? <Loader /> :
            !data || !data.fileType ? <ErrorFetch /> :
              [
                <h5>{data.fileType.name}</h5>,
                data.fileType.subtypes.map(type => [
                  <div className="MenuScreen-container">
                    <NavLink className="MenuItem" to={`/files/list/${data.fileType._id}/${type._id}`}>
                      <div className="MenuItem-label">{type.name}</div>
                    </NavLink>
                  </div>
                ])
              ]
      }
    </Query>
  </div>
);

export default FileSubTypes;
