import React, { Component } from 'react';
import { Mutation, Query } from "react-apollo";
import { FARMERS_CULTURE_FOR_FERTILIZER } from '../queries/fertilizers.graphql';
import { COEFFICIENTS, NPKS_LIST } from '../fertilizer.constants';
import FertilizerBalance from './FertilizerBalance';
import SelectedFertilizers from './SelectedFertilizers';
import FertilizerCompare from './FertilizerCompare';
import { withRouter } from "react-router-dom";
import { SEND_HTML_PROPOSAL } from "../../cultureOverview/queries/cultureOverview.graphql";
import { PROPOSAL_TYPES } from "../../../common/constants/proposal.constants";
import { FARMER_PROPOSALS, ALL_PROPOSALS } from "../../../proposals/queries/proposals.graphql";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class Fertilizers extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = { };

  yieldSelectHandler = event => {
    this.setState({ selectedYield: parseInt(event.target.value) });
  };

  typeSelectHandler = event => {
    this.setState({ fertilizeType: event.target.value });
  };

  getNeed = (value, fertilizeType) => Math.round(value * COEFFICIENTS[fertilizeType]);
  getValue = (item, element) =>
    Math.round(
      (parseFloat(item.rate) / item.fertilizer.acuracy) *
        item.fertilizer.NPKS[element]
    );

  countNewBalance = (selectedType, fertilizeType, cultureNPKS, fertilizers) => {
    const initialNeed = NPKS_LIST.reduce(
      (acc, letter) => ({
        ...acc,
        [letter]: -this.getNeed(cultureNPKS[letter], fertilizeType),
      }),
      {}
    );
    return fertilizers.reduce(
      (acc, fertilizer) => {
        const initial = fertilizer.rate / fertilizer.fertilizer.acuracy;
        acc.N =
          fertilizer.fertilizer.NPKS.N && fertilizer.fertilizer.NPKS.N !== 0
            ? acc.N + initial * fertilizer.fertilizer.NPKS.N
            : acc.N;
        acc.P =
          fertilizer.fertilizer.NPKS.P && fertilizer.fertilizer.NPKS.P !== 0
            ? acc.P + initial * fertilizer.fertilizer.NPKS.P
            : acc.P;
        acc.K =
          fertilizer.fertilizer.NPKS.K && fertilizer.fertilizer.NPKS.K !== 0
            ? acc.K + initial * fertilizer.fertilizer.NPKS.K
            : acc.K;
        acc.S =
          fertilizer.fertilizer.NPKS.S && fertilizer.fertilizer.NPKS.S !== 0
            ? acc.S + initial * fertilizer.fertilizer.NPKS.S
            : acc.S;
        return acc;
      },
      { ...initialNeed }
    );
  };

  backHandler = () => {
    if (this.props.location.pathname !== '/') {
      this.props.history.goBack();
    }
  };

  sendProposal = ({ sendProposal, farmerId, cultureId, selectedYield, fertilizeType }) => {
    const comments = prompt('Komentarai:');
    const domClone = this.myRef.current.cloneNode(true);
    // 2. disable all inputs
    const inputs = domClone.querySelectorAll('input, select');
    [...inputs].forEach(input => {
      input.disabled = true;
    });

    domClone.querySelectorAll('#selectedYield > option')[selectedYield].setAttribute('selected', 'selected');
    domClone.querySelectorAll('#fertilizeType > option')[fertilizeType - 1].setAttribute('selected', 'selected');
    // console.log(options, selectedYield);
    // console.log(domClone.querySelectorAll('#selectedYield')[0]);

    // 2. disable all inputs
    const buttons = domClone.querySelectorAll('button, a');
    [...buttons].forEach(button => {
      button.remove();
    });

    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;

    sendProposal({
      variables: {
        userId,
        farmerId,
        cultureId,
        comments,
        html: domClone.outerHTML,
        proposalType: PROPOSAL_TYPES.FERTILIZERS,

      }
    }).then(() => alert('Pasūlymas išsiųstas'));
    alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    return;
    //
    // const comments = prompt('Komentarai:');
    //
    // const proposedCultures = {
    //   name: farmerCulture.culture.name,
    //   cultureCode: farmerCulture.culture.code,
    //   area:  farmerCulture.area,
    //   fertilizers: farmerCulture.fertilizers.map(({ fertilizer, rate, price}) => ({
    //     name: fertilizer.name,
    //     rate: parseFloat(rate),
    //     price: parseFloat(price)
    //   })),
    // };
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     cultureId,
    //     comments,
    //     proposedCultures,
    //     proposalType: PROPOSAL_TYPES.FERTILIZERS,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    return (
        <Query
          query={FARMERS_CULTURE_FOR_FERTILIZER}
          variables={{ _id: this.props.match.params.id }}
        >
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const fertilizerLastItem = data.farmersCultureById.fertilizers.length - 1;
            const selectedYield = this.state.selectedYield ? this.state.selectedYield : fertilizerLastItem !== -1 ?
              data.farmersCultureById.fertilizers[fertilizerLastItem].selectedYield : 0;
            const fertilizeType = this.state.fertilizeType ? this.state.fertilizeType : fertilizerLastItem !== -1 ?
              (data.farmersCultureById.fertilizers[fertilizerLastItem].isSurface ? 2 : 1) : 2;
            const balance = this.countNewBalance(
              selectedYield,
              fertilizeType,
              data.farmersCultureById.culture.yields[selectedYield].NPKS,
              data.farmersCultureById.fertilizers
            );

            const user = localStorage.getItem('user-basis-ui2');
            const userId = JSON.parse(user)._id;
            return (
              <div className="CultureOverview">

                <h5>TRĘŠIMO PLANAVIMAS</h5>
                <div ref={this.myRef}>
                  <div key="yieldSelect">
                    <h4>Planuojamas derlius</h4>
                    <select
                      id="selectedYield"
                      className="select"
                      onChange={this.yieldSelectHandler}
                      value={selectedYield}
                      disabled={data.farmersCultureById.fertilizers.length > 0}
                    >
                      {data.farmersCultureById.culture.yields.map(
                        (option, index) => (
                          <option key={`yieldsSelectOption${index}`} value={index}>
                            {option.amount}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div key="fertilizeType">
                    <h4>Tręšimo būdas</h4>
                    <select
                      id="fertilizeType"
                      className="select"
                      onChange={this.typeSelectHandler}
                      value={fertilizeType}
                    >
                      <option value={1} >Lokalinis tręšimas</option>
                      <option value={2} >Paviršinis tręšimas</option>
                    </select>
                  </div>
                  <FertilizerBalance
                    key="FertiliserBalance"
                    getNeed={this.getNeed}
                    fertilizeType={fertilizeType}
                    yields={data.farmersCultureById.culture.yields}
                    selectedYield={selectedYield}
                    balance={balance}
                  />
                  <SelectedFertilizers
                    key="SelectedFertilizers"
                    entityId={this.props.match.params.id}
                    fertilizers={data.farmersCultureById.fertilizers}
                    area={data.farmersCultureById.area}
                    getValue={this.getValue}
                  />
                </div>
                <div key="buttons">
                  <button className="button button-success" onClick={this.backHandler}>
                    IŠSAUGOTI IR GRĮŽTI
                  </button>
                  <Mutation
                    mutation={SEND_HTML_PROPOSAL}
                    refetchQueries={[
                      {
                        query: FARMER_PROPOSALS,
                        variables: { farmerId: data.farmersCultureById.farmer._id }
                      },
                      {
                        query: ALL_PROPOSALS,
                        variables: { userId }
                      }
                    ]}
                  >
                    {sendProposal => (
                      <button
                        className="button button-warning"
                        onClick={() =>this.sendProposal({
                          sendProposal,
                          farmerId: data.farmersCultureById.farmer._id,
                          cultureId: data.farmersCultureById.culture._id,
                          farmerCulture: data.farmersCultureById,
                          selectedYield,
                          fertilizeType
                        })}
                      >
                        IŠSIŲSTI PASIŪLYMĄ
                      </button>
                    )}
                  </Mutation>
                </div>
                <FertilizerCompare
                  key="FertilizerCompare"
                  entityId={this.props.match.params.id}
                  cultureType={data.farmersCultureById.culture.type}
                  selectedFertilizers={data.farmersCultureById.fertilizers}
                  surfaceNorm={fertilizeType == 2 ? 0.85 : 1}
                  needForN={balance.N}
                  needForK={balance.K}
                  selectedYield={selectedYield}
                />
              </div>
            );
          }}
        </Query>
    );
  }
}

export default withRouter(Fertilizers);
