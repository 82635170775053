import * as types from './action-types';
import { setToken, cleanAuthStorage } from '../common/utils/auth.utils';

export const login = (token, user) => {
  setToken(token, user);
  return {
    type: types.LOGIN,
    token,
    user,
  };
};

export const logout = () => {
  cleanAuthStorage();
  return {
    type: types.LOGOUT,
  };
};
