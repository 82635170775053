import gql from "graphql-tag";

export const FARMER_PROPOSALS = gql`
    query FarmersProposals ($farmerId: String) {
        proposals(farmerId: $farmerId) {
            _id
            typeLabel
            type
            filePath
            culture {
                _id
                code
                name
            }
            createdAt
        }
    }
`;

export const ALL_PROPOSALS = gql`
    query AllProposals ($userId: String) {
        proposals(userId : $userId) {
            _id
            farmer {
                name
            }
            typeLabel
            type
            filePath
            culture {
                _id
                code
                name
            }
            createdAt
        }
    }
`;
