import React, { PureComponent } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import ExpandableRow from '../../../../common/components/ExpandableRow/ExpandableRow';
import './FarmersCard.css';
import 'font-awesome/css/font-awesome.css';

const activeLinks = {
  phone: 'tel',
  envelope: 'mailto',
};

const lineText = (icon, text) => activeLinks[icon] ?
  <a href={`${activeLinks[icon]}:${text}`}>{text}</a> :
  text;

const FarmersCardsLine = ({ icon, text }) =>
  text && text.length ? (
    <div className="FarmersCardsLine">
      <FontAwesome className="FarmersCardsLine-icon" name={icon} />
      <div className="FarmersCardsLine-text">{lineText(icon, text)}</div>
    </div>
  ) : null;

class FarmersCards extends PureComponent {
  render() {
    const { farmer, pathRoot } = this.props;
    return (
      <div className="mb-3">
        <ExpandableRow
          title={farmer.name}
          content={
            <div>
              <FarmersCardsLine icon="phone" text={farmer.phone} />
              <FarmersCardsLine icon="envelope" text={farmer.email} />
              <FarmersCardsLine
                icon="map"
                text={`${farmer.location ? `${farmer.location}, ` : ''}${
                  farmer.region.name
                }`}
              />
              <Link
                to={`/${pathRoot}/farmers/form/${farmer._id}`}
                className="button button-warning FarmersCard-button"
              >
                ŪKININKO KORTELĖ
              </Link>
            </div>
          }
        />
      </div>
    );
  }
}

export default FarmersCards;
