import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Query, Mutation } from 'react-apollo';
import {
  FARMER_QUERY,
  UPDATE_FARMER_MUTATION,
  FARMERS_QUERY,
} from "../../queries/farmers.graphql";
import { CULTURE_OVERVIEW_QUERY } from '../../../cultureOverview/queries/cultureOverview.graphql';

import './FarmersForm.css';
import REGIONS_QUERY from '../../queries/regions.graphql';
import RegionSelect from '../RegionSelect';
import ProposalsList from '../../../../proposals/components/ProposalsList';
import Loader from "../../../../common/components/Loader/Loader";
import ErrorFetch from "../../../../common/components/Error/ErrorFetch";

const getInitialValues = () => ({
  name: '',
  email: '',
  location: '',
  phone: '',
  regionId: '',
  areas: {},
});

const cultureCodes = [
  'zKvieciai',
  'vKvieciai',
  'rapsai',
  'zirniai',
  'pupos',
  'mieziai'
];

const RegionSelectField = ({ field, regions, onChange }) => (
  <RegionSelect
    options={regions.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }))}
    selected={field.value}
    onSelect={onChange}
  />
);

const FarmersFormContent = ({ initialValues, isNew, history, isCattle }) => {
  const submitForm = (values, setSubmitting, updateReport) => {
    const refetchQueries = [{
      query: FARMERS_QUERY,
      variables: { isCattle, region: '', name: '' }
    }];
    const { __typename, region, ...variables } = values;
    if (!isCattle) {
      const {
        zKvieciai,
        vKvieciai,
        rapsai,
        zirniai,
        pupos,
        mieziai,
      } = values.areas;
      variables.areas = {
        zKvieciai,
        vKvieciai,
        rapsai,
        zirniai,
        pupos,
        mieziai,
      };
    }
    variables.isCattle = isCattle;

    if (variables._id) {
      refetchQueries.push({
        query: FARMER_QUERY,
        variables: { _id: variables._id }
      });
      if (!initialValues.areas) {
        initialValues.areas = {}
      }
      if (!isCattle) {
        cultureCodes.forEach(cultureId => {
          if (initialValues.areas[cultureId] !== variables.areas[cultureId]) {
            refetchQueries.push({
              query: CULTURE_OVERVIEW_QUERY,
              variables: { farmerId: variables._id, cultureId, area: initialValues.areas[cultureId] }
            });

          }
        })
      }
    }

    updateReport({ variables, refetchQueries })
      .then(({ data }) => {
        setSubmitting(false);
        if (isNew) {
          alert('Ūkininkas sukurtas');
          // history.replace(
          //   `/grow-technology/farmers/details/${data.updateFarmer._id}`
          // );
        } else {
          alert('Pakeitimai išsaugoti');
          // history.goBack();
        }
        history.goBack();
      })
      .catch(error => {
        setSubmitting(false);
        if (error.message.includes('exists')) {
          alert('Klaida! Ūkininkas tokiu el. paštu jau egzistuoja!');
        } else {
          console.log('there was an error sending the query', error.message);
        }
      });
  };

  const remove = updateReport => {
    const response = window.confirm('Ar tikrai norite ištrinti?');
    if (response) {
      updateReport({
        variables: { '_id': initialValues._id, hidden: true },
        refetchQueries: [{
          query: FARMERS_QUERY,
          variables: { isCattle, region: '', name: '' }
        }]
      }).then(() => {
        alert('Ūkininkas pašalintas');
        history.replace('/grow-technology/farmers');
      });
    }
  };

  const refetchQueries = [{ query: FARMERS_QUERY }];
  if (initialValues._id) {
    refetchQueries.push({ query: FARMER_QUERY, variables: { _id: initialValues._id } });
  }

  console.log(isCattle);
  return [
    <Mutation
      mutation={UPDATE_FARMER_MUTATION}
      refetchQueries={refetchQueries}
    >
      {updateReport => (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) =>
            submitForm(values, setSubmitting, updateReport)
          }
          enableReinitialize={true}
          render={({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div>
                <div className="FarmersForm-row">
                  <Field
                    name="name"
                    className="text-field"
                    placeholder="Ūkio pavadinimas: (privaloma)"
                  />
                </div>
                <Query query={REGIONS_QUERY}>
                  {({ loading, data }) =>
                    loading ? null : (
                      <Field
                        name="regionId"
                        component={RegionSelectField}
                        regions={data.regions}
                        onChange={value => setFieldValue('regionId', value)}
                      />
                    )
                  }
                </Query>
                <div className="FarmersForm-row">
                  <Field
                    name="location"
                    className="text-field"
                    placeholder="Adresas"
                  />
                </div>
                <div className="FarmersForm-row">
                  <Field
                    name="email"
                    className="text-field"
                    placeholder="El. paštas"
                  />
                </div>
                <div className="FarmersForm-row">
                  <Field
                    name="phone"
                    className="text-field"
                    placeholder="Telefonas"
                  />
                </div>
                {/*<div className="FarmersForm-row">*/}
                  {/*<Field*/}
                    {/*name="areaTotal"*/}
                    {/*type="number"*/}
                    {/*className="text-field"*/}
                    {/*placeholder="Įveskite bendrą hektarų skaičių"*/}
                  {/*/>*/}
                {/*</div>*/}
                {isCattle ? null : [
                  <div className="FarmersForm-row" key="row-0">
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.zKvieciai">Ž. kviečiai, ha</label>
                      <Field
                        name="areas.zKvieciai"
                        type="number"
                        className="text-field"
                      />
                    </div>
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.vKvieciai">V. kviečiai, ha</label>
                      <Field
                        name="areas.vKvieciai"
                        type="number"
                        className="text-field"
                      />
                    </div>
                  </div>,
                  <div className="FarmersForm-row" key="row-1">
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.mieziai">Miežiai, ha</label>
                      <Field
                        name="areas.mieziai"
                        type="number"
                        className="text-field"
                      />
                    </div>
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.rapsai">Ž. Rapsai, ha</label>
                      <Field
                        name="areas.rapsai"
                        type="number"
                        className="text-field"
                      />
                    </div>
                  </div>,
                  <div className="FarmersForm-row" key="row-2">
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.pupos">Pupos, ha</label>
                      <Field
                        name="areas.pupos"
                        type="number"
                        className="text-field"
                      />
                    </div>
                    <div className="FarmersForm-rowCell">
                      <label htmlFor="areas.zirniai">Žirniai, ha</label>
                      <Field
                        name="areas.zirniai"
                        type="number"
                        className="text-field"
                      />
                    </div>
                  </div>
                ]}
                <div className="FarmersForm-row">
                  <button
                    type="submit"
                    disabled={isSubmitting || !values.name}
                    className="button button-success FarmersForm-submit"
                  >
                    Išsaugoti
                  </button>
                </div>
                {!isNew ? <div className="FarmersForm-row">
                  <button
                    onClick={() => remove(updateReport)}
                    disabled={isSubmitting}
                    className="button button-warning FarmersForm-submit"
                  >
                    Pašalinti ūkininką
                  </button>
                </div> : null}
              </div>
            </Form>
          )}
        />
      )}
    </Mutation>,
    initialValues._id ? <ProposalsList farmerId={initialValues._id} /> : null
  ];
};

const FarmersForm = ({ match, history }) => (
  <div className="FarmersForm">
    <h5>INFORMACIJA APIE ŪKININKĄ</h5>
    {match.params._id === 'null' ? (
      <FarmersFormContent
        initialValues={getInitialValues()}
        isNew
        history={history}
        isCattle={match.path.split('/')[1] === 'cattle'}
      />
    ) : (
      <Query query={FARMER_QUERY} variables={{ _id: match.params._id }}>
        {({ loading, data }) =>
          loading ? <Loader /> :
            (!data || !data.farmer) ? <ErrorFetch /> :
              <FarmersFormContent
                initialValues={data.farmer}
                history={history}
                isCattle={match.path.split('/')[1] === 'cattle'}
              />
        }
      </Query>
    )}
  </div>
);

export default FarmersForm;
