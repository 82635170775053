import gql from 'graphql-tag';

export const RECOMMENDATIONS = gql`
    query Recommendations {
        recommendations {
            _id
            date
            text
            videoTitle
            videoText
            videoLink
            photoTitle
            photoText
            image {
                uri
            }
        }
    }
`;

