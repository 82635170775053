import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import numeral from 'numeral';
import {
  FARMERS_CULTURE_FOR_SEEDS,
  CULTURE_SEEDS_REMOVE,
  CULTURE_SEEDS_UPDATE,
} from '../queries/seeds.graphql';
import Input from "../../../common/components/Input/Input";
import { CULTURE_OVERVIEW_QUERY } from '../../cultureOverview/queries/cultureOverview.graphql';

class SeedSelected extends PureComponent {
  state = { areaMod: {}, rateMod: {}, priceMod: {}, packsRequiredMod: {} };

  getValue = (state, existing) => (state || state === '') ? state : existing;

  updateArea = ({ totalArea, definedArea, index, currentValue, value }) => {
    const diff = value - currentValue;
    if ((definedArea + diff) > totalArea) {
      alert('Nurodytas plotas viršija kultūros deklaruotą plotą');
      return;
    }
    this.setState({ areaMod: { [index]: value } });
  };

  render() {
    const {
      entityId,
      seeds,
      totalArea,
      farmerId,
      cultureId,
      area,
      maxArea,
      editable,
      hideSeedsArea,
      cultureCode
    } = this.props;
    const { definedArea, totalNeed, totalPrice } = seeds.reduce((acc, seed) => {
      const thisNeed = cultureCode !== 'rapsai' ?
        ((seed.area * seed.rate) / 1000) : Math.ceil(seed.area * seed.packsRequired);
      return ({
        definedArea: acc.definedArea + seed.area,
        totalNeed: acc.totalNeed + thisNeed,
        totalPrice: acc.totalPrice + (thisNeed * seed.price)
      })
    },
      { definedArea: 0, totalNeed: 0, totalPrice: 0 }
    ); // Math.ceil(area * packsRequired))
    const areaCoef = !maxArea ? null : (maxArea / definedArea);
    return (
      <div>
        {/*<div>Benras sėjos plotas yra : {totalArea} Ha</div>*/}
        <table className="table" align="center">
          <tbody>
            <tr>
              <td>
                <strong>Veislė</strong>
              </td>
              {!hideSeedsArea ? <td>
                <strong>Plotas (ha)</strong>
              </td> : null}
              <td>
                <strong>Norma ({cultureCode !== 'rapsai' ? 'kg/ha' : 'pak.'})</strong>
              </td>
              <td>
                <strong>Kiekis ({cultureCode !== 'rapsai' ? 't' : 'pak.'})</strong>
              </td>
              <td>
                <strong>Kaina (Eur/{cultureCode !== 'rapsai' ? 't' : 'pak.'})</strong>
              </td>
              <td>
                <strong>Viso (Eur)</strong>
              </td>
              {editable ? <td /> : null}
            </tr>
            {seeds.map((seed, index) => (
              <Mutation
                key={seed.variety._id}
                mutation={CULTURE_SEEDS_REMOVE}
                refetchQueries={[
                  {
                    query: FARMERS_CULTURE_FOR_SEEDS,
                    variables: { _id: entityId },
                  },
                  {
                    query: CULTURE_OVERVIEW_QUERY,
                    variables: { farmerId, cultureId, area }
                  }
                ]}
                variables={{ index, entityId }}
              >
                {removeMutation => (
                  <Mutation
                    mutation={CULTURE_SEEDS_UPDATE}
                    refetchQueries={[
                      {
                        query: FARMERS_CULTURE_FOR_SEEDS,
                        variables: { _id: entityId },
                      },
                      {
                        query: CULTURE_OVERVIEW_QUERY,
                        variables: { farmerId, cultureId, area }
                      }
                    ]}
                  >
                    {updateMutation => {
                      const { areaMod, rateMod, packsRequiredMod, priceMod } = this.state;
                      const onBlur = () => {
                        if (
                          areaMod[index] ||
                          rateMod[index] ||
                          priceMod[index] ||
                          packsRequiredMod[index]
                        ) {
                          const { variety, __typename, ...restOfSeed } = seed;
                          updateMutation({
                            variables: {
                              entityId,
                              index,
                              seed: {
                                ...restOfSeed,
                                area: parseFloat(areaMod[index]) || seed.area,
                                rate: parseFloat(rateMod[index]) || seed.rate,
                                packsRequired: parseFloat(packsRequiredMod[index]) || seed.packsRequired,
                                price:
                                  parseFloat(priceMod[index]) || seed.price,
                              },
                            },
                            onCompleted: () =>
                              this.setState({
                                areaMod: {},
                                rateMod: {},
                                priceMod: {},
                                packsRequiredMod: {},
                              }),
                          });
                        }
                      };

                      const area = areaCoef ?
                        (seed.area * areaCoef) :
                        this.getValue(areaMod[index], seed.area);
                      const rate = this.getValue(rateMod[index], Math.ceil(seed.rate));
                      const packsRequired = this.getValue(packsRequiredMod[index], seed.packsRequired);
                      const price = this.getValue(priceMod[index], seed.price);

                      const totalNeed = (cultureCode !== 'rapsai' ?
                        ((area * rate) / 1000) : Math.ceil(area * packsRequired));
                      return (
                        <tr key={seed.variety.name}>
                          <td>{seed.variety.name}</td>
                          {!hideSeedsArea ? <td>
                            {editable ? <Input
                              className="SelectedFertilizers-input"
                              type="number"
                              value={area}
                              onChange={event =>
                                this.updateArea({
                                  totalArea,
                                  definedArea,
                                  index,
                                  currentValue: seed.area,
                                  value: event.target.value
                                })
                              }
                              onBlur={onBlur}
                            /> : numeral(area).format('0.[00]')}
                          </td> : null}
                          {cultureCode !== 'rapsai' ?
                            <td>
                              {editable ? <Input
                                className="SelectedFertilizers-input"
                                type="number"
                                value={rate}
                                onChange={event =>
                                  this.setState({
                                    rateMod: { [index]: event.target.value === '' ? '' : parseInt(event.target.value) },
                                  })
                                }
                                onBlur={onBlur}
                              /> : numeral(rate).format('0.[00]')}
                            </td> :
                            <td>
                              {editable ? <Input
                                className="SelectedFertilizers-input"
                                type="number"
                                value={packsRequired}
                                onChange={event =>
                                  this.setState({
                                    packsRequiredMod: { [index]: event.target.value },
                                  })
                                }
                                onBlur={onBlur}
                              /> : numeral(packsRequired).format('0.[000]')}
                            </td>
                          }
                          <td>{numeral(totalNeed).format('0.[00]')}</td>
                          <td>
                            {editable ? <Input
                              className="SelectedFertilizers-input"
                              type="number"
                              value={price}
                              onChange={event =>
                                this.setState({
                                  priceMod: { [index]: event.target.value },
                                })
                              }
                              onBlur={onBlur}
                            /> : numeral(price).format('0.[00]')}
                          </td>
                          <td>
                            {price ? numeral(totalNeed * price).format('0.[00]') : ''}
                          </td>
                          {editable ? <td>
                            <strong>
                              <button onClick={removeMutation}>x</button>
                            </strong>
                          </td> : null}
                        </tr>
                      );
                    }}
                  </Mutation>
                )}
              </Mutation>
            ))}
            <tr>
              <td className="table-summaryRow">
                <strong>Viso:</strong>
              </td>
              {!hideSeedsArea ? <td className="table-summaryRow">
                <strong>{numeral(definedArea).format('0')}</strong>
              </td> : null}
              <td className="table-summaryRow" />
              <td className="table-summaryRow">
                <strong>{numeral(areaCoef ? totalNeed * areaCoef : totalNeed).format('0')}</strong>
              </td>
              <td className="table-summaryRow" />
              <td className="table-summaryRow">
                <strong>{numeral(areaCoef ? totalPrice * areaCoef : totalPrice).format('0.[00]')}</strong>
              </td>
              {editable ? <td className="table-summaryRow" /> : null}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default SeedSelected;
