import moment from 'moment';
import {
  TOKEN_ID,
  TOKEN_EXPIRATION,
  TOKEN_USER,
} from '../constants/system.constant';

export const cleanAuthStorage = () => {
  localStorage.removeItem(TOKEN_ID);
  localStorage.removeItem(TOKEN_EXPIRATION);
  localStorage.removeItem(TOKEN_USER);
};

const hasExpired = () => {
  const storedExpiration = localStorage.getItem(TOKEN_EXPIRATION);
  if (!storedExpiration) {
    cleanAuthStorage();
    return true;
  }
  return moment().isAfter(storedExpiration);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_ID);
  return !token || hasExpired() ? null : token;
};

export const getUser = () => {
  const userString = localStorage.getItem(TOKEN_USER);
  return userString ? JSON.parse(userString) : null;
};

export const setToken = (token, user) => {
  localStorage.setItem(TOKEN_USER, JSON.stringify(user));
  localStorage.setItem(TOKEN_ID, token);
  localStorage.setItem(
    TOKEN_EXPIRATION,
    moment()
      .add(1, 'd')
      .format()
  );
};
