import React from 'react';
import { FARMER_QUERY } from "../../grow-technology/farmers/queries/farmers.graphql";
import FarmersCards from "../../grow-technology/farmers/components/FarmersCard/FarmersCard";
import { Query } from "react-apollo";
import { CATTLE_TYPES } from '../cattle.constants';
import './CattleList.css';
import { Link } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const CattleList = ({ match }) => (
  <div className="px-3">
    <h5>PAPILDAI</h5>
    <Query query={FARMER_QUERY} variables={{ _id: match.params.farmer }}>
      {({ loading, data }) =>
        loading ? <Loader /> :
          !data || !data.farmer ? <ErrorFetch /> :
          [
            <FarmersCards farmer={data.farmer} pathRoot={match.path.split('/')[1]} />,
            <div className="CattleList-descriptions">
              <p>Mūsų siekis – užtikrinti visavertį šėrimą – gyvulio aprūpinimą visomis maisto ir kitomis būtinosiomis medžiagomis, kurios reikalingos įprastiems fiziologiniams procesams užtikrinti ir produkcijai gaminti.</p>
            </div>,
            <div className="CattleList-container">
              {CATTLE_TYPES.map(type => (
                <Link
                  to={`/cattle/calculator/${data.farmer._id}/${type.code}`}
                  className="CattleList-item"
                >
                  <img alt={type.image} className="CattleList-itemImage" src={`/assets/cattle/${type.image}.jpg`} />
                  <h5>{type.name}</h5>
                </Link>
              ))}
            </div>
          ]
      }
    </Query>

  </div>
);

export default CattleList;
