import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './configure-store';
import './styles/style.css';
import './styles/menus.css';
import './styles/framework.css';
import './styles/animate.css';
import './styles/main.css';
import './index.css';
import Root from './common/components/Root/Root';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './apollo-client';
import Provider from 'react-redux/es/components/Provider';

const globalStore = configureStore();
// history={history}
ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={globalStore}>
      <Root className="Root" />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
