import gql from 'graphql-tag';
export default gql`
  {
    cultures {
      code
      name
      shortName
    }
  }
`;
