export const AAP_CATEGORIES = [
  {
    type: 'GROW_REGULATOR',
    label: 'Augimo reguliatorius',
  },
  {
    type: 'HERBICIDES',
    label: 'Herbicidas',
  },
  {
    type: 'INSECTICIDES',
    label: 'Insekticidas',
  },
  {
    type: 'FUNGICIDES',
    label: 'Fungicidas',
  },
  {
    type: 'STAIN',
    label: 'Beicas',
  },
];
