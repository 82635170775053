import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import * as loginActions from '../../../actions/auth-action';
import LoginQuery from '../../queries/login.query';

import './Login.css';
import Loader from "../../../common/components/Loader/Loader";

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

class Login extends React.Component {
  state = { email: '', password: '', isLoading: false };

  isIos = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  componentWillUpdate(nextProps) {
    if (nextProps.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleUsernameChange = ({ target }) => this.setState({
    [target.name]: target.value.toLowerCase(),
  });

  onSubmit = event => {
    event.preventDefault();
    if (this.state.email === '' || this.state.password === '') {
      return;
    }
    this.setState({ isLoading: true });
    this.props
      .mutate({
        variables: { ...this.state },
      })
      .then(({ data }) => {
        const { accessToken, user } = data.logIn;
        this.props.login(accessToken, user);
        this.props.history.push('/');
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (error.toString().indexOf('401') !== -1) {
          alert('Neteisingi prisijungimo duomenys!');
        } else if (error.toString().includes('Network')) {
          alert('Nepavyko pasiekti serverio! Prašome pranešti administratoriui');
        }
        this.setState({ isLoading: false });
        console.log('there was an error sending the query', error);
      });
  };

  render() {
    const { email, password, isLoading } = this.state;
    const autocomplete = this.isIos() ? 'off' : 'on';
    return (
      <div id="content" className="snap-content">
        <div className="Login pageapp-login bg-5">
          <div className="Login-overlay" />
          <div className="pageapp-login-content cover-center">
            <Form  className="unboxed-layout" onSubmit={this.onSubmit}>
              <div className="pageapp-login-logo" />
              <FormGroup className="pageapp-login-field Login-fieldContainer">
                <FontAwesomeIcon icon={faUser} />
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Prisijungimo vardas"
                  autoComplete={autocomplete}
                  value={email}
                  onChange={this.handleUsernameChange}
                />
              </FormGroup>
              <FormGroup className="pageapp-login-field Login-fieldContainer">
                <FontAwesomeIcon icon={faLock} />
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Slaptažodis"
                  autoComplete={autocomplete}
                  value={password}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <Button
                className="pageapp-login-button button button-small button-green button-fullscreen"
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : 'Prisijungti'}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = propTypes;

const mapStateToProps = ({ auth: { isLoggedIn } }) => ({ isLoggedIn });

const mapDispatchToProps = dispatch => ({
  login: (accessToken, user) => dispatch(loginActions.login(accessToken, user))
});

export default compose(
  graphql(LoginQuery),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login);
