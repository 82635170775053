import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { SeedItem } from '../queries/seed.query';
import { FertilizerItem } from '../queries/fertilizer.query';
import { AapItem } from '../queries/aap.query';
import { AdditionalItem } from '../queries/additional.query';
import { CattleItem } from '../queries/cattle.query';
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const queriesMap = {
  seeds: SeedItem,
  fertilizer: FertilizerItem,
  aap: AapItem,
  additional: AdditionalItem,
  cattle: CattleItem,
};

const queriesResponseMap = {
  seeds: 'seedVariety',
  fertilizer: 'fertilizer',
  aap: 'aapProduct',
  additional: 'additionalFertilizer',
  cattle: 'cattleProduct',
};

class StockItem extends Component {
  render () {
    const itemQuery = queriesMap[this.props.match.params.type];
    const response = queriesResponseMap[this.props.match.params.type];
    return (
      <Query variables={{ _id: this.props.match.params.id }} query={itemQuery}>
        {({ loading, data }) =>
          loading ? <Loader /> :
          !data ? <ErrorFetch /> :
            [
              data[response].image ? <img alt={data[response].name} src={data[response].image.uri} /> : null,
              <div className="decoration-lines container-fullscreen">
                <div className="deco-0" />
                <div className="deco-1" />
                <div className="deco-2" />
                <div className="deco-3" />
                <div className="deco-4" />
                <div className="deco-5" />
                <div className="deco-6" />
                <div className="deco-7" />
                <div className="deco-8" />
                <div className="deco-9" />
              </div>,
              <h2>{data[response].name}</h2>,
              <div className="px-3">{data[response].shortDescription}</div>,
              data[response].price || data[response].rate ?
                <button className="my-2 button button-success">
                  {data[response].price || data[response].rate} EUR
                </button> : null,
              <div className="decoration" />,
              <div className="px-3" dangerouslySetInnerHTML={{ __html: data[response].description }} />
            ]
        }
      </Query>
    )
  }
}

export default StockItem;
