import gql from 'graphql-tag';

export const GRAIN_PRICES = gql`
    query GrainPrices {
        grainPrices {
            name
            price
            updateTime
        }
    }
`;

export const GRAIN_CALCULATOR = gql`
  query GrainCalculator($userId: String!) {
      user(_id: $userId) {
          _id
          firstName
          elevatorIds
          elevators {
              _id
              name
              finalDeduction
              acceptance
              kmToPort
              transportToPort
              transportToPortG
              transportationCorection
              cultureVisibility {
                  cultureId
                  visible
                  deduction
              }

          }
      }
      calculatorValues {
          transportationPrice {
              perKm
          }
          seedsPrices {
              name
              code
              price
          }
      }
      calculatorCultures {
          _id
          name
          type
          deduction
          notInPort
      }
  }
`;
