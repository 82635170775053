const STAIN = 'STAIN';
const GROW_REGULATOR = 'GROW_REGULATOR';
const HERBICIDES = 'HERBICIDES';
const INSECTICIDES = 'INSECTICIDES';
const FUNGICIDES = 'FUNGICIDES';
const SURFACE = 'SURFACE';

// Papildomos trasos
export const ADDITIONAL_FERTILEZER_LIST = {
  CHELATED_FERTILIZER: 'CHELATED_FERTILIZER',
  BIOSTIMULANT: 'BIOSTIMULANT',
  LEAF_FERTILIZER: 'LEAF_FERTILIZER',
  HUMIC_ACIDS: 'HUMIC_ACIDS',
  NPK_STANDART: 'NPK_STANDART',
  SPEC_CORECTORS: 'SPEC_CORECTORS'
};

export const ADDITIONAL_FERTILEZER_LABELS = {
  [ADDITIONAL_FERTILEZER_LIST.CHELATED_FERTILIZER]: 'Chelatinės trąšos',
  [ADDITIONAL_FERTILEZER_LIST.BIOSTIMULANT]: 'Biostimuliatoriai',
  [ADDITIONAL_FERTILEZER_LIST.LEAF_FERTILIZER]: 'Lapų trąšos',
  [ADDITIONAL_FERTILEZER_LIST.HUMIC_ACIDS]: 'Huminės rūgštys',
  [ADDITIONAL_FERTILEZER_LIST.NPK_STANDART]: 'NPK ir startinės trąšos',
  [ADDITIONAL_FERTILEZER_LIST.SPEC_CORECTORS]: 'Specialūs korektoriai'
};

export const PRODUCT_CATEGORIES = {
  STAIN,
  GROW_REGULATOR,
  HERBICIDES,
  INSECTICIDES,
  FUNGICIDES,
  SURFACE
};

export const PRODUCT_CATEGORIES_LIST = [
  STAIN,
  GROW_REGULATOR,
  HERBICIDES,
  INSECTICIDES,
  FUNGICIDES,
  SURFACE
];

export const PRODUCT_CATEGORIES_LABELS = {
  [STAIN]: 'Beicas',
  [GROW_REGULATOR]: 'Augimo reguliatorius',
  [HERBICIDES]: 'Herbicidas',
  [INSECTICIDES]: 'Insekticidas',
  [FUNGICIDES]: 'Fungicidas',
  [SURFACE]: 'Paviršiaus aktyvi medžiaga'
};
