import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTimes, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import * as loginActions from '../../../actions/auth-action';
import { MENU_LIST } from '../../constants/menu.constant';

import 'font-awesome/css/font-awesome.css';
import './Sidebar.css';

const SidebarMenuItemContent = ({ label, iconName, isControl, close, isOpen, hasSub, isSub }) => (
  <div className="SidebarMenuItem-label" onClick={close}>
    <div
      className={`SidebarMenuItem-icon ${isControl &&
        'SidebarMenuItem-iconControl'} ${isOpen && 'open'}`}
    >
      <FontAwesomeIcon icon={iconName} size={isSub ? '1x' : '2x'} />
    </div>
    <span className="SidebarMenuItem-text">{label}</span>
    {hasSub &&
      <div
        className={`SidebarMenuItem-icon SidebarMenuItem-iconPlus ${isControl &&
        'SidebarMenuItem-iconControl'} ${isOpen && 'open'}`}
      >
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
      </div>
    }
  </div>
);

const RootNavItem = ({ children }) => (
  <div>
    {children}
  </div>
);

class SidebarMenuItem extends PureComponent {
  state = { isOpen: false };
  clickHandler = () => this.setState(state => ({ isOpen: !state.isOpen }));
  render() {
    const { path, label, iconName, close, sub } = this.props;
    const MenuComponent = sub ? RootNavItem : NavLink;
    return (
      <MenuComponent className="SidebarMenuItem" to={path}>
        <SidebarMenuItemContent
          label={label}
          iconName={iconName}
          close={sub ? this.clickHandler : close}
          isOpen={this.state.isOpen}
          hasSub={!!sub}
        />
        <div className="SidebarMenuItem-subContainer">
          {sub && this.state.isOpen ?
            sub.map(item =>
              (
              <SidebarMenuItem
                key={item.path}
                path={item.path}
                label={item.label}
                iconName={item.iconName}
                close={() => { this.clickHandler(); close();}}
                isSub={true}
              />
              )
            ) : null
          }
        </div>
      </MenuComponent>
    );
  }
};

const Sidebar = ({ visible, close, logout, isLimited }) => (
  <div id="Sidebar" className={`Sidebar ${visible ? 'Sidebar-open' : ''}`}>
    <div className="Sidebar-closeButton" onClick={close}>
      <NavLink to="/" className="Sidebar-logo">
        <img alt="logo" className="Sidebar-logoImage" src="/assets/logo-dark.png" />
      </NavLink>
    </div>
    {MENU_LIST(isLimited).map(({ path, label, iconName, sub }) => (
      <SidebarMenuItem
        key={path}
        path={path}
        label={label}
        iconName={iconName}
        close={close}
        sub={sub}
      />
    ))}
    <SidebarMenuItemContent
      label="ATSIJUNGTI"
      iconName={faSignOutAlt}
      isControl
      close={logout}
    />
    <SidebarMenuItemContent
      label="UŽDARYTI"
      iconName={faTimes}
      isControl
      close={close}
    />
  </div>
);

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(loginActions, dispatch).logout,
});

export default connect(
  null,
  mapDispatchToProps
)(Sidebar);
