import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import numeral from 'numeral';

import {
  AAP_MIX_AND_PHASE_QUERY,
  INSERT_AAP_RECORD,
  FARMERS_CULTURE_FOR_AAP,
} from '../queries/aap.graphql';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import './AapPhases.css';
import { CULTURE_OVERVIEW_QUERY, SEND_HTML_PROPOSAL } from "../../cultureOverview/queries/cultureOverview.graphql";
import { FARMER_PROPOSALS } from '../../../proposals/queries/proposals.graphql';
import { PROPOSAL_TYPES } from "../../../common/constants/proposal.constants";
import Input from "../../../common/components/Input/Input";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class AapAddSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    date: moment().format('YYYY-MM-DD'),
    updates: {},
    alerted: {},
  };

  dateChangeHandler = event => {
    this.setState({ date: event.target.value });
  };

  onAreaChange = event => this.setState({ area: event.target.value === '' ? '' : parseInt(event.target.value) });

  onValueChange = (id, value) => this.setState(state => ({
    updates: {
      ...state.updates,
      [id]: {
        ...state.updates[id],
        value: value === '' ? '' :parseFloat(value)
      }
    }
  }));

  onPriceChange = (id, price) => this.setState(state => ({
    updates: {
      ...state.updates,
      [id]: {
        ...state.updates[id],
        price: price === '' ? '' :parseFloat(price)
      }
    }
  }));

  getProducts = ({ aapMix, additionalId, isProposal }) => {
    const { updates } = this.state;
    const mainProducts = aapMix.products.map(({ _id, code, name, value, measureUnit, price }) => ({
      [isProposal ? 'name' : 'productCode']: isProposal ? name : code,
      [isProposal ? 'rate' : 'quantity']: updates[_id] && updates[_id].value ? updates[_id].value : value,
      measureUnit,
      price: updates[_id] && updates[_id].price ? updates[_id].price : price,
      isAdditional: false
    }));
    const additionalProducts = aapMix.additional.find(additional => additional._id === additionalId)
      .products.map(({ _id, code, name, value, measureUnit, price }) => ({
        [isProposal ? 'name' : 'productCode']: isProposal ? name : code,
        [isProposal ? 'rate' : 'quantity']: updates[_id] && updates[_id].value ? updates[_id].value : value,
        measureUnit,
        price: updates[_id] && updates[_id].price ? updates[_id].price : price,
        isAdditional: true
      }));

    return [ ...mainProducts, ...additionalProducts ];
  };

  save = ({ area, aapMix, additionalId, entityId, phaseId, cultureCode, farmerId, insertAapRecord }) => {
    // const { updates } = this.state;

    const products = this.getProducts({ aapMix, additionalId });

    insertAapRecord({
      variables: {
        entityId,
        record: {
          date: this.state.date,
          phaseCode: phaseId,
          cultureCode,
          area,
          products
        }
      },

    }).then(() => {
      alert('Pakeitimai išsaugoti');
      setTimeout(() => {
        this.props.history.replace(
          `/grow-technology/culture-overview/${cultureCode}/${farmerId}/${area}`
        );
      }, 500);
    });
  };

  sendProposal = ({ sendProposal, farmerId, cultureId, farmerCulture, area, aapMix, additionalId, phaseName }) => {
    const comments = prompt('Komentarai:');
    const domClone = this.myRef.current.cloneNode(true);
    // 2. disable all inputs
    const inputs = domClone.querySelectorAll('input, select');
    [...inputs].forEach(input => {
      input.disabled = true;
    });

    // 2. disable all inputs
    const buttons = domClone.querySelectorAll('button, a');
    [...buttons].forEach(button => {
      button.remove();
    });

    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;

    sendProposal({
      variables: {
        userId,
        farmerId,
        cultureId,
        comments,
        html: domClone.outerHTML,
        proposalType: PROPOSAL_TYPES.AAP,

      }
    }).then(() => alert('Pasūlymas išsiųstas'));
    alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    // return;

    // const comments = prompt('Komentarai:');
    // const products = this.getProducts({ aapMix, additionalId, isProposal: true });
    // const proposedCultures = {
    //   name: farmerCulture.culture.name,
    //   cultureCode: farmerCulture.culture.code,
    //   area,
    //   aaps: [{
    //     phaseName,
    //     date: this.state.date,
    //     area,
    //     products,
    //   }],
    // };
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     cultureId,
    //     comments,
    //     proposedCultures,
    //     proposalType: PROPOSAL_TYPES.AAP,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    const { match } = this.props;
    const entityId = match.params.id;
    const phaseId = match.params.phaseId;
    const aapMixId = match.params.mixId;
    const additionalId = match.params.additionalId;
    return (
      <div className="CultureOverview">
        <h5>AAP MAIŠYMO VARIANTAI</h5>
          <Query
            variables={{ entityId, aapMixId, phaseId }}
            query={AAP_MIX_AND_PHASE_QUERY}
          >
            {({ loading, data }) => {
              if (loading) return <Loader />;
              if (!data || !data.farmersCultureById) return <ErrorFetch />;

              const aapPhase = data.farmersCultureById.culture.aapPhases.find(
                phase => phase._id === phaseId
              );
              const area = this.state.area ||
                this.state.area === '' ? this.state.area : data.farmersCultureById.aapAvailableArea;
              const { updates } = this.state;

              const { totalAmmountM, totalPriceM } = data.aapMix.products.reduce((acc, product) => {
                const value = updates[product._id] &&
                (updates[product._id].value || updates[product._id].value === '') ?
                  updates[product._id].value : product.value;
                const price = updates[product._id] &&
                (updates[product._id].price || updates[product._id].price === '') ?
                  updates[product._id].price : product.price;
                return {
                  totalAmmountM: acc.totalAmmountM + (value * area),
                  totalPriceM: acc.totalPriceM + (value * area * price),
                }
              }, { totalAmmountM: 0, totalPriceM: 0 });

              const { totalPrice } = data.aapMix.additional
                .find(additional => additional._id === additionalId).products.reduce((acc, product) => {
                const value = updates[product._id] &&
                (updates[product._id].value || updates[product._id].value === '') ?
                  updates[product._id].value : product.value;
                const price = updates[product._id] &&
                (updates[product._id].price || updates[product._id].price === '') ?
                  updates[product._id].price : product.price;
                return {
                  totalAmmount: acc.totalAmmount + (value * area),
                  totalPrice: acc.totalPrice + (value * area * price),
                }
              }, { totalAmmount: totalAmmountM, totalPrice: totalPriceM });
              const isBeicas = aapPhase.name === 'Beicavimas';

              return (
                <Mutation
                  mutation={INSERT_AAP_RECORD}
                  refetchQueries={[{
                    query: CULTURE_OVERVIEW_QUERY,
                    variables: {
                      farmerId: data.farmersCultureById.farmer._id,
                      cultureId: data.farmersCultureById.culture._id,
                      area: data.farmersCultureById.area,
                    }
                  }, {
                    variables: { _id: entityId },
                    query: FARMERS_CULTURE_FOR_AAP
                  }]}
                >
                  {insertAapRecord => [
                    <FarmersCards
                      key="FarmersCards"
                      farmer={data.farmersCultureById.farmer}
                      pathRoot={match.path.split('/')[1]}
                    />,
                    <div ref={this.myRef} key="AapAddSummaryContent">
                      <div key="Augalai" className="AapType-nameTitle">
                        Augalai: {data.farmersCultureById.culture.name}
                      </div>
                      <div key="Augimo" className="AapType-nameTitle">
                        Augimo tarpsnis: {aapPhase && aapPhase.name}
                      </div>
                      <div key="MixName" className="AapType-nameTitle mb-4">
                        Augalų apsaugos produktas: {data.aapMix.name}
                      </div>
                      <table className="AapSummaryDate">
                        <tbody>
                          <tr>
                            <td className="AapSummaryDate-title">Rekomenduojama {isBeicas ? 'beicavimo' : 'purškimo'} data</td>
                            <td className="AapSummaryDate-inputCell">
                              <Input
                                type="text"
                                className="AapSummaryDate-input"
                                value={this.state.date}
                                onChange={this.dateChangeHandler}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="AapSummaryDate-title">
                              {aapPhase.name === 'Beicavimas' ? 'Kiekis' : 'Plotas'}
                            </td>
                            <td className="AapSummaryDate-inputCell">
                              <Input
                                type="text"
                                className="AapSummaryDate-input"
                                value={area}
                                onChange={this.onAreaChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="AapAddSummary-table">
                        <tbody>
                          <tr>
                            <td className="AapAddSummary-table-rowTitle"><b>Produktas</b></td>
                            <td><b>Norma</b></td>
                            <td><b>Kiekis (kg/l)</b></td>
                            <td><b>Kaina (Eur)</b></td>
                            <td><b>Viso (Eur)</b></td>
                          </tr>
                          {data.aapMix.products.map(product => {
                            const value = updates[product._id] &&
                              (updates[product._id].value || updates[product._id].value === '') ?
                              updates[product._id].value : product.value;
                            const price = updates[product._id] &&
                              (updates[product._id].price || updates[product._id].price === '') ?
                              updates[product._id].price : product.price;
                            return (
                              <tr key={product._id}>
                                <td className="AapAddSummary-table-rowTitle">
                                  {product.descriptionURL ? <a target="_blank" href={product.descriptionURL}>{product.name}</a> : product.name}
                                </td>
                                <td>
                                  <Input
                                    className="AapAddSummary-tableInput"
                                    value={value}
                                    type="number"
                                    onChange={event => {
                                      if (parseFloat(event.target.value) > product.maxValue && !this.state.alerted[product._id]) {
                                        alert(`Nustatyta reikšmė yra didesnė nei nurodyta maksimali (${product.maxValue})`);
                                        this.setState(state => ({ alerted: { ...state.alerted, [product._id]: true}}))
                                      }
                                      this.onValueChange(product._id, event.target.value)
                                    }}
                                  />
                                  {isBeicas ? ' l/t' : product.measureUnit}
                                </td>
                                <td>{area && value ? numeral(value * area).format('0.[000]') : ''}</td>
                                <td>
                                  <Input
                                    className="AapAddSummary-tableInput"
                                    value={price}
                                    type="number"
                                    onChange={event => this.onPriceChange(product._id, event.target.value)}
                                  />
                                </td>
                                <td>{value && area && price ? numeral(value * area * price).format('0.[00]') : ''}</td>
                              </tr>
                            );
                          })}
                          {data.aapMix.additional.find(additional => additional._id === additionalId)
                            .products.map(product => {
                              const value = updates[product._id] &&
                              (updates[product._id].value || updates[product._id].value === '') ?
                                updates[product._id].value : product.value;
                              const price = updates[product._id] &&
                              (updates[product._id].price || updates[product._id].price === '') ?
                                updates[product._id].price : product.price;
                              return(
                                <tr key={product._id}>
                                  <td className="AapAddSummary-table-rowTitle">{product.name}</td>
                                  <td>
                                    <Input
                                      className="AapAddSummary-tableInput"
                                      value={value}
                                      type="number"
                                      onChange={event => {
                                        if (parseFloat(event.target.value) > product.maxValue && !this.state.alerted[product._id]) {
                                          alert(`Nustatyta reikšmė yra didesnė nei nurodyta maksimali (${product.maxValue})`);
                                          this.setState(state => ({ alerted: { ...state.alerted, [product._id]: true}}))
                                        }
                                        this.onValueChange(product._id, event.target.value)
                                      }}
                                    />
                                    {isBeicas ? ' l/t' : product.measureUnit}
                                  </td>
                                  <td>{area && value ? numeral(value * area).format('0.[000]') : ''}</td>
                                  <td>
                                    <Input
                                      className="AapAddSummary-tableInput"
                                      value={price}
                                      type="number"
                                      onChange={event => this.onPriceChange(product._id, event.target.value)}
                                    />
                                  </td>
                                  <td>{value && area && price ? numeral(value * area * price).format('0.[00]') : ''}</td>
                                </tr>
                              )
                            })
                          }
                          <tr>
                            <td className="table-summaryRow text-left pl-2" colSpan="4">Viso:</td>
                            <td className="table-summaryRow">{numeral(totalPrice).format('0.[00]')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>,

                    <div key="AapAddSummaryContentButtons">
                      <button
                        className="button button-success"
                        onClick={() => this.save({
                          area,
                          aapMix: data.aapMix,
                          additionalId,
                          insertAapRecord,
                          entityId,
                          phaseId,
                          farmerId: data.farmersCultureById.farmer._id,
                          cultureCode: data.farmersCultureById.culture.code
                        })}
                      >
                        Išsaugoti
                      </button>
                      <Mutation
                        mutation={SEND_HTML_PROPOSAL}
                        refetchQueries={[
                          {
                            query: FARMER_PROPOSALS,
                            variables: { farmerId: data.farmersCultureById.farmer._id }
                          },
                        ]}
                      >
                        {sendProposal => (
                          <button
                            className="button button-warning"
                            onClick={() =>this.sendProposal({
                              sendProposal,
                              farmerId: data.farmersCultureById.farmer._id,
                              cultureId: data.farmersCultureById.culture._id,
                              farmerCulture: data.farmersCultureById,
                              area,
                              aapMix: data.aapMix,
                              additionalId,
                              phaseName: aapPhase.name,
                            })}
                          >
                            Išsiųsti pasiūlymą
                          </button>
                        )}
                      </Mutation>
                    </div>
                  ]}
                </Mutation>
              )
            }}
          </Query>
      </div>
    );
  }
}

export default AapAddSummary;
