import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FarmersRoot from '../grow-technology/farmers/components/FarmersRoot';
import CattleList from './list/CattleList';
import CattleCalculator from './CattleCalculator/CattleCalculator';

const CattleModule = () => (
  <Switch>
    <Route path="/cattle/farmers" render={(props) => <FarmersRoot {...props} isCattle />} />
    <Route path="/cattle/list/:farmer" component={CattleList} />
    <Route path="/cattle/calculator/:farmer/:category" component={CattleCalculator} />
    <Redirect to="/cattle/farmers" />
  </Switch>
);

export default CattleModule;
