export const FERTILIZER_TYPES = {
  ALL: 'ALL',
  N: 'N',
  NPK: 'NPK',
  KITA: 'KITA',
};

export const FERTILIZER_TYPES_LIST = [
  FERTILIZER_TYPES.ALL,
  FERTILIZER_TYPES.N,
  FERTILIZER_TYPES.NPK,
  // FERTILIZER_TYPES.KITA,
];

export const FERTILIZER_TYPE_LABELS = {
  [FERTILIZER_TYPES.ALL]: "Visos trąšos",
  [FERTILIZER_TYPES.N]: 'N trąšos',
  [FERTILIZER_TYPES.NPK]: 'NPK trąšos',
  [FERTILIZER_TYPES.KITA]: 'Kitos trąšos',
};

export const FERTILIZER_SORT = {
  [FERTILIZER_TYPES.N]: 'NPKS.N',
  [FERTILIZER_TYPES.NPK]: 'NPKS.K',
};

export default {
  FERTILIZER_TYPES,
  FERTILIZER_TYPES_LIST,
  FERTILIZER_TYPE_LABELS,
  FERTILIZER_SORT,
};
