import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import { orderBy, round } from 'lodash';
import FontAwesome from 'react-fontawesome';
import {
  FERTILIZERS_QUERY,
  ADD_FERTILIZER,
  FARMERS_CULTURE_FOR_FERTILIZER,
} from '../queries/fertilizers.graphql';
import {
  FERTILIZER_TYPES,
  FERTILIZER_TYPES_LIST,
  FERTILIZER_TYPE_LABELS,
  FERTILIZER_SORT,
} from '../constants/fertilizers.constants';
import './FertilizerCompare.css';
import 'font-awesome/css/font-awesome.css';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

// const FertilizerCompareRow = ({ fertis, norm, setNorm }) => (
//   <tr key={fertis._id}>
//     <td className="FertilizerCompare-tableCell">{fertis.name}</td>
//     <td>
//       <Input
//         type="number"
//         value={norm}
//         onChange={event => setNorm(fertis._id, event.target.value)}
//       />
//     </td>
//     <td>{fertis.NPKS.N}</td>
//     <td>{fertis.NPKS.P}</td>
//     <td>{fertis.NPKS.K}</td>
//     <td>{fertis.NPKS.S}</td>
//   </tr>
// );

class FertilizerCompare extends PureComponent {
  constructor(props) {
    super(props);

    // const selectedType = props.needForK <= -1 ? FERTILIZER_TYPES_LIST[0] : FERTILIZER_TYPES_LIST[1];
    const selectedType = FERTILIZER_TYPES_LIST[0];
    this.state = {
      norms: {},
      selectedType,
      coefficient: this.countCoefficient(selectedType),
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.needForK !== prevProps.needForK) {
      if (this.props.needForK > -1 && this.state.selectedType === FERTILIZER_TYPES_LIST[2]) {
        this.setState({ selectedType: FERTILIZER_TYPES_LIST[1] })
      }
    }
  }

  setNorm = (id, value) => {
    this.setState(({ norm }) => ({ norms: { ...norm, [id]: value } }));
  };

  countCoefficient = selectedType => {
    const { surfaceNorm, needForN, needForK } = this.props;

    return selectedType === FERTILIZER_TYPES.N
      ? (surfaceNorm / (needForN * surfaceNorm)) * -1
      : (surfaceNorm / (needForK * surfaceNorm)) * -1;
  };

  selectType = value =>
    this.setState({
      selectedType: value,
      coefficient: this.countCoefficient(value),
    });

  getSorted = fertilizers =>
    this.state.selectedType === FERTILIZER_TYPES[0]
      ? fertilizers
      : orderBy(fertilizers, FERTILIZER_SORT[this.state.selectedType], 'desc');

  selectFertis = (addFertilizer, fertis, norm) => {
    const { selectedYield, entityId, surfaceNorm } = this.props;

    const variables = {
      entityId,
      fertilizer: {
        fertilizerId: fertis._id,
        rate: parseFloat(norm),
        price: fertis.price,
        selectedYield,
        isSurface: surfaceNorm === 0.85,
      },
    };

    addFertilizer({ variables });
  };

  render() {
    return (
      <div>
        <h5>TRĘŠIMO PLANAVIMAS</h5>
        <select
          className="select"
          value={this.state.selectedType}
          onChange={event => this.selectType(event.target.value)}
        >
          {FERTILIZER_TYPES_LIST.map(type => (
            <option key={type} value={type}>
              {FERTILIZER_TYPE_LABELS[type]}
            </option>
          ))}
        </select>
        <Query
          variables={{
            seedsType: this.props.cultureType,
            type: this.state.selectedType,
          }}
          query={FERTILIZERS_QUERY}
        >
          {({ loading, data }) =>
            loading ? <Loader /> :
              (!data || !data.fertilizers) ? <ErrorFetch /> :
                (
                  <Mutation
                    mutation={ADD_FERTILIZER}
                    refetchQueries={() => [
                      {
                        query: FARMERS_CULTURE_FOR_FERTILIZER,
                        variables: { _id: this.props.entityId },
                      },
                    ]}
                  >
                    {addFertilizer => (
                      <table
                        className="table FertilizerCompare-table"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <strong>Produktas</strong>
                            </td>
                            <td className="FertilizerCompare-normColumn">
                              <strong>kg/ha</strong>
                            </td>
                            <td>
                              <strong>N</strong>
                            </td>
                            <td>
                              <strong>P</strong>
                            </td>
                            <td>
                              <strong>K</strong>
                            </td>
                            <td>
                              <strong>S</strong>
                            </td>
                            <td />
                          </tr>
                          {this.getSorted(data.fertilizers).map(fertis => {
                            const { surfaceNorm, needForN, needForK } = this.props;
                            const { selectedType } = this.state;
                            let coefficient = 0;

                            // if (this.props.selectedFertilizers.findIndex(selected =>
                            //   selected.fertilizer._id === fertis._id
                            // ) > -1) {
                            //   return null;
                            // }

                            if (this.state.norms[fertis._id]) {
                              coefficient =
                                this.state.norms[fertis._id] / fertis.acuracy;
                            } else if (selectedType === FERTILIZER_TYPES.ALL && fertis.NPKS.K === 0) {
                              coefficient = -needForN / (fertis.NPKS.N);
                            } else if (
                              (selectedType === FERTILIZER_TYPES.N &&
                                fertis.NPKS.N === 0) ||
                              ((selectedType === FERTILIZER_TYPES.NPK ||
                                selectedType === FERTILIZER_TYPES.ALL) &&
                                fertis.NPKS.K === 0)
                            ) {
                              coefficient = 0;
                            } else {
                              coefficient =
                                selectedType === FERTILIZER_TYPES.N
                                  ? -needForN / (fertis.NPKS.N * surfaceNorm)
                                  : -needForK / (fertis.NPKS.K * surfaceNorm);
                            }

                            const norm =
                              this.state.norms[fertis._id] || this.state.norms[fertis._id] === '' ?
                                this.state.norms[fertis._id] : round(fertis.acuracy * coefficient);

                            return this.state.selectedType === FERTILIZER_TYPES_LIST[0] && norm <= 0 && norm !== '' ?
                              null : (
                              <tr key={fertis._id}>
                                <td className="FertilizerCompare-tableCell">
                                  {fertis.descriptionURL ? <a target="_blank" href={fertis.descriptionURL}>{fertis.name}</a> : fertis.name}
                                </td>
                                <td>
                                  <b>{norm}</b>
                                  {/*<Input*/}
                                    {/*className="FertilizerCompare-input"*/}
                                    {/*type="number"*/}
                                    {/*value={norm}*/}
                                    {/*onChange={event =>*/}
                                      {/*this.setNorm(fertis._id, event.target.value)*/}
                                    {/*}*/}
                                  {/*/>*/}
                                </td>
                                <td>
                                  {fertis.NPKS.N > 0
                                    ? round(fertis.NPKS.N * coefficient)
                                    : 0}
                                </td>
                                <td>
                                  {fertis.NPKS.P > 0
                                    ? round(fertis.NPKS.P * coefficient)
                                    : 0}
                                </td>
                                <td>
                                  {fertis.NPKS.K > 0
                                    ? round(fertis.NPKS.K * coefficient)
                                    : 0}
                                </td>
                                <td>
                                  {fertis.NPKS.S > 0
                                    ? round(fertis.NPKS.S * coefficient)
                                    : 0}
                                </td>
                                <td
                                  onClick={() =>
                                    this.selectFertis(addFertilizer, fertis, norm)
                                  }
                                >
                                  <FontAwesome name="shopping-cart" />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </Mutation>
                )
          }
        </Query>
      </div>
    );
  }
}

export default FertilizerCompare;
