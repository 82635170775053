import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { GRAIN_CALCULATOR } from '../queries/grains.graphql';
import { DELIVERY_OPTIONS } from '../grains.constants';
import './GrainCalculator.css';
import Input from "../../common/components/Input/Input";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const MatifRow = ({ seed, value, onUpdate }) => {
  const handleChange = event => onUpdate({ code: seed.code, value: event.target.value });
  return (
    <div className="GrainCalculator-matifRow">
      <div className="text-center GrainCalculator-matifCell">
        {seed.name} MATIF
      </div>
      <div className="GrainCalculator-matifCell">
        <Input
          className="text-field"
          type="number"
          step="0.25"
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  )
};

const GrainsSelect = ({ options, selected, onSelect }) => {
  const selectHandler = event => onSelect(event.target.value);
  return (
    <select className="select" onChange={selectHandler} value={selected}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

class GrainPrices extends PureComponent {
  state = { matif: {}, deliveryPlace: 'YARD_ELEVATOR' };

  handleMatifUpdate = ({ code, value }) =>
    this.setState(state => ({ matif: { ...state.matif, [code]: value !== '' ? parseFloat(value) : value }}));

  handleDeliveryPlace = deliveryPlace => this.setState({ deliveryPlace });

  handleKmChange = ({ target }) => this.setState({ km: target.value });

  handleElevatorSelect = selectedElevator => this.setState({ selectedElevator });

  render() {
    return (
      <div className="px-3">
        <h5>GRŪDŲ KAINŲ SKAIČIUOKLĖ</h5>
        <Query query={GRAIN_CALCULATOR} variables={{ userId: this.props.user._id }}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.calculatorValues) return <ErrorFetch />;

            const { matif } = this.state;
            const matifPrices = data.calculatorValues.seedsPrices.reduce((acc, { code, price }) =>
              ({ ...acc, [code]: (matif[code] || matif[code] === '') ? matif[code] : price }),
              {}
            );
            const elevator = this.state.selectedElevator ?
              data.user.elevators.find(({ _id }) => _id === this.state.selectedElevator) :
              data.user.elevators[0];
            return [
              <div className="GrainCalculator-matif mb-3">
                {data.calculatorValues.seedsPrices.map(seed => (
                  <MatifRow
                    seed={seed}
                    value={matifPrices[seed.code]}
                    onUpdate={this.handleMatifUpdate}
                  />
                ))}
              </div>,
              <div className="mb-2">Pasirinkite pirkimo variantą</div>,
              <GrainsSelect
                options={DELIVERY_OPTIONS}
                selected={this.state.deliveryPlace}
                onSelect={this.handleDeliveryPlace}
              />,
              this.state.deliveryPlace === 'PORT' ? null : [
                <div className="mb-2">Pasirinkite elevatorių</div>,
                <GrainsSelect
                  options={data.user.elevators.filter(item => !!item).map(({ name, _id }) => ({ name, value: _id }))}
                  selected={this.state.selectedElevator || data.user.elevators[0]._id}
                  onSelect={this.handleElevatorSelect}
                />
              ],
              this.state.deliveryPlace === 'YARD_ELEVATOR' ? (
                <div className="GrainCalculator-matif">
                  <div className="GrainCalculator-matifRow">
                    <div className="text-center GrainCalculator-matifCell">
                      Kilometrai
                    </div>
                    <div className="GrainCalculator-matifCell">
                      <Input
                        className="text-field"
                        type="number"
                        step="0.25"
                        value={this.state.km}
                        onChange={this.handleKmChange}
                      />
                    </div>
                  </div>
                </div>
              ) : null,
              <h5>Grūdų kaina pristatant į uostą</h5>,
              <table className="table">
                <tbody>
                  <tr>
                    <td><b>Kultūra</b></td>
                    <td><b>Kaina Eur/t</b></td>
                    <td><b>Nuoskaita</b></td>
                  </tr>
                  {data.calculatorCultures.map(culture => {
                    if (this.state.deliveryPlace === 'PORT' && culture.notInPort) return null;
                    let deduction = culture.deduction;
                    if (this.state.deliveryPlace !== 'PORT') {
                      const cultureVisibility = elevator.cultureVisibility.find(
                        ({ cultureId }) => cultureId === culture._id
                      );
                      if (!cultureVisibility.visible) return null;
                      if (cultureVisibility && cultureVisibility.deduction) {
                        deduction += cultureVisibility.deduction;
                      }
                      if (this.state.deliveryPlace === 'YARD_ELEVATOR') {
                        console.log(this.state.km, data.calculatorValues);
                        deduction -= (this.state.km) ?
                          (parseFloat(this.state.km) * data.calculatorValues.transportationPrice.perKm) : 0;
                      }
                    }
                    return (
                      <tr>
                        <td>{culture.name}</td>
                        <td>{numeral(matifPrices[culture.type] + deduction).format('0.[00]')}</td>
                        <td className={deduction < 0 ?'GrainCalculator-deduction' : ''}>
                          {numeral(deduction).format('0.[00]')}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ]
          }}
        </Query>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(GrainPrices);
