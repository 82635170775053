import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import uniq from 'lodash/uniq';
import { PRODUCT_CATEGORIES_LABELS } from '../aap.constants';
import {
  FARMERS_CULTURE_PHASES,
  AAP_MIXES_QUERY,
} from '../queries/aap.graphql';
import './AapType.css';
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import { Link } from 'react-router-dom';
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

const Button = props => <button {...props} />;

class AapType extends PureComponent {
  state = { selected: [] };
  toggleSelected = id =>
    this.setState(state => {
      const selectedIndex = state.selected.indexOf(id);
      const currentSelection = [...state.selected];
      if (selectedIndex > -1) {
        currentSelection.splice(selectedIndex, 1);
      } else {
        currentSelection.push(id);
      }
      return {
        selected: currentSelection,
      };
    });
  render() {
    const entityId = this.props.match.params.id;
    const phaseId = this.props.match.params.phaseId;
    return (
      <div className="AapType">
        <h5>AUGALŲ APSAUGOS PRODUKTAI</h5>
        <Query variables={{ _id: entityId }} query={FARMERS_CULTURE_PHASES}>
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const aapPhase = data.farmersCultureById.culture.aapPhases.find(
              phase => phase._id === phaseId
            );
            return [
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={this.props.match.path.split('/')[1]}
              />,
              <div key="Augalai" className="AapType-nameTitle">
                Augalai: {data.farmersCultureById.culture.name}
              </div>,
              <div key="Augimo" className="AapType-nameTitle mb-4">
                Augimo tarpsnis: {aapPhase && aapPhase.name}
              </div>,
              <div key="description" className="AapType-description mb-1">
                Pasirinkite augalų apsaugos produktus kuriuos norite naudoti
                vienu metu šiame tarpsnyje:
              </div>,
              <Query
                key="aapTypesList"
                variables={{ culturePhaseCode: aapPhase.code }}
                query={AAP_MIXES_QUERY}
              >
                {mixesResponse => {
                  if (mixesResponse.loading) return <Loader />;
                  if (!mixesResponse.data || !mixesResponse.data.aapMixes) return <ErrorFetch />;

                  const {
                    categoriesAll,
                    availableMixes,
                  } = mixesResponse.data.aapMixes.reduce(
                    (acc, mix) => {
                      acc.categoriesAll.push(...mix.categoryCodes);
                      if (this.state.selected.length > 0) {
                        const uniqCodes = uniq(mix.categoryCodes);
                        const matches =
                          uniqCodes.length === this.state.selected.length &&
                          uniqCodes.reduce(
                            (ac, code) =>
                              ac && this.state.selected.includes(code),
                            true
                          );

                        if (matches) {
                          acc.availableMixes.push(mix);
                        }
                      }
                      return acc;
                    },
                    { categoriesAll: [], availableMixes: [] }
                  );

                  const availableCategories = uniq(categoriesAll);
                  const without =
                    mixesResponse.data.aapMixes.find(mix => mix.categoryCodes && mix.categoryCodes.length === 0);
                  const categoriesList = availableCategories.map(category => (
                    <div
                      key={category}
                      className={`tasklist-item ${this.state.selected.includes(
                        category
                      ) && 'tasklist-completed'}`}
                      onClick={() => this.toggleSelected(category)}
                    >
                      <i className="fa fa-check" />
                      <h5>{PRODUCT_CATEGORIES_LABELS[category]}</h5>
                    </div>
                  ));
                  const LinkComponent =
                    availableMixes.length === 0 ? Button : Link;
                  const buttons = (
                    <div className="mt-4 text-center" key="buttons">
                      {without ? <Link
                        to={`/grow-technology/aap/mixAdditionalCategories/${entityId}/${phaseId}/${without._id}`}
                        className="button button-warning mr-1"
                      >
                        Nenaudoti AAP
                      </Link> : null}
                      <LinkComponent
                        to={`/grow-technology/aap/mixOptions/${entityId}/${phaseId}/${this.state.selected.join('|')}`}
                        className="button button-success"
                        disabled={availableMixes.length === 0}
                      >
                        Galimi produktai ({availableMixes.length})
                      </LinkComponent>
                    </div>
                  );
                  return [
                    <div key="categoriesList" className="tasklist-container">{categoriesList}</div>,
                    buttons
                  ];
                }}
              </Query>,
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default AapType;
