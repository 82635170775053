import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo'
import numeral from "numeral";
import { CULTURE_OVERVIEW_QUERY, SEND_HTML_PROPOSAL } from "../../cultureOverview/queries/cultureOverview.graphql";
import { DELIVERY_OPTIONS } from '../../../grains/grains.constants';
import { UPDATE_FINANCIAL, FINANCIAL_DATA } from '../queries/financial.graphql';
import './FarmersCultureFinancial.css';
import connect from "react-redux/es/connect/connect";
import { CultureOverviewContent } from '../../cultureOverview/components/CultureOverview';
import { PROPOSAL_TYPES } from "../../../common/constants/proposal.constants";
import { FARMER_PROPOSALS, ALL_PROPOSALS } from "../../../proposals/queries/proposals.graphql";
import Input from "../../../common/components/Input/Input";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

const GrainsSelect = ({ options, selected, onSelect }) => {
  const selectHandler = event => onSelect(event.target.value);
  return (
    <select onChange={selectHandler} value={selected}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

class FarmersCultureFinancial extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  onAreaChange = event => this.setState({ area: event.target.value === '' ? '' : parseInt(event.target.value) });

  onWorkCostChange = event =>
    this.setState({ workCost: event.target.value === '' ? '' : parseInt(event.target.value) });

  onSubsidiesChange = event =>
    this.setState({ subsidies: event.target.value === '' ? '' : parseInt(event.target.value) });

  onYieldForecastChange = event =>
    this.setState({ yieldForecast: event.target.value === '' ? '' : parseInt(event.target.value) });

  onMatifChange = event =>
    this.setState({ matif: event.target.value === '' ? '' : parseInt(event.target.value) });

  onKmChange = event =>
    this.setState({ kilometers: event.target.value === '' ? '' : parseInt(event.target.value) });

  handleDeliveryPlace = deliveryPlace => this.setState({ deliveryPlace });

  handleElevatorSelect = elevatorId => this.setState({ elevatorId });

  handleCultureSelect = cultureSelected => this.setState({ cultureSelected });

  getSeeds = (seeds, culture) => {
    const { price, totalArea } = seeds.reduce((acc, seed) =>
        ({
          price: acc.price + ((culture === 'rapeseed') ?
            (seed.packsRequired * seed.price * seed.area) :
            (seed.rate * seed.area * seed.price / 1000)),
          totalArea: acc.totalArea + seed.area,
        }),
      { price: 0, totalArea: 0 }
    );
    return price / totalArea;
  };

  getFertilizers = fertilizers =>
    fertilizers.reduce((acc, fertilizer) => acc + (fertilizer.rate * fertilizer.price / 1000), 0);

  getDeduction = ({ deliveryPlace, elevator, culture, kilometers, perKm }) => {
    if (!culture || !elevator) return null;
    let deduction = culture.deduction;
    if (deliveryPlace === 'PORT') return deduction;
    const cultureVisibility = elevator.cultureVisibility.find(
      ({ cultureId }) => cultureId === culture._id
    );
    if (cultureVisibility && cultureVisibility.deduction) {
      deduction += cultureVisibility.deduction;
    }
    if (deliveryPlace === 'YARD_ELEVATOR') {
      deduction -= (kilometers) ?
        (parseFloat(kilometers) * perKm) : 0;
    }

    return deduction;
  };
// levas komentaras
  save = ({ updateFinancial, entityId, ...finances }) => {
    updateFinancial({ variables: { entityId, finances }}).then(() => {
      alert('Pakeitimai išsaugoti');
      this.props.history.goBack();
    });
  };

  sendProposal = ({ sendProposal, farmerId, cultureId, farmerCulture, finances }) => {
    // Steps to pass correct html string to proposals
    // 1. expand all content
    const closedRows = this.myRef.current.querySelectorAll('.ExpandableRow-mainRow.isClosed');
    [...closedRows].forEach(row => {
      row.click();
    });

    setTimeout(() => {
      const comments = prompt('Komentarai:');
      const domClone = this.myRef.current.cloneNode(true);
      // 2. disable all inputs
      const inputs = domClone.querySelectorAll('input, select');
      [...inputs].forEach(input => {
        input.disabled = true;
      });

      // 2. disable all inputs
      const buttons = domClone.querySelectorAll('button, .ExpandableRow-expandIcon');
      [...buttons].forEach(button => {
        button.remove();
      });

      const user = localStorage.getItem('user-basis-ui2');
      const userId = JSON.parse(user)._id;

      const html = domClone.outerHTML.replace('<h5 class="mt-4">', '<h5 class="ExpandableRow-title">');
      sendProposal({
        variables: {
          userId,
          farmerId,
          cultureId,
          comments,
          html,
          proposalType: PROPOSAL_TYPES.FINANCES,

        }
      }).then(() => alert('Pasūlymas išsiųstas'));
      alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    }, 300);
  };

  render () {
    const { params } = this.props.match;
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    return (
      <div className="FarmersCultureFinancial">
        <h5>FINANSINIS SKAIČIAVIMAS</h5>
        <div ref={this.myRef}>
          <Mutation
            mutation={UPDATE_FINANCIAL}
            refetchQueries={[
              {
                query: FINANCIAL_DATA,
                variables: {
                  userId: this.props.user._id,
                  entityId: params.entityId,
                  growTechCulture: params.culture,
                }
              },
              {
                query: CULTURE_OVERVIEW_QUERY,
                variables: {
                  farmerId: params.farmer,
                  cultureId: params.culture,
                  area: parseFloat(params.area),
                }
              }
            ]}
          >
            {updateFinancial => (
              <Query
                query={FINANCIAL_DATA}
                variables={{
                  userId: this.props.user._id,
                  entityId: params.entityId,
                  growTechCulture: params.culture,
                }}
              >
                {({ loading, data }) => {
                  if (loading) return <Loader />;
                  if (!data || !data.farmersCultureById) return <ErrorFetch />;

                  const { aapPrice, additionalPrice } = data.farmersCultureById.aap.reduce((acc, item) => {
                    const { aapPriceProduct, additionalProduct } = item.products.reduce((accu, product) => {
                      const pricePerHa = product.quantity * product.price;
                      const productType = product.isAdditional ? 'additionalProduct' : 'aapPriceProduct';
                      return { ...accu, [productType]: accu[productType] + pricePerHa};
                    }, {aapPriceProduct: 0, additionalProduct: 0});
                    return {
                      aapPrice: acc.aapPrice + aapPriceProduct,
                      additionalPrice: acc.additionalPrice + additionalProduct
                    };
                  }, { aapPrice: 0, additionalPrice: 0 });

                  const finances = data.farmersCultureById.finances || {};

                  const area = (this.state.area || this.state.area === '') ? this.state.area :
                    (finances && finances.area) || data.farmersCultureById.area;

                  const cultureSelected = this.state.cultureSelected ||
                    finances.cultureSelected ||
                    data.calculatorCultures[0]._id;
                  const culture = data.calculatorCultures.find(({ _id }) => _id === cultureSelected);
                  const seeds = this.getSeeds(data.farmersCultureById.seeds, culture.type);
                  const fertilizers = this.getFertilizers(data.farmersCultureById.fertilizers);
                  const workCost = (this.state.workCost || this.state.workCost === '') ? this.state.workCost :
                    finances && finances.workCost;
                  const subsidies = (this.state.subsidies || this.state.subsidies === '') ? this.state.subsidies :
                    finances && finances.subsidies;
                  const yieldForecast = (this.state.yieldForecast || this.state.yieldForecast === '') ?
                    this.state.yieldForecast :
                    finances && finances.yieldForecast;
                  const deliveryPlace = this.state.deliveryPlace ||
                    (finances && finances.deliveryPlace) || 'YARD_ELEVATOR';
                  const elevatorId = this.state.elevatorId ||
                    (finances && finances.elevatorId) || (data.user.elevators[0] && data.user.elevators[0]._id) || null;
                  const kilometers = (this.state.kilometers || this.state.kilometers === '') ? this.state.kilometers :
                    finances && finances.kilometers;
                  const elevator = !this.state.elevatorId ? data.user.elevators[0] :
                    data.user.elevators.find(({ _id }) => _id === this.state.elevatorId);
                  const seedsPrices = data.calculatorValues.seedsPrices.find(item => item.code === culture.type);
                  const matif = (this.state.matif || this.state.matif === '') ? this.state.matif :
                    (finances && finances.matif) ||
                    (seedsPrices && seedsPrices.price);
                  const deduction = this.getDeduction({
                    deliveryPlace,
                    elevator,
                    culture,
                    kilometers,
                    perKm: data.calculatorValues.transportationPrice.perKm
                  });
                  const totalCost = seeds + fertilizers + aapPrice + additionalPrice + (workCost || 0);

                  return [
                      <table className="AapSummaryDate">
                        <tr>
                          <td className="AapSummaryDate-title">Plotas</td>
                          <td className="AapSummaryDate-inputCell">
                            <Input
                              type="text"
                              className="AapSummaryDate-input"
                              value={area}
                              onChange={this.onAreaChange}
                            />
                          </td>
                        </tr>
                      </table>,
                      <CultureOverviewContent
                        key="CultureOverviewContent"
                        farmersCulture={data.farmersCultureById}
                        farmerId={data.farmersCultureById.farmer._id}
                        cultureId={data.farmersCultureById.culture._id}
                        area={parseFloat(data.farmersCultureById.area)}
                        maxArea={area}
                        hideSeedsArea
                      />,
                      <h5 className="mt-4">Sąnaudų suvestinė</h5>,
                      <table className="table">
                        <tbody>
                          <tr>
                            <td><b>Sąnaudos</b></td>
                            <td className="FarmersCultureFinancial-valueCell"><b>Kaina 1ha</b></td>
                            <td className="FarmersCultureFinancial-valueCell"><b>Viso (Eur)</b></td>
                          </tr>
                          <tr>
                            <td>Sėjos sąnaudos</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(seeds).format('0.[00]')}</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(seeds * area).format('0.[00]')}</td>
                          </tr>
                          <tr>
                            <td>Trešimo sąnaudos</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(fertilizers).format('0.[00]')}</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(fertilizers * area).format('0.[00]')}</td>
                          </tr>
                          <tr>
                            <td>AAP produktų sąnaudos</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(aapPrice).format('0.[00]')}</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(aapPrice * area).format('0.[00]')}</td>
                          </tr>
                          <tr>
                            <td>Sąnaudos papildomam trešimui</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(additionalPrice).format('0.[00]')}</td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(additionalPrice * area).format('0.[00]')}</td>
                          </tr>
                          <tr>
                            <td>Darbo sąnaudos</td>
                            <td className="FarmersCultureFinancial-valueCell">
                              <Input
                                type="number"
                                className="FarmersCultureFinancial-input"
                                value={workCost}
                                onChange={this.onWorkCostChange}
                              />
                            </td>
                            <td className="FarmersCultureFinancial-valueCell">
                              {numeral(workCost * area).format('0.[00]')}
                            </td>
                          </tr>
                          <tr>
                            <td className="table-summaryRow">Bendra kaina:</td>
                            <td className="table-summaryRow FarmersCultureFinancial-valueCell">
                              {numeral(totalCost).format('0.[00]')}
                              </td>
                            <td className="table-summaryRow FarmersCultureFinancial-valueCell">
                              {numeral(totalCost * area).format('0.[00]')}
                              </td>
                          </tr>
                        </tbody>
                      </table>,
                      <h5>Išmokų suvestinė</h5>,
                      <table className="table">
                        <tbody>
                        <tr>
                          <td><b>Išmokos</b></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>Hektarui</b></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>Viso (Eur)</b></td>
                        </tr>
                        <tr>
                          <td>Subsidijos iš valstybės</td>
                          <td className="FarmersCultureFinancial-valueCell">
                            <Input
                              type="number"
                              className="FarmersCultureFinancial-input"
                              value={subsidies}
                              onChange={this.onSubsidiesChange}
                            />
                          </td>
                          <td className="FarmersCultureFinancial-valueCell">{numeral(subsidies * area).format('0.[00]')}</td>
                        </tr>
                        </tbody>
                      </table>,
                      <h5>Pajamų suvestinė</h5>,
                      <table className="table">
                        <tbody>
                          <tr>
                            <td><b>Pajamos</b></td>
                            <td className="FarmersCultureFinancial-valueCell"><b>Hektarui</b></td>
                            <td className="FarmersCultureFinancial-valueCell"><b>Viso</b></td>
                          </tr>
                          <tr>
                            <td>Prognozojamas derlius (t)</td>
                            <td className="FarmersCultureFinancial-valueCell">
                              <Input
                                type="number"
                                className="FarmersCultureFinancial-input"
                                value={yieldForecast}
                                onChange={this.onYieldForecastChange}
                              />
                            </td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(yieldForecast * area).format('0.[00]')}</td>
                          </tr>
                          <tr>
                            <td>MATIF</td>
                            <td className="FarmersCultureFinancial-valueCell">
                              <Input
                                type="number"
                                className="FarmersCultureFinancial-input"
                                value={matif}
                                onChange={this.onMatifChange}
                              />
                            </td>
                            <td className="FarmersCultureFinancial-valueCell">{numeral(matif * area).format('0.[00]')}</td>
                          </tr>

                      </tbody>
                    </table>,
                    <div className="FarmersCultureFinancial-deliveryContainer mt-3">
                      <GrainsSelect
                        options={DELIVERY_OPTIONS}
                        selected={deliveryPlace}
                        onSelect={this.handleDeliveryPlace}
                      />
                      {(deliveryPlace === 'PORT') ? null : [
                        <div className="mt-2" />,
                        <GrainsSelect
                          options={data.user.elevators.filter(item => !!item).map(({ name, _id }) => ({ name, value: _id }))}
                          selected={elevatorId}
                          onSelect={this.handleElevatorSelect}
                        />
                      ]}
                      {(deliveryPlace === 'YARD_ELEVATOR') ? (
                        <div className="mt-2">
                          Kilometrai:&nbsp;
                          <Input
                            type="number"
                            className="FarmersCultureFinancial-input"
                            value={kilometers}
                            onChange={this.onKmChange}
                          />
                        </div>
                      ) : null}
                    </div>,
                    <table className="table mt-3">
                      <tbody>
                        <tr>
                          <td></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>Nuoskaita</b></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>Eur/t</b></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>1 ha/eur</b></td>
                          <td className="FarmersCultureFinancial-valueCell"><b>Viso</b></td>
                        </tr>

                        {data.calculatorCultures.map(culture => {
                          const lineDeduction = this.getDeduction({
                            deliveryPlace,
                            elevator,
                            culture,
                            kilometers,
                            perKm: data.calculatorValues.transportationPrice.perKm
                          });
                          const isSelected = cultureSelected === culture._id;
                          return (
                            <tr className={isSelected ? 'FarmersCultureFinancial-cultureSelected' : ''}>
                              <td onClick={() => this.handleCultureSelect(culture._id)}>
                                {isSelected ? '> ' : ''}{culture.name}
                              </td>
                              <td className={`FarmersCultureFinancial-valueCell ${lineDeduction < 0 ? 'negativeDeduction' : ''}`}>
                                {numeral(lineDeduction).format('0.[00]')}
                              </td>
                              <td className="FarmersCultureFinancial-valueCell">
                                {numeral(matif + lineDeduction).format('0.[00]')}
                              </td>
                              <td className="FarmersCultureFinancial-valueCell">
                                {numeral((matif + lineDeduction) * yieldForecast).format('0.[00]')}
                              </td>
                              <td className="FarmersCultureFinancial-valueCell">
                                {numeral((matif + lineDeduction) * yieldForecast * area).format('0.[00]')}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td className="table-summaryRow"colSpan="3">
                            Grūdų pardavimo kaina:
                          </td>
                          <td className="table-summaryRow FarmersCultureFinancial-valueCell">
                            {numeral((matif + deduction) * yieldForecast).format('0.[00]')}
                          </td>
                          <td className="table-summaryRow FarmersCultureFinancial-valueCell">
                            {numeral((matif + deduction) * yieldForecast * area).format('0.[00]')}
                          </td>
                        </tr>
                        </tbody>
                      </table>,
                      <h5>Planuojamos ūkininko pajamos</h5>,
                      <div className="FarmersCultureFinancial-inputSummaryContainer">
                        <Input
                          className="text-field FarmersCultureFinancial-inputSummary"
                          value={numeral(((matif + deduction) * yieldForecast + subsidies) * area).format('0,0').replace(',', ' ') + ' Eur'}
                          disabled
                        />
                      </div>,
                      <h5>Ūkininko išlaidos</h5>,
                      <div className="FarmersCultureFinancial-inputSummaryContainer">
                        <Input
                          className="text-field FarmersCultureFinancial-inputSummary"
                          value={numeral(totalCost * area).format('0,0').replace(',', ' ') + ' Eur'}
                          disabled
                        />
                      </div>,
                      <h5>Planuojamas ūkininko uždarbis</h5>,
                      <div className="FarmersCultureFinancial-inputSummaryContainer">
                        <Input
                          className="text-field FarmersCultureFinancial-inputSummary"
                          value={numeral(((matif + deduction) * yieldForecast + subsidies - totalCost) * area).format('0,0').replace(',', ' ') + ' Eur'}
                          disabled
                        />
                      </div>,
                      <div>
                        <button
                          className="button button-success"
                          onClick={() => this.save({
                            updateFinancial,
                            entityId: data.farmersCultureById._id,
                            area,
                            workCost,
                            subsidies,
                            yieldForecast,
                            matif,
                            deliveryPlace,
                            elevatorId,
                            kilometers,
                            cultureSelected,
                            profitPerHa: (matif + deduction) * yieldForecast + subsidies
                          })}
                        >
                          Išsaugoti
                        </button>
                        <Mutation
                          mutation={SEND_HTML_PROPOSAL}
                          refetchQueries={[
                            {
                              query: FARMER_PROPOSALS,
                              variables: { farmerId: data.farmersCultureById.farmer._id }
                            },
                            {
                              query: ALL_PROPOSALS,
                              variables: { userId }
                            }
                          ]}
                        >
                          {sendProposal => (
                            <button
                              className="button button-warning"
                              onClick={() =>this.sendProposal({
                                sendProposal,
                                farmerId: data.farmersCultureById.farmer._id,
                                cultureId: data.farmersCultureById.culture._id,
                                farmerCulture: data.farmersCultureById,
                                finances: {
                                  area,
                                  workCost,
                                  subsidies,
                                  yieldForecast,
                                  matif,
                                  deduction,
                                  deliveryPlace,
                                  elevatorId:
                                    elevatorId ? data.user.elevators.find(item => item._id === elevatorId).name : null,
                                  kilometers,
                                  cultureSelected:
                                    data.calculatorCultures.find(item => item._id === cultureSelected).name,
                                  profitPerHa: (matif + deduction) * yieldForecast + subsidies
                                }
                              })}
                            >
                              Išsiųsti pasiūlymą
                            </button>
                          )}
                        </Mutation>
                      </div>
                    ]
                  }
                }
              </Query>)
            }
          </Mutation>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(FarmersCultureFinancial);
