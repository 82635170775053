import React, { PureComponent } from 'react';
import { Mutation, Query } from "react-apollo";
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import SeedCompare from './SeedCompare';
import SeedSelected from './SeedSelected';
import { FARMERS_CULTURE_FOR_SEEDS } from '../queries/seeds.graphql';
import { SEND_HTML_PROPOSAL } from "../../cultureOverview/queries/cultureOverview.graphql";
import { PROPOSAL_TYPES } from "../../../common/constants/proposal.constants";
import { FARMER_PROPOSALS, ALL_PROPOSALS } from "../../../proposals/queries/proposals.graphql";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class Seeds extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  backHandler = () => {
    if (this.props.location.pathname !== '/') {
      this.props.history.goBack();
    }
  };

  sendProposal = ({ sendProposal, farmerId, cultureId, farmerCulture }) => {
    const comments = prompt('Komentarai:');
    const domClone = this.myRef.current.cloneNode(true);
    // 2. disable all inputs
    const inputs = domClone.querySelectorAll('input, select');
    [...inputs].forEach(input => {
      input.disabled = true;
    });

    // 2. disable all inputs
    const buttons = domClone.querySelectorAll('button, a');
    [...buttons].forEach(button => {
      button.remove();
    });

    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;

    sendProposal({
      variables: {
        userId,
        farmerId,
        cultureId,
        comments,
        html: domClone.outerHTML,
        proposalType: PROPOSAL_TYPES.SEEDS,

      }
    }).then(() => alert('Pasūlymas išsiųstas'));
    alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    return;

    // const comments = prompt('Komentarai:');
    //
    // const proposedCultures = {
    //   name: farmerCulture.culture.name,
    //   cultureCode: farmerCulture.culture.code,
    //   area:  farmerCulture.area,
    //   seeds: farmerCulture.seeds.map(({ variety, rate, area, price, packsRequired}) => ({
    //     varietyName: variety.name,
    //     rate: parseFloat(rate),
    //     price: parseFloat(price),
    //     area: parseFloat(area),
    //     packsRequired
    //   })),
    // };
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     cultureId,
    //     comments,
    //     proposedCultures,
    //     proposalType: PROPOSAL_TYPES.SEEDS,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;
    return (
      <div className="CultureOverview">
        <h5>SĖJOS PLANAVIMAS</h5>
        <Query
          variables={{ _id: this.props.match.params.id }}
          query={FARMERS_CULTURE_FOR_SEEDS}
        >
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const areaUsed = data.farmersCultureById.seeds.reduce(
              (acc, seed) => acc + seed.area,
              0
            );
            return [
              <h4 key="cultureName">{data.farmersCultureById.culture.name}</h4>,
              <FarmersCards
                key="FarmersCards"
                farmer={data.farmersCultureById.farmer}
                pathRoot={this.props.match.path.split('/')[1]}
              />,
              <div ref={this.myRef} key="SeedSelectedContainer">
                <h4>Sėklų pasirinkimai</h4>
                <SeedSelected
                  key="SeedSelected"
                  entityId={data.farmersCultureById._id}
                  seeds={data.farmersCultureById.seeds}
                  cultureCode={data.farmersCultureById.culture.code}
                  totalArea={data.farmersCultureById.area}
                  editable
                />
              </div>,
              <div key="buttons">
                <button className="button button-success" onClick={this.backHandler}>
                  IŠSAUGOTI IR GRĮŽTI
                </button>
                <Mutation
                  mutation={SEND_HTML_PROPOSAL}
                  refetchQueries={[
                    {
                      query: FARMER_PROPOSALS,
                      variables: { farmerId: data.farmersCultureById.farmer._id }
                    },
                    {
                      query: ALL_PROPOSALS,
                      variables: { userId }
                    }
                  ]}
                >
                  {sendProposal => (
                    <button
                      className="button button-warning"
                      onClick={() =>this.sendProposal({
                        sendProposal,
                        farmerId: data.farmersCultureById.farmer._id,
                        cultureId: data.farmersCultureById.culture._id,
                        farmerCulture: data.farmersCultureById,
                      })}
                    >
                      IŠSIŲSTI PASIŪLYMĄ
                    </button>
                  )}
                </Mutation>
              </div>,
              <SeedCompare
                key="SeedCompare"
                entityId={data.farmersCultureById._id}
                cultureId={data.farmersCultureById.culture._id}
                cultureCode={data.farmersCultureById.culture.code}
                selectedSeeds={data.farmersCultureById.seeds.map(item => item.variety._id)}
                area={data.farmersCultureById.area - areaUsed}
              />,
            ];
          }}
        </Query>
      </div>
    );
  }
}

export default Seeds;
