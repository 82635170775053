import React, { Component } from 'react';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Input from "../../common/components/Input/Input";

const CattleProductsRow = ({ product, animalCount, onSelect }) => {
  const handleSelect = () => onSelect(product);
  return (
    <tr>
      <td className="text-left">
        {product.name}
      </td>
      <td>
        {product.norm} ({product.measureUnit})
      </td>
      <td>
        {product.rate}
      </td>
      <td>
        {numeral(product.norm * animalCount).format('0.[00]')}
      </td>
      <td onClick={handleSelect}>
        <FontAwesomeIcon icon={faShoppingCart} />
      </td>
    </tr>
  )
};

class CattleProductsTable extends Component {
  state = { filter: '' };

  handleSearchChange = ({ target }) => this.setState({ filter: target.value });

  render() {
    const { products, selected, animalCount, onSelect } = this.props;
    const { filter } = this.state;
    const filtered = products.filter(product =>
      !selected.includes(product._id) &&
        (filter === '' || product.name.toLowerCase().includes(filter.toLowerCase()))
    );

    return [
      <h5>Prekių sąrašas</h5>,
      <div className="flex">
        <Input
          type="text"
          className="text-field flex-grow"
          value={filter}
          placeholder="Prekių paieška"
          onChange={this.handleSearchChange}
        />
      </div>,
      <table className="table" align="center">
        <tbody>
          <tr>
            <td className="text-left">
              <strong>Prekė</strong>
            </td>
            <td>
              <strong>Norma</strong>
            </td>
            <td>
              <strong>Kaina Eur/t</strong>
            </td>
            <td>
              <strong>Kiekis</strong>
            </td>
            <td />
          </tr>
          {filtered.map(product => (
            <CattleProductsRow
              product={product}
              animalCount={animalCount}
              onSelect={onSelect}
            />
          ))}
        </tbody>
      </table>
    ]
  }
}

export default CattleProductsTable;
