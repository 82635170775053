export const STOCK_TYPES = [
  {
    type: 'seeds',
    label: 'Sėklos',
  },
  {
    type: 'fertilizer',
    label: 'Tąšos'
  },
  {
    type: 'aap',
    label: 'AAP'
  },
  {
    type: 'additional',
    label: 'Spec. Trąšos'
  },
  {
    type: 'cattle',
    label: 'Gyvulininkystės prod.'
  },
];

export const STOCK_TYPES_LIST = STOCK_TYPES.map(item => item.type);

export const STOCK_TYPE_LABELS = STOCK_TYPES.reduce(
  (acc, item) => ({ ...acc, [item.type]: item.label}),
  {}
);
