import gql from 'graphql-tag';

export const FARMERS_CULTURE_FOR_FERTILIZER = gql`
  query FarmersCultureForFertilizer($_id: String!) {
    farmersCultureById(_id: $_id) {
      _id
      area
      culture {
        _id
        name
        code
        type
        yields {
          amount
          NPKS {
            N
            K
            P
            S
          }
        }
      }
      farmer {
        _id
      }
      fertilizers {
        fertilizer {
          _id
          name
          acuracy
          NPKS {
            N
            K
            P
            S
          }
        }
        rate
        price
        isSurface
        selectedYield
      }
    }
  }
`;

export const FERTILIZERS_QUERY = gql`
  query Fertilizers($seedsType: String, $type: String) {
    fertilizers(seedsType: $seedsType, type: $type) {
      _id
      name
      type
      price
      acuracy
      descriptionURL
      NPKS {
        N
        P
        K
        S
      }
    }
  }
`;

export const FERTILIZER_REMOVE_MUTATION = gql`
  mutation RemoveFarmersCultureFertilizer($entityId: String!, $index: Int!) {
    removeFarmersCultureFertilizer(entityId: $entityId, index: $index) {
      _id
    }
  }
`;

export const FERTILIZER_UPDATE_MUTATION = gql`
  mutation UpdateFertilizerRate(
    $entityId: String!
    $index: Int!
    $rate: Int
    $price: Int
  ) {
    updateFertilizerRate(
      entityId: $entityId
      index: $index
      rate: $rate
      price: $price
    ) {
      _id
    }
  }
`;

export const ADD_FERTILIZER = gql`
  mutation UpdateFarmersCultureFertilizer(
    $entityId: String!
    $fertilizer: inCultureFertilizer!
  ) {
    updateFarmersCultureFertilizer(
      entityId: $entityId
      fertilizer: $fertilizer
    ) {
      _id
    }
  }
`;

// export const FERTILIZER_SEND_PROPOSAL_MUTATION = gql`
//   mutation SendProposal(
//     $userId: String!
//     $farmerId: String!
//     $comments: String
//     $proposedCultures: [inProposedCulture]
//   ) {
//     emailProposal(
//       userId: $userId
//       farmerId: $farmerId
//       comments: $comments
//       proposedCultures: $proposedCultures
//     ) {
//       status
//     }
//   }
// `;

export default {
  FERTILIZERS_QUERY,
  FERTILIZER_REMOVE_MUTATION,
  FERTILIZER_UPDATE_MUTATION,
  FARMERS_CULTURE_FOR_FERTILIZER,
};
