import React from 'react';

const Calendar = () => (
  <iframe
    title='calendar'
    src="https://calendar.google.com/calendar/embed?showNav=0&amp;showPrint=0&amp;showCalendars=0&amp;height=600&amp;wkst=2&amp;hl=lt&amp;bgcolor=%23FFFFFF&amp;src=bakalendorius%40gmail.com&amp;color=%231B887A&amp;ctz=Europe%2FVilnius&mode=AGENDA"
    style={{ borderWidth: 0 }}
    width="100%"
    height="600"
    frameBorder="0"
    scrolling="no"
  />
);

export default Calendar;

