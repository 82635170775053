import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import numeral from 'numeral';
import FontAwesome from "react-fontawesome";
import {
  AAP_PRODUCTS, FARMERS_CULTURE_FOR_AAP,
  FARMERS_CULTURE_PHASES,
  INSERT_AAP_RECORD
} from "../queries/aap.graphql";
import FarmersCards from '../../farmers/components/FarmersCard/FarmersCard';
import './AapPhases.css';
import { CULTURE_OVERVIEW_QUERY, SEND_HTML_PROPOSAL } from "../../cultureOverview/queries/cultureOverview.graphql";
import SelectComponent from "../../../common/components/SelectComponent/SelectComponent";
import { PROPOSAL_TYPES } from "../../../common/constants/proposal.constants";
import Input from "../../../common/components/Input/Input";
import Loader from "../../../common/components/Loader/Loader";
import ErrorFetch from "../../../common/components/Error/ErrorFetch";

class AapAddPro extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    date: moment().format('YYYY-MM-DD'),
    selectedProducts: [],
    selectedProductIds: [],
    productTypeSelect: '--',
    productCategorySelect: null,
    searchText: ''
  };

  dateChangeHandler = event => {
    this.setState({ date: event.target.value });
  };

  onAreaChange = event => this.setState({ area: event.target.value === '' ? '' : parseInt(event.target.value) });

  onProductTypeSelect = type => this.setState({
    productTypeSelect: type,
    productCategorySelect: null
  });

  onProductCategorySelect = category => this.setState({
    productCategorySelect: category
  });

  addProduct = product => this.setState(state => ({
    selectedProducts: [
      ...state.selectedProducts,
      { ...product }
    ],
    selectedProductIds: [ ...state.selectedProductIds, product._id ],
  }));

  removeProduct = index => this.setState(state => ({
    selectedProducts: state.selectedProducts.filter((item, itemIndex) => itemIndex !== index),
    selectedProductIds: state.selectedProductIds.filter((item, itemIndex) => itemIndex !== index),
  }));

  onValueChange = (index, value) => this.setState(state => {
    const selectedProducts = [ ...state.selectedProducts ];
    selectedProducts[index].value = value;
    return { selectedProducts };
  });

  onPriceChange = (index, price) => this.setState(state => {
    const selectedProducts = [ ...state.selectedProducts ];
    selectedProducts[index].price = price;
    return { selectedProducts };
  });

  onSearchTextChange = event =>
    this.setState({ searchText: event.target.value });

  save = ({ area, totalArea, entityId, phaseId, cultureCode, farmerId, insertAapRecord }) => {
    const products = this.state.selectedProducts.map(product => ({
      productCode: product.isAdditional ? product._id : product.code,
      quantity: parseFloat(product.value),
      measureUnit: product.measureUnit,
      price: parseFloat(product.price),
      isAdditional: product.isAdditional
    }));

    insertAapRecord({ variables: {
        entityId,
        record: {
          date: this.state.date,
          phaseCode: phaseId,
          cultureCode,
          area: parseFloat(area),
          products
        }
      }}).then(({ data }) => {
        alert('Pakeitimai išsaugoti');
        setTimeout(() => {
          this.props.history.replace(
            `/grow-technology/culture-overview/${cultureCode}/${farmerId}/${totalArea}`
          );
        }, 700);
      });
  };

  sendProposal = ({ sendProposal, farmerId, cultureId, farmerCulture, area, phaseName }) => {
    const comments = prompt('Komentarai:');
    const domClone = this.myRef.current.cloneNode(true);
    // 2. disable all inputs
    const inputs = domClone.querySelectorAll('input, select');
    [...inputs].forEach(input => {
      input.disabled = true;
    });

    // 2. disable all inputs
    const buttons = domClone.querySelectorAll('button, a');
    [...buttons].forEach(button => {
      button.remove();
    });

    const user = localStorage.getItem('user-basis-ui2');
    const userId = JSON.parse(user)._id;

    sendProposal({
      variables: {
        userId,
        farmerId,
        cultureId,
        comments,
        html: domClone.outerHTML,
        proposalType: PROPOSAL_TYPES.AAP,

      }
    }).then(() => alert('Pasūlymas išsiųstas'));
    alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
    return;

    //
    // const comments = prompt('Komentarai:');
    // const products = this.state.selectedProducts.map(product => ({
    //   name: product.isAdditional ? product._id : product.code,
    //   rate: parseFloat(product.value),
    //   measureUnit: product.measureUnit,
    //   price: parseFloat(product.price),
    //   isAdditional: product.isAdditional
    // }));
    // const proposedCultures = {
    //   name: farmerCulture.culture.name,
    //   cultureCode: farmerCulture.culture.code,
    //   area,
    //   aaps: [{
    //     phaseName,
    //     date: this.state.date,
    //     area,
    //     products,
    //   }],
    // };
    //
    // const user = localStorage.getItem('user-basis-ui2');
    // const userId = JSON.parse(user)._id;
    //
    // sendProposal({
    //   variables: {
    //     userId,
    //     farmerId,
    //     cultureId,
    //     comments,
    //     proposedCultures,
    //     proposalType: PROPOSAL_TYPES.AAP,
    //
    //   }
    // }).then(() => alert('Pasūlymas išsiųstas'));
    // alert('Pasiūlimas formuojamas. Apie jo išsiuntima informuosime');
  };

  render() {
    const entityId = this.props.match.params.id;
    const { phaseId, phaseCode } = this.props.match.params;
    return (
      <div className="CultureOverview">
        <h5>AAP MAIŠYMO VARIANTAI</h5>
        <Query
          variables={{ _id: entityId }}
          query={FARMERS_CULTURE_PHASES}
        >
          {({ loading, data }) => {
            if (loading) return <Loader />;
            if (!data || !data.farmersCultureById) return <ErrorFetch />;

            const aapPhase = data.farmersCultureById.culture.aapPhases.find(
              phase => phase._id === phaseId
            );
            const cultureId = data.farmersCultureById.culture._id;
            const area = this.state.area ||
              this.state.area === '' ? this.state.area : data.farmersCultureById.aapAvailableArea;

            const totalPrice = this.state.selectedProducts.reduce((acc, product) =>
              acc + (area * product.price * product.value),
              0
            );
            const isBeicas = aapPhase.name === 'Beicavimas';
            return  (
              <Mutation
                key="INSERT_AAP_RECORD"
                mutation={INSERT_AAP_RECORD}
                refetchQueries={[{
                  variables: { _id: entityId },
                  query: FARMERS_CULTURE_FOR_AAP
                }, {
                  query: CULTURE_OVERVIEW_QUERY,
                  variables: {
                    farmerId: data.farmersCultureById.farmer._id,
                    cultureId: data.farmersCultureById.culture.code,
                    area: data.farmersCultureById.area,
                  }
                }]}
              >
                {insertAapRecord => [
                  <FarmersCards
                    key="FarmersCards"
                    farmer={data.farmersCultureById.farmer}
                    pathRoot={this.props.match.path.split('/')[1]}
                  />,
                  <div ref={this.myRef} key="aapProContent">
                    <div key="Augalai" className="AapType-nameTitle">
                      Augalai: {data.farmersCultureById.culture.name}
                    </div>
                    <div key="Augimo" className="AapType-nameTitle">
                      Augimo tarpsnis: {aapPhase && aapPhase.name}
                    </div>
                    <table className="AapSummaryDate">
                      <tbody>
                        <tr>
                          <td className="AapSummaryDate-title">Rekomenduojama {isBeicas ? 'beicavimo' : 'purškimo'} data</td>
                          <td className="AapSummaryDate-inputCell">
                            <Input
                              type="text"
                              className="AapSummaryDate-input"
                              value={this.state.date}
                              onChange={this.dateChangeHandler}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="AapSummaryDate-title">
                            {isBeicas ? 'Kiekis' : 'Plotas'}
                          </td>
                          <td className="AapSummaryDate-inputCell">
                            <Input
                              type="text"
                              className="AapSummaryDate-input"
                              value={area}
                              onChange={this.onAreaChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="AapAddSummary-table">
                      <tbody>
                        <tr>
                          <td className="AapAddSummary-table-rowTitle"><b>Produktas</b></td>
                          <td><b>Norma (kg/l/ha)</b></td>
                          <td><b>Kiekis</b></td>
                          <td><b>Kaina (Eur)</b></td>
                          <td><b>Viso (Eur)</b></td>
                          <td className="AapAddSummary-table-removeColumn"></td>
                        </tr>
                        {this.state.selectedProducts.map((product, index) => (
                            <tr key={product._id}>
                              <td className="AapAddSummary-table-rowTitle">{product.name}</td>
                              <td>
                                <Input
                                  className="AapAddSummary-tableInput"
                                  value={product.value}
                                  type="number"
                                  onChange={event => this.onValueChange(index, event.target.value)}
                                />
                                {isBeicas ? ' l/t' : product.measureUnit}
                              </td>
                              <td>{numeral(product.value * area).format('0.[00]')}</td>
                              <td>
                                <Input
                                  className="AapAddSummary-tableInput"
                                  value={product.price}
                                  type="number"
                                  onChange={event => this.onPriceChange(index, event.target.value)}
                                />
                              </td>
                              <td>{numeral(product.value * area * product.price).format('0.[00]')}</td>
                              <td className="AapAddSummary-table-removeColumn" onClick={() => this.removeProduct(index)}>
                                <FontAwesome name="trash-o" />
                              </td>
                            </tr>
                          ))
                        }
                        <tr>
                          <td className="table-summaryRow text-left pl-2" colSpan="4">Viso:</td>
                          <td className="table-summaryRow">{numeral(totalPrice).format('0.[00]')}</td>
                          <td className="table-summaryRow" />
                        </tr>
                      </tbody>
                    </table>
                  </div>,

                  <div key="aapProButtons">
                    <button
                      className="button button-success"
                      onClick={() => this.save({
                        area,
                        entityId,
                        phaseId,
                        farmerId: data.farmersCultureById.farmer._id,
                        cultureCode: data.farmersCultureById.culture.code,
                        totalArea: data.farmersCultureById.area,
                        insertAapRecord
                      })}
                    >
                      Išsaugoti
                    </button>
                    <Mutation
                      mutation={SEND_HTML_PROPOSAL}
                    >
                      {sendProposal => (
                        <button
                          className="button button-warning"
                          onClick={() =>this.sendProposal({
                            sendProposal,
                            farmerId: data.farmersCultureById.farmer._id,
                            cultureId: data.farmersCultureById.culture._id,
                            farmerCulture: data.farmersCultureById,
                            area,
                            phaseName: aapPhase.name,
                          })}
                        >
                          Išsiųsti pasiūlymą
                        </button>
                      )}
                    </Mutation>
                  </div>,
                  <div key="ListTitle" className="AapType-nameTitle">
                    Prekių sąrašas
                  </div>,
                  <SelectComponent
                    key="productTypeSelect"
                    options={[{
                        value: '--',
                        label: 'Visi produktai',
                      },{
                        value: 'products',
                        label: 'AAP produktai',
                      },
                      {
                        value: 'additional',
                        label: 'Papildomas trešimo produktas',
                      }]}
                    selected={this.state.productTypeSelect}
                    onSelect={this.onProductTypeSelect}
                  />,
                  <Query key={`AAP_PRODUCTS${cultureId}`} query={AAP_PRODUCTS} variables={{ cultureId }}>
                    {({ loading, data }) => {
                      if (loading) return <Loader />;
                      if (!data || !data.aapProducts) return <ErrorFetch />;

                      const additionalOptionSelected = this.state.productTypeSelect === 'additional';
                      const products = this.state.productTypeSelect === '--' ?
                        [ ...data['aapProducts'], ...data['additionalFertilizers'].map(item => ({ ...item, isAdditional: true })) ] :
                        data[this.state.productTypeSelect === 'products' ? 'aapProducts' : 'additionalFertilizers']
                          .map(item => additionalOptionSelected ? ({ ...item, isAdditional: true }) : item);
                      const { categoryOptions, list } = products.reduce((acc, item) => {
                        if (!acc.list.includes(item.categoryCode)) {
                          acc.list.push(item.categoryCode);
                          acc.categoryOptions.push({
                            value: item.categoryCode,
                            label: item.category.name
                          })
                        }
                        return acc;
                      }, { categoryOptions: [], list: [] });
                      categoryOptions.unshift({
                        value: '--',
                        label: 'Pasirinkite produkto grupę'
                      });
                      list.unshift('--');
                      const selectedOption = (list.includes(this.state.productCategorySelect)) ?
                        this.state.productCategorySelect : list[0];

                      return [
                        <SelectComponent
                          key="productCategorySelect"
                          options={categoryOptions}
                          selected={selectedOption}
                          onSelect={this.onProductCategorySelect}
                        />,
                        <Input
                          key="searchInput"
                          type="text"
                          placeholder="Paieška"
                          className="text-field"
                          value={this.state.searchText}
                          onChange={this.onSearchTextChange}
                        />,
                        <table className="AapAddSummary-table">
                          <tbody>
                          <tr>
                            <td className="AapAddSummary-table-rowTitle"><b>Prekė</b></td>
                            <td><b>Norma</b></td>
                            <td><b>Kaina Eur</b></td>
                            <td><b>Kiekis</b></td>
                            <td>Pasirinkt</td>
                          </tr>
                          {products
                            .filter(product =>
                              (selectedOption === '--' || product.categoryCode === selectedOption) &&
                              product.phaseIds.includes(phaseCode) &&
                              !this.state.selectedProductIds.includes(product._id) &&
                              (
                                this.state.searchText === '' ||
                                (new RegExp(this.state.searchText, 'i')).test(product.name)
                              )
                            ).map(product => (
                              <tr key={product._id}>
                                <td className="AapAddSummary-table-rowTitle">{product.name}</td>
                                <td>{product.value}</td>
                                <td>{product.price}</td>
                                <td>{numeral(product.value * area).format('0.00')}</td>
                                <td onClick={() => this.addProduct(product)}>
                                  <FontAwesome name="shopping-cart" />
                                </td>
                              </tr>
                            ))
                          }
                          </tbody>
                        </table>
                      ];
                    }}
                  </Query>
                ]}
              </Mutation>
            )
          }}
        </Query>
      </div>
    );
  }
}

export default AapAddPro;
