import React from 'react';
import ProposalsList from './ProposalsList';

const Proposals = () => (
  <div className="mx-3">
    <h5>IŠSIŲSTI PASIŪLYMAI</h5>
    <ProposalsList />
  </div>
);

export default Proposals;
