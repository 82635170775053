import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Query } from 'react-apollo';

import REGIONS_QUERY from '../../queries/regions.graphql.js';
import RegionSelect from '../RegionSelect';
import FarmersList from './FarmersList';
import Input from "../../../../common/components/Input/Input";

import 'font-awesome/css/font-awesome.css';
import './FarmersListScreen.css';

class FarmersListScreen extends Component {
  state = { selectedRegion: '', searchText: '' };
  onRegionSelected = region => this.setState({ selectedRegion: region });
  onSearchTextChange = event =>
    this.setState({ searchText: event.target.value });

  render() {
    const { selectedRegion, searchText } = this.state;
    const pathRoot = this.props.match.path.split('/')[1];
    const { isCattle } = this.props;
    return (
      <div className="FarmersList">
        <h5>ŪKININKŲ SĄRAŠAS</h5>
        <div>
          <NavLink
            className="FarmersList-createNewButton button button-success"
            to={`/${pathRoot}/farmers/form/null`}
          >
            <FontAwesome name="user" />
            <span> Pridėti ūkininką</span>
          </NavLink>
        </div>
        <Query query={REGIONS_QUERY}>
          {({ loading, data }) =>
            loading
              ? null
              : [
                  <RegionSelect
                    key="RegionSelect"
                    options={data.regions.map(({ _id, name }) => ({
                      value: _id,
                      label: name,
                    }))}
                    selected={selectedRegion}
                    onSelect={this.onRegionSelected}
                  />,
                  <Input
                    key="searchInput"
                    type="text"
                    placeholder="Paieška"
                    className="text-field"
                    value={searchText}
                    onChange={this.onSearchTextChange}
                  />,
                  <FarmersList
                    key="FarmersListComponent"
                    region={selectedRegion}
                    name={searchText}
                    pathRoot={pathRoot}
                    isCattle={isCattle || false}
                  />,
                ]
          }
        </Query>
      </div>
    );
  }
}

export default FarmersListScreen;
