import rootReducer from './reducers';
import { createStore } from 'redux';
import { createHashHistory } from 'history';

export const history = createHashHistory();

export default initialState =>
  createStore(
    rootReducer,
    initialState
  );
