import React, { Component } from 'react';

import './ExpandableRow.css';

class ExpandableRow extends Component {
  state = { isOpen: false };

  triggerOpen = () => this.setState(state => ({ isOpen: !state.isOpen }));

  render() {
    return (
      <div className="ExpandableRow">
        <div
          className={`ExpandableRow-mainRow ${!this.state.isOpen ? 'isClosed' : ''}`}
          id={this.props.id}
          onClick={this.triggerOpen}
        >
          <div className="ExpandableRow-title">{this.props.title}</div>
          <div className="ExpandableRow-expandIcon">+</div>
        </div>
        <div
          className={`ExpandableRow-content ${
            this.state.isOpen ? 'ExpandableRow-content--open' : ''
          }`}
        >
          {this.state.isOpen ? this.props.content : null}
        </div>
      </div>
    );
  }
}

export default ExpandableRow;
