import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FilesQuery, FarmersAllQuery, SendFile } from '../queries/files.query';
import { FILE_TYPE_ICONS, FILE_TYPE_LABELS } from '../files.constants';
import '../../common/components/MenuScreen/MenuScreen.css';
import './FileList.css';
import Input from "../../common/components/Input/Input";
import Loader from "../../common/components/Loader/Loader";
import ErrorFetch from "../../common/components/Error/ErrorFetch";

const serverRoot =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3030/'
    : '/';

class FileList extends Component {
  state = { searchText: '', category: '--', selectedShare: null, shareEmail: '' };

  onSearchTextChange = ({ target }) => this.setState({ searchText: target.value });

  onCategoryChange = ({ target }) => this.setState({ category: target.value });

  toggleShare = id =>
    this.setState(state => ({ selectedShare: (state.selectedShare === id) ? null : id, shareEmail: '' }));

  closeShare = () => this.setState({ selectedShare: null, shareEmail: '' });

  onFarmersChange = (selected) => this.setState({ shareEmail: selected.map(item => item.email).join('; ') });

  onEmailChange = ({ target }) => this.setState({ shareEmail: target.value });

  sendFile = ({ emailFile, file }) =>
    this.state.shareEmail === '' ? alert('Nenurodytas adresatas') :
    emailFile({
      variables: {
        fileId: file.fileType ? file.path : file.videoUrl,
        userId: JSON.parse(localStorage.getItem('user-basis-ui2'))._id,
        name: file.name,
        email: this.state.shareEmail,
        isVideo: !file.fileType
      }
    }).then(() => {
      alert('Sėkmingai išsiųsta');
      this.closeShare();
    }, error => {
      console.error(error);
      alert('Nepavyko išsiųsti! Kreipkitės į administratorių');
    });

  render() {
    const { type, subTypeId } = this.props.match.params;
    const { searchText, category, selectedShare, shareEmail } = this.state;
    return (
      <div className="MenuScreen-root px-3">
        <h5 className="MenuScreen-container">FAILŲ SAUGYKLA</h5>
        <Query query={FilesQuery} variables={{ type, subTypeId }}>
          {
            ({ loading, data }) => {
              if (loading) return <Loader />;
              if (!data || !data.fileType) return <ErrorFetch />;

              const categories = data.fileType.subtype.files.reduce((acc, file) => {
                const type = file.fileType || 'video';
                return (acc.indexOf(type) === -1) ? [ ...acc, type ] : acc;
              }, []);
              return [
                  <h6 key="title">{data.fileType.name} / {data.fileType.subtype.name}</h6>,
                  <Input
                    key="searchInput"
                    type="text"
                    placeholder="Paieška"
                    className="text-field"
                    value={searchText}
                    onChange={this.onSearchTextChange}
                  />,
                  <select
                    key="categorySelect"
                    className="select"
                    onChange={this.onCategoryChange}
                    value={category}
                  >
                    <option value="--">Visi failų tipai</option>
                    {categories.map(
                      (category, index) => (
                        <option key={`categoryOption${index}`} value={category}>
                          {FILE_TYPE_LABELS[category]}
                        </option>
                      )
                    )}
                  </select>,
                  <table key="FileList-table" className="table FileList-table" align="center">
                    <tbody>
                    {
                      data.fileType.subtype.files
                        .filter(file =>
                          (
                            category === '--' ||
                            file.fileType === category ||
                            (!file.fileType && category === 'video')
                          ) &&
                          (searchText === '' || (new RegExp(searchText, 'i')).test(file.name))
                        )
                        .map(file => [
                          <tr className="FileList-tableRow" key={file._id}>
                            <td className="FileList-iconColumn">
                              <img
                                className="FileList-typeIcon"
                                src={`/assets/images/fileIcons/${FILE_TYPE_ICONS[file.fileType || 'video']}`}
                                alt="file type"
                                height="30"
                                width="30"
                              />
                            </td>
                            <td>
                              {
                                file.fileType ?
                                  <a
                                    href={`${serverRoot}upload/${file.path}`}
                                    download={file.name}
                                    target="_blank"
                                    type={file.fileType}
                                  >
                                    {file.name}
                                  </a> :
                                  <a href={file.videoUrl} target="_blank">
                                    {file.name}
                                  </a>
                              }
                            </td>
                            <td onClick={() => this.toggleShare(file._id)} className="FileList-shareColumn">
                              <FontAwesomeIcon icon={faShareAlt} />
                            </td>
                          </tr>,
                          (file._id === selectedShare) ?
                            <Mutation mutation={SendFile} key="MutationRow">
                              {emailFile =>
                                <tr className="FileList-shareRow">
                                  <td colSpan="2" className="px-3 py-3">
                                    <Query query={FarmersAllQuery}>
                                      {
                                        farmersResult => {
                                          if (farmersResult.loading) return <Loader />;
                                          if (!farmersResult.data || !farmersResult.data.farmers) return <ErrorFetch />;

                                          const farmersOptions = farmersResult.data.farmers.map(farmer =>
                                            ({ ...farmer, value: farmer._id, label: farmer.name })
                                          );
                                          return [
                                            <Select
                                              key="farmersSelect"
                                              isMulti
                                              options={farmersOptions}
                                              onChange={this.onFarmersChange}
                                            />,
                                            <Input
                                              key="searchInput"
                                              type="text"
                                              placeholder="el. paštas"
                                              className="text-field mt-2"
                                              value={shareEmail}
                                              onChange={this.onEmailChange}
                                            />,
                                            <button
                                              key="ShareButton"
                                              className="button button-success full-width"
                                              onClick={() => this.sendFile({
                                                emailFile,
                                                file
                                              })}
                                            >
                                              Išsiųsti failą
                                            </button>
                                          ];
                                        }
                                      }
                                    </Query>
                                  </td>
                                  <td className="FileList-closeShare" onClick={this.closeShare}>X</td>
                                </tr>
                              }
                            </Mutation> :
                            null
                        ])
                    }
                    </tbody>
                  </table>,
                ]
            }
          }
        </Query>
      </div>
    )
  }
}

export default FileList;
