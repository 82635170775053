import React from 'react';

const RegionSelect = ({ options, selected, onSelect }) => {
  const selectHandler = event => onSelect(event.target.value);
  return (
    <select className="select" onChange={selectHandler} value={selected}>
      <option key="" value="">
        Pasirinkite regioną
      </option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default RegionSelect;
