import gql from 'graphql-tag';
export const FARMERS_CATTLE = gql`
    query FarmersCattle($farmerId: String!, $categoryCode: String) {
        farmersCattle(farmerId: $farmerId, categoryCode: $categoryCode) {
            _id
            farmer {
                _id
                name
                email
                location
                phone
                regionId
                areaTotal
                region {
                    name
                    _id
                }
            }
            farmerId
            category {
                name
            }
            categoryCode
            animalCount
            daysValid
            products {
                productId
                product {
                    name
                    measureUnit
                }
                norm
                rate
                amount
            }
        }
        cattleProducts(categoryCode: $categoryCode) {
            _id
            name
            code
            shortDescription
            measureUnit
            inPack
            norm
            rate
        }
    }
`;

export const CULTURE_CATTLE_UPDATE = gql`
    mutation UpdateFarmersCattle(
      $farmersCattle: inFarmersCattle!
    ) {
        updateFarmersCattle(farmersCattle: $farmersCattle) {
            _id
        }
    }
`;

export const CULTURE_CATTLE_UPDATE_ROW = gql`
    mutation UpdateFarmersCattleRow(
      $_id: String!
      $index: Int!
      $farmersCattleProduct: inFarmersCattleProduct!
    ) {
        updateFarmersCattleRow(_id: $_id, index: $index, farmersCattleProduct: $farmersCattleProduct) {
            _id
        }
    }
`;

export const CULTURE_CATTLE_REMOVE_ROW = gql`
    mutation RemoveFarmersCattleRow($_id: String!, $index: Int!) {
        removeFarmersCattleRow(_id: $_id, index: $index) {
            _id
        }
    }
`;
