import React from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import round from 'lodash/round';
import numeral from 'numeral';
import {
  FARMERS_CULTURE_FOR_FERTILIZER,
  FERTILIZER_REMOVE_MUTATION,
} from '../../fertilizers/queries/fertilizers.graphql';
import { CULTURE_OVERVIEW_QUERY } from '../queries/cultureOverview.graphql';

const CultureOverviewFertilizerRow = ({
  fertilizer,
  culture,
  index,
  entityId,
  farmerId,
  cultureId,
  area,
  editable,
}) => (
  <Mutation
    key={`selectedFertis${index}`}
    mutation={FERTILIZER_REMOVE_MUTATION}
    variables={{ index, entityId }}
    refetchQueries={[
      {
        query: FARMERS_CULTURE_FOR_FERTILIZER,
        variables: { _id: entityId },
      },
      {
        query: CULTURE_OVERVIEW_QUERY,
        variables: {
          farmerId,
          cultureId,
        },
      },
    ]}
  >
    {removeMutation => {
      const quantity = (area * fertilizer.rate) / 1000;
      return (
        <tr key={fertilizer.fertilizer.name}>
          <td>{fertilizer.fertilizer.name}</td>
          <td>{fertilizer.rate}</td>
          <td>{round(quantity, 3)}</td>
          <td>{fertilizer.price}</td>
          <td>{round(quantity * fertilizer.price, 3)}</td>
          {editable ? <td>
            <strong>
              <button onClick={removeMutation}>x</button>
            </strong>
          </td> : null}
        </tr>
      );
    }}
  </Mutation>
);

const CultureOverviewFertilizer = ({
  farmerId,
  entryId,
  fertilizers,
  culture,
  area,
  editable
}) => {
  const { totalNeed, totalPrice } = fertilizers.reduce((acc, fertilizer) =>
      ({
        totalNeed: acc.totalNeed + ((area * fertilizer.rate) / 1000),
        totalPrice: acc.totalPrice + (((area * fertilizer.rate) / 1000) * fertilizer.price)
      }),
    { totalNeed: 0, totalPrice: 0 }
  );
  return (
    <div className="CultureOverviewFertilizer">
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Produktas</strong>
            </td>
            <td>
              <strong>Norma (kg/ha)</strong>
            </td>
            <td>
              <strong>Kiekis (t)</strong>
            </td>
            <td>
              <strong>Kaina (t/Eur)</strong>
            </td>
            <td>
              <strong>Viso (Eur)</strong>
            </td>
            {editable ? <td className="CultureOverviewFertilizer-closeColumn" /> : null}
          </tr>
          {fertilizers.map((fertilizer, index) => (
            <CultureOverviewFertilizerRow
              key={fertilizer.fertilizer._id}
              fertilizer={fertilizer}
              index={index}
              culture={culture}
              entityId={entryId}
              farmerId={farmerId}
              cultureId={culture._id}
              area={area}
              editable={editable}
            />
          ))}
          <tr>
            <td className="table-summaryRow"><b>Viso:</b></td>
            <td className="table-summaryRow" />
            <td className="table-summaryRow">{numeral(totalNeed).format('0.[000]')}</td>
            <td className="table-summaryRow" />
            <td className="table-summaryRow">{numeral(totalPrice).format('0.[00]')}</td>
          </tr>
        </tbody>
      </table>

      {editable ? <Link
        to={`/grow-technology/fertilizers/${entryId}`}
        className="button button-success"
      >
        PRIDĖTI TRĄŠAS
      </Link> : null}
    </div>
  );
};

export default CultureOverviewFertilizer;
